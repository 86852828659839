<template>
    <div class="news-filters news-filters--mobile">
        <div class="news-filters__content">
            <div class="news-filters__item news-filters__item--input">
                <z-input
                    name="search"
                    theme="search"
                    :value="updatedFilters.search"
                    @change="onChange"
                    :placeholder="text.placeholders.search"
                />
            </div>
            <div
                class="news-filters__item news-filters__item--button"
                :class="{'is-filled': isFilled}"
                v-if="updatedFilters"
            >
                <z-button
                    kind="secondary"
                    @click="opened = true"
                >
                    <span>{{ text.button.filter }}</span>
                </z-button>
            </div>
        </div>
        <div class="news-filters__popup" v-if="opened">
            <div class="news-filters__popup-header">
                <span>{{ text.button.filter }}</span>
                <z-link
                    @click="opened = false"
                    href="javascript: void(0);"
                >
                    <z-icon
                        name="close"
                        width="22"
                        height="22"
                    />
                </z-link>
            </div>
            <div class="news-filters__popup-body">
                <div class="news-filters__content">
                    <div
                        class="news-filters__item"
                        v-if="updatedFilters.years && updatedFilters.years.length"
                    >
                        <z-select
                            name="years"
                            :data="updatedFilters.years"
                            @change="onChange"
                            :placeholder="text.placeholders.years"
                        />
                    </div>
                    <div
                        class="news-filters__item"
                        v-if="updatedFilters.categories && updatedFilters.categories.length"
                    >
                        <z-select
                            name="categories"
                            :data="updatedFilters.categories"
                            @change="onCategoryChange"
                            :placeholder="text.placeholders.categories"
                        />
                    </div>
                    <div
                        class="news-filters__item"
                        :class="{'is-disabled': !categoriesSelected}"
                        v-if="updatedFilters.subcategories && updatedFilters.subcategories.length"
                    >
                        <z-select-multi
                            name="subcategories"
                            @change="onChange"
                            :data="updatedFilters.subcategories"
                            :placeholder="text.placeholders.subcategories"
                        />
                    </div>
                </div>
                <div class="news-filters__content">
                    <div class="news-filters__item">
                        <z-button
                            kind="secondary"
                            @click="$emit('clear')"
                        >
                            <span>{{ text.button.clear }}</span>
                        </z-button>
                    </div>
                    <div class="news-filters__item">
                        <z-button
                            kind="primary"
                            @click="opened = false"
                        >
                            <span>{{ text.button.apply }}</span>
                        </z-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'news-filters-mobile',
    props: {
        data: Object,
        isFilled: Boolean
    },
    data () {
        return {
            text: {
                button: {
                    filter: this.$root.lang === 'ru' ? 'Фильтр' : 'Filter',
                    clear: this.$root.lang === 'ru' ? 'Сбросить фильтр' : 'Clear filter',
                    apply: this.$root.lang === 'ru' ? 'Применить фильтр' : 'Apply filter'
                },
                placeholders: {
                    years: this.$root.lang === 'ru' ? 'Выберите год' : 'Select year',
                    search: this.$root.lang === 'ru' ? 'Введите ключевое слово' : 'Enter a keyword',
                    categories: this.$root.lang === 'ru' ? 'Выберите категорию' : 'Select categories',
                    subcategories: this.$root.lang === 'ru' ? 'Выберите подкатегорию' : 'Select subcategories'
                }
            },
            counter: 0,
            opened: false
        }
    },
    computed: {
        categoriesSelected () {
            for (let i = 0; i < this.updatedFilters.categories.length; i++) {
                if (this.updatedFilters.categories[i].selected === true) {
                    return true
                }
            }
        },
        updatedFilters () {
            return this.data
        }
    },
    methods: {
        onChange (data) {
            this.$emit('change', data)
        },
        onCategoryChange (data) {
            if (data.name === 'categories' && data.value.length < 1) {
                this.$root.$bus.$emit('clear', 'subcategories')
            }
            this.$nextTick(() => this.onChange(data))
        }
    }
}
</script>
