<template>
    <div
        class="z-accordion"
        :class="[{'z-accordion--nested': nested}]"
    >
        <slot/>
    </div>
</template>

<script>
export default {
    name: 'z-accordion',
    props: {
        nested: Boolean,
        multiple: Boolean,
        initial: {
            type: String,
            default: 'closed',
            validator: prop => ['closed', 'opened', 'first'].includes(prop)
        }
    },
    data () {
        return {
            name: 'z-accordion',
            hash: window.location.hash,
            openedItem: {
                id: {
                    type: String,
                    default: ''
                },
                childrens: Array
            }
        }
    },
    mounted () {
        this.defaultState()
        this.$nextTick(() => this.checkHash())
        this.$on('toggle-item', data => this.openHandler(data))
    },
    beforeDestroy () {
        this.$off('toggle-item', data => this.openHandler(data))
    },
    watch: {
        initial () {
            this.defaultState()
        }
    },
    computed: {
        currentHash: {
            get () {
                return this.hash ? this.hash.split('#')[1] : ''
            },
            set (string) {
                this.hash = string
            }
        }
    },
    methods: {
        openHandler (data) {
            // если это первое открытие - записываем параметры
            if (!this.openedItem.id.length) {
                // id элемента
                this.openedItem.id = data.itemId
                // его потомков
                this.openedItem.childrens = data.itemChildrens
            };

            // если id элемента совпадает с текущим
            if (this.openedItem.id === data.itemId) {
                // удаляем хэш
                this.removeHash()
                // закрываем все внутренние аккордеоны
                this.closeInner(this.openedItem.childrens)
                // если id не совпадает
            } else {
                // записываем новый id
                this.openedItem.id = data.itemId

                // если не multiple - закрываем все внутренние аккордеоны
                if (!this.multiple) this.closeInner(this.openedItem.childrens)

                // записываем потомков
                this.openedItem.childrens = data.itemChildrens
            };

            // если аккордеон multiple - прерываем
            if (this.multiple) return

            // закрываем все элементы кроме текущего
            for (let i = 0; i < this.$children.length; i++) {
                if (this.$children[i].itemId !== this.openedItem.id) this.$children[i].state = false
            };
        },
        removeHash () {
            this.currentHash = ''
            history.pushState('', document.title, window.location.pathname + window.location.search)
        },
        checkHash () {
            if (this.currentHash.length) {
                this.$nextTick(() => {
                    let matched = this.$children.filter(item => item.itemId === this.currentHash)

                    if (matched.length) this.openItem()
                })
            };
        },
        defaultState () {
            if (this.initial === 'opened') this.toggleAll(true)
            if (this.initial === 'closed') this.toggleAll(false)
            if (this.initial === 'first' && !this.currentHash.length) this.openByIndex(0)
        },
        openItem () {
            for (let i = 0; i < this.$children.length; i++) {
                if (this.$children[i].itemId === this.currentHash) {
                    this.$children[i].state = true

                    this.openedItem.id = this.$children[i].itemId
                    this.openedItem.childrens = this.$children[i].$children

                    if (this.nested) this.openParent(this.$parent)
                    if (this.$parent.name === 'z-tabs-content') this.openTabs(this.$parent)
                    if (this.$parent.$parent.name === 'z-tabs-content') this.openTabs(this.$parent.$parent)
                };
            };
        },
        openParent (parent) {
            let grandParent = parent.$parent

            parent.state = true

            if (grandParent.$parent.name === 'z-tabs-content') this.openTabs(grandParent.$parent)

            if (grandParent.name === 'z-accordion' && grandParent.nested) {
                grandParent.$parent.state = true
                this.openParent(grandParent.$parent)
            };
        },
        toggleAll (state) {
            for (let i = 0; i < this.$children.length; i++) {
                this.$children[i].state = state
            };
        },
        openByIndex (index) {
            this.toggleAll(false)
            this.$nextTick(() => {
                this.$children[index].state = true
                this.openedItem.id = this.$children[index].itemId
                this.openedItem.childrens = this.$children[index].itemChildrens
            })
        },
        closeInner (inner) {
            if (inner === undefined || !inner.length) return

            for (let i = 0; i < inner.length; i++) {
                let item = inner[i].$children

                if (item.length) {
                    for (let i = 0; i < item.length; i++) {
                        if (item[i].name === 'z-tabs-label') return
                        if (item[i].name === 'z-tabs-content') return

                        item[i].state = false
                    };
                    this.closeInner(item)
                };
            };
        },
        openTabs (parent) {
            let tabs = parent.$parent

            tabs.closeAll()
            this.$nextTick(() => tabs.openParent(parent))
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
