<template>
    <div class="media-library-filters u-bottom-margin--m">
        <z-tabs class="u-bottom-margin--m" @open-tab="tabsHandler" only-visual>
            <template v-slot:label>
                <template v-for="item in filters.type">
                    <z-tabs-label :id="item.xmlId" :initial-state="item.selected" :key="`tab-${item.id}`">{{ item.text }}</z-tabs-label>
                </template>
            </template>
        </z-tabs>
        <z-select :data="filters.subjects" placeholder="Тема" name="Тема" is-filter @change="selectHandler"/>
    </div>
</template>

<script>
import { queryString } from '@/utils/queryString'
import { clearEmptyParams } from '@/utils/clearEmptyParams'

export default {
    name: 'filters',
    props: {
        filters: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            params: {
                subject: '',
                type: ''
            }
        }
    },
    mounted () {
        if (this.filters.type.length) {
            this.filters.type.forEach((item) => {
                if (item.selected) this.params.type = item.id
            })
        }
    },
    computed: {
        clearedParams () {
            return clearEmptyParams(this.params)
        }

    },
    methods: {
        selectHandler (data) {
            this.params.subject = data
            queryString({...this.clearedParams})
            this.$emit('change-filters', this.params)
        },
        tabsHandler (data) {
            this.params.type = data
            queryString({...this.clearedParams})
            this.$emit('change-filters', this.params)
        }
    }
}
</script>

<style lang="scss">
.media-library-filters {
    .z-select {
        max-width: 314px;
    }
}
</style>
