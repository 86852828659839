/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar/apple-ical': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.701 4.604L17.15 2.253l.194 1.106L3.895 5.71 3.7 4.604zm.194 1.106L2.7 5.919 5.468 21.75 21.3 18.983 18.532 3.15l-1.19.208 2.575 14.725-13.448 2.351L3.895 5.71z" _fill="#81899D"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M12.224 10.014l.147.83 3.448-.596c-.355.57-.657 1.252-.916 2.047-.268.787-.432 1.59-.501 2.41-.07.822-.06 1.504.026 2.057l.88-.155a8.996 8.996 0 01-.008-1.987c.087-.882.285-1.746.588-2.61.302-.864.63-1.572.993-2.125l-.12-.666-4.537.795zm-1.919 7.465l.864-.146-1.227-7.034-.553.095c-.103.329-.302.683-.604 1.072A5.173 5.173 0 017.67 12.51l.147.83a5.81 5.81 0 00.83-.605c.302-.26.535-.501.708-.735l.95 5.478z" _fill="#626C84"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M3.696 4.6l13.448-2.35.878 5.022-13.448 2.35-.878-5.021zm9.21 2.477l-.475-2.73.363-.061.424 2.41 1.348-.233.06.32-1.72.294zM11.845 4.45l-.363.06.277 1.573c.06.346.043.588-.052.726s-.268.233-.536.276a.794.794 0 01-.406-.026.465.465 0 01-.276-.216c-.061-.103-.113-.276-.156-.51l-.277-1.572-.362.06.276 1.582c.052.285.13.51.225.665.095.155.233.26.414.32.173.06.39.069.649.026.267-.044.466-.13.613-.26a.79.79 0 00.268-.449 1.803 1.803 0 00-.018-.674l-.276-1.581zM8.258 7.042l-.32.104c.044.276.139.466.285.587.156.121.346.156.588.113a.868.868 0 00.406-.173.618.618 0 00.216-.337c.035-.13.035-.32-.009-.545l-.328-1.857-.363.06.329 1.884c.034.155.034.268.025.346a.3.3 0 01-.103.19.452.452 0 01-.225.103c-.12.018-.225 0-.31-.069-.079-.06-.148-.199-.191-.406z" _fill="#81899D"/>'
  }
})
