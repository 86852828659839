/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'people-default/l': {
    width: 102,
    height: 102,
    viewBox: '0 0 102 102',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M16.663 85.338C-2.3 66.375-2.3 35.63 16.663 16.668c18.963-18.964 49.708-18.964 68.67 0 18.963 18.962 18.963 49.707 0 68.67-18.962 18.963-49.707 18.963-68.67 0zm-.849-69.52c-19.431 19.432-19.431 50.937 0 70.368 19.432 19.432 50.937 19.432 70.368 0 19.432-19.431 19.432-50.936 0-70.367-19.431-19.432-50.936-19.432-70.368 0zm34.433-.034c-6.29 0-11.51 5.709-11.51 12.871 0 7.21 5.246 13.672 11.51 13.672 3.125 0 5.994-1.6 8.098-4.132 2.104-2.533 3.411-5.966 3.411-9.54 0-7.163-5.204-12.87-11.509-12.87zm-12.71 12.871c0-7.702 5.64-14.07 12.71-14.07 7.086 0 12.71 6.369 12.71 14.07 0 3.858-1.408 7.561-3.69 10.306-2.28 2.746-5.466 4.566-9.02 4.566-7.126 0-12.71-7.218-12.71-14.872zm12.71 20.302c-13.251 0-23.987 10.751-23.987 23.987v5.766c0 .44.089.732.211.933.12.199.299.354.555.475.548.26 1.366.33 2.414.33h6.336V68.172a.6.6 0 111.2 0V80.45h26.542V68.17a.6.6 0 111.2 0V80.45h6.32c.204 0 .396.002.58.004h.002c.311.003.599.006.879-.002.439-.01.782-.046 1.046-.125.254-.076.4-.18.495-.313.098-.14.19-.382.194-.825v-6.244c0-13.252-10.735-23.987-23.987-23.987zM36.404 81.649H64.09a.65.65 0 00.056 0h6.892l.533.003h.004c.329.003.656.007.954 0 .467-.013.939-.051 1.36-.176.43-.13.838-.359 1.13-.77.286-.404.41-.914.415-1.51v-6.252c0-13.914-11.273-25.187-25.187-25.187-13.915 0-25.187 11.289-25.187 25.187v5.766c0 .602.123 1.123.384 1.555.264.435.64.735 1.068.938.822.389 1.894.446 2.928.446H36.404z" _fill="#0077C8"/>'
  }
})
