/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tools/lock': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12 2.25a2.625 2.625 0 00-2.625 2.625V7.5h5.25V4.875A2.625 2.625 0 0012 2.25zM7.875 4.875V7.5H4.5A1.5 1.5 0 003 9v10.5A1.5 1.5 0 004.5 21h15a1.5 1.5 0 001.5-1.5V9a1.5 1.5 0 00-1.5-1.5h-3.375V4.875a4.125 4.125 0 00-8.25 0zM4.5 9h15v10.5h-15V9zm9 5.25a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" _fill="#81899D"/>'
  }
})
