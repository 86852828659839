// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import { TippyComponent } from 'vue-tippy'
import App from './App'
import store from './store/'
import { localize } from '@/utils/i18n'

import '@/utils/filter' // global
import '@/utils/modernizr-custom' // global
import '@/utils/polyfills/object-fit' // global
import '@/utils/correctExtensions' // global
import { VueAxios } from '@/utils/request'
import { AppDeviceEnquire } from '@/utils/mixin'
import { showNotyfications } from '@/utils/notifications'

/* Components */
import NavigationTop from './components/NavigationTop'
import NavigationMain from './components/NavigationMain'
import NavigationMobile from './components/NavigationMobile'
import NavigationBottom from './components/NavigationBottom'
import Social from './components/Social'
import SiteSelector from './components/SiteSelector'
import NavigationLeft from './components/NavigationLeft'
import PageTools from './components/PageTools'
import Card from './components/Card'
import CardListItem from './components/CardListItem'
import CardMedia from './components/CardMedia'
import NewsFilters from './components/NewsFilters'
import NewsFiltersMobile from './components/NewsFiltersMobile'
import CalendarFilters from './components/CalendarFilters'
import CookiePopup from './components/CookiePopup'
import Remind from './components/Remind'
import MainSlider from './components/MainSlider'
import LastUpdates from './components/LastUpdates'
import NewsSlider from './components/NewsSlider'
import FastSearch from './components/FastSearch'
import Lead from './components/Lead'
import NavigationRight from './components/NavigationRight'
import DownloadButton from './components/DownloadButton'
import { Slider, Slide, SlideContent } from './components/Slider'
import LkInfo from './components/LkInfo'

/* Views */
import { News, NewsDetail } from './views/News'
import SiteMap from './views/SiteMap'
import Search from './views/Search'
import MainPage from './views/MainPage'
import { Layout, HeaderLayout, FooterLayout } from './views/Layout'
import { HistoryPage, HistoryList } from './views/History'
import PurchaseDetail from './views/PurchaseDetail'
import Purchase from './views/Purchase'
import { Management, ManagementDetail } from './views/Management'
import Page404 from './views/Page404'
import MediaLibrary from './views/MediaLibrary'
import Files from './views/Files'
import CalculatorHeatSupply from './views/CalculatorHeatSupply'
import CalculatorWaterSupply from './views/CalculatorWaterSupply'

/* Components */
Vue.component('tippy-tooltip', TippyComponent)
Vue.component('navigation-top', NavigationTop)
Vue.component('navigation-main', NavigationMain)
Vue.component('navigation-mobile', NavigationMobile)
Vue.component('navigation-bottom', NavigationBottom)
Vue.component('social', Social)
Vue.component('site-selector', SiteSelector)
Vue.component('navigation-left', NavigationLeft)
Vue.component('page-tools', PageTools)
Vue.component('card', Card)
Vue.component('card-list-item', CardListItem)
Vue.component('card-media', CardMedia)
Vue.component('news-filters', NewsFilters)
Vue.component('news-filters-mobile', NewsFiltersMobile)
Vue.component('calendar-filters', CalendarFilters)
Vue.component('cookie-popup', CookiePopup)
Vue.component('remind', Remind)
Vue.component('main-slider', MainSlider)
Vue.component('last-updates', LastUpdates)
Vue.component('news-slider', NewsSlider)
Vue.component('fast-search', FastSearch)
Vue.component('lead', Lead)
Vue.component('navigation-right', NavigationRight)
Vue.component('download-button', DownloadButton)
Vue.component('slider', Slider)
Vue.component('slide', Slide)
Vue.component('slide-content', SlideContent)
Vue.component('lk-info', LkInfo)

/* Views */
Vue.component('news', News)
Vue.component('news-detail', NewsDetail)
Vue.component('site-map', SiteMap)
Vue.component('search', Search)
Vue.component('main-page', MainPage)
Vue.component('layout', Layout)
Vue.component('header-layout', HeaderLayout)
Vue.component('footer-layout', FooterLayout)
Vue.component('history-page', HistoryPage)
Vue.component('history-list', HistoryList)
Vue.component('purchase-detail', PurchaseDetail)
Vue.component('purchase', Purchase)
Vue.component('management', Management)
Vue.component('management-detail', ManagementDetail)
Vue.component('page-404', Page404)
Vue.component('media-library', MediaLibrary)
Vue.component('files', Files)
Vue.component('calculator-heat-supply', CalculatorHeatSupply)
Vue.component('calculator-water-supply', CalculatorWaterSupply)

// https://webpack.js.org/guides/dependency-management/#require-context
const requireComponent = require.context(
    './components',
    true,
    /Z[\w-]+\/index\.js$/
)

requireComponent.keys().forEach(fileName => {
    const componentConfig = requireComponent(fileName)
    let componentName = fileName.split('/')[1]

    for (let key in componentConfig) {
        if (componentConfig.hasOwnProperty(key)) {
            if (key !== 'default') componentName = key
            Vue.component(componentName, componentConfig[key])
        }
    }
})

if (process.env.NODE_ENV !== 'production') {
    window.onerror = function (msg, url, lineNo, columnNo, error) {
        showNotyfications(`${msg}<br>${url}#${lineNo}`, {
            type: 'error'
        })
    }

    Vue.config.errorHandler = function (err, vm, info) {
        console.error(`Error: ${err.toString()}\nInfo: ${info}\nVm component: ${vm.$options._componentTag}`)
        showNotyfications(`Error: ${err.toString()}<br>Info: ${info}<br>Vm component: ${vm.$options._componentTag}`, {
            type: 'error'
        })
    }

    Vue.config.warnHandler = function (msg, vm, trace) {
        console.warn(`Warn: ${msg.toString()}\nInfo: ${trace}\nVm component: ${vm.$options._componentTag}`)
        showNotyfications(`Warn: ${msg.toString()}<br>Info: ${trace}<br>Vm component: ${vm.$options._componentTag}`, {
            type: 'alert'
        })
    }

    Vue.config.productionTip = false
}

Object.defineProperty(Vue.prototype, '$bus', {
    get () {
        return this.$root.bus
    }
})

Vue.config.productionTip = false

Vue.use(VueAxios)

/* eslint-disable no-new */
new Vue({
    el: '#app',
    mixins: [AppDeviceEnquire],
    store,
    data: {
        lang: window.App.page.lang,
        bus: new Vue({}),
        app: window.App
    },
    components: {
        App
    },
    mounted () {
        this.$el.classList.add('is-loaded')
    },
    methods: {
        localize: localize
    }
})
