/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cooperation': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<g clip-path="url(#clip0_3611_15857)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M32.125 12.625c-3.197 0-5.813-2.616-5.813-5.813C26.313 3.616 28.928 1 32.126 1c3.197 0 5.813 2.616 5.813 5.813 0 3.196-2.616 5.812-5.813 5.812zm0-9.688a3.886 3.886 0 00-3.875 3.876 3.886 3.886 0 003.875 3.875A3.886 3.886 0 0036 6.812a3.886 3.886 0 00-3.875-3.875zm-.969 39.72V63h1.938V42.656h-1.938zm10.657-20.98v-.333c0-2.713-2.132-4.844-4.844-4.844H27.28c-2.712 0-4.843 2.131-4.843 4.844v.334c2.251.802 3.875 2.96 3.875 5.478V63h-1.938V44.594h-1.938V63H20.5V42.656h3.875v-15.5a3.886 3.886 0 00-3.875-3.875h-9.688a3.886 3.886 0 00-3.874 3.875v15.5h3.875V63H8.874V44.594H5V27.156c0-3.197 2.616-5.812 5.813-5.812H20.5a6.74 6.74 0 016.781-6.782h9.688a6.74 6.74 0 016.781 6.782h9.688c3.196 0 5.812 2.615 5.812 5.812v17.438h-3.875V63h-1.938V42.656h3.876v-15.5a3.886 3.886 0 00-3.876-3.875H43.75a3.886 3.886 0 00-3.875 3.875v15.5h3.875V63h-1.938V44.594h-1.937V63h-1.938V27.156c0-2.518 1.624-4.676 3.876-5.478zM16.625 46.532h-1.938V63h1.938V46.531zm-5.813-31.968c0 2.712 2.132 4.843 4.844 4.843 2.713 0 4.844-2.131 4.844-4.843 0-2.713-2.131-4.844-4.844-4.844-2.712 0-4.844 2.131-4.844 4.844zm1.938 0c0-1.647 1.26-2.907 2.906-2.907 1.647 0 2.906 1.26 2.906 2.906 0 1.647-1.259 2.907-2.906 2.907-1.647 0-2.906-1.26-2.906-2.907zM49.563 46.53V63h-1.938V46.531h1.938zm-.97-27.125c-2.712 0-4.843-2.131-4.843-4.843 0-2.713 2.131-4.844 4.844-4.844 2.712 0 4.843 2.131 4.843 4.844 0 2.712-2.13 4.843-4.843 4.843zm0-7.75c-1.646 0-2.906 1.26-2.906 2.906 0 1.647 1.26 2.907 2.907 2.907s2.906-1.26 2.906-2.907c0-1.646-1.26-2.906-2.906-2.906zm-.968 13.563v1.937h1.938V25.22h-1.938zm-32.938 0h1.938v1.937h-1.938V25.22zm18.407-6.782h-1.938v1.938h1.938v-1.938zm-1.938 3.875h1.938v1.938h-1.938v-1.938z" _fill="#0077C8"/></g><defs><clipPath id="clip0_3611_15857"><path pid="1" _fill="#fff" d="M0 0h64v64H0z"/></clipPath></defs>'
  }
})
