/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'media/video': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.25 4.875a1.5 1.5 0 00-1.5 1.5v9a3.75 3.75 0 003.75 3.75h12a1.5 1.5 0 001.5-1.5v-2.833l4.128 2.36a.75.75 0 001.122-.652v-9a.75.75 0 00-1.122-.651L18 9.208v-.583a3.75 3.75 0 00-3.75-3.75h-12zm14.25 5.612v7.138h-12a2.25 2.25 0 01-2.25-2.25v-9h12a2.25 2.25 0 012.25 2.25v1.862zm1.5 2.578v-2.13l3.75-2.143v6.416L18 13.065z" _fill="#81899D"/>'
  }
})
