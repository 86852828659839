/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/big/txt': {
    width: 56,
    height: 56,
    viewBox: '0 0 56 56',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.934 4.667H30.6a1 1 0 01.707.293l1.807 1.807h-.01l.471.472 11.526 11.526v-.01l1.808 1.806a1 1 0 01.293.707V46.9a5.6 5.6 0 01-5.6 5.6H14.934a5.6 5.6 0 01-5.6-5.6V10.267a5.6 5.6 0 015.6-5.6zM45.102 20.94H32.874a1.4 1.4 0 01-1.4-1.4V6.767H14.934a3.5 3.5 0 00-3.5 3.5V46.9a3.5 3.5 0 003.5 3.5h26.668a3.5 3.5 0 003.5-3.5V20.941zm-11.527-2.1v-8.633l8.633 8.633h-8.633z" _fill="#1092DC"/><path pid="1" d="M19.72 37.833v-7.176h-2.904v-1.284h7.344v1.284h-2.904v7.176H19.72zm4.177 0l3.228-4.308-3.108-4.152h1.788L28 32.41l2.196-3.036h1.8l-3.108 4.152 3.216 4.308h-1.8L28 34.665l-2.292 3.168h-1.812zm10.858 0v-7.176h-2.904v-1.284h7.344v1.284h-2.904v7.176h-1.536z" _fill="#1092DC"/>'
  }
})
