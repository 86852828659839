/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'accordions/minus': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M21.75 11.563a.749.749 0 00-.61-.313H2.86a.749.749 0 00-.61.313v.874c.136.19.358.313.61.313h18.28a.749.749 0 00.61-.313v-.874z" _fill="#81899D"/>'
  }
})
