<template>
    <div>
        <p
            v-if="data.title"
            class="header-3 u-bottom-margin--double"
            v-html="data.title"
        ></p>
        <div
            v-if="data.text"
            v-html="data.text"
            class="html-content"
        ></div>
    </div>
</template>

<script>
export default {
    name: 'lead',
    props: {
        component: {
            type: String,
            default: 'potencial'
        }
    },
    data () {
        return {
            data: this.$root.app.components['investment-case'][this.component]
        }
    }
}
</script>
