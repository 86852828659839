/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/small/xls': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.666 3.333h11.072a1 1 0 01.708.293l1.207 1.207h-.008l.337.337 8.233 8.233v-.007l1.207 1.207a1 1 0 01.293.707V33.5a4 4 0 01-4 4H10.666a4 4 0 01-4-4V7.333a4 4 0 014-4zm21.55 11.625h-8.734a1 1 0 01-1-1V4.833H10.666a2.5 2.5 0 00-2.5 2.5V33.5a2.5 2.5 0 002.5 2.5h19.05a2.5 2.5 0 002.5-2.5V14.958zm-8.234-1.5V7.292l6.166 6.166h-6.166z" _fill="#1092DC"/><path pid="1" d="M10.892 27.167l2.69-3.59-2.59-3.46h1.49l1.83 2.53 1.83-2.53h1.5l-2.59 3.46 2.68 3.59h-1.5l-1.92-2.64-1.91 2.64h-1.51zm7.349 0v-7.05h1.28v5.96h3.38v1.09h-4.66zm7.698.1c-.553 0-1.066-.07-1.54-.21a3.875 3.875 0 01-1.2-.59l.39-1.01c.327.233.684.413 1.07.54.394.126.82.19 1.28.19.527 0 .91-.087 1.15-.26.24-.18.36-.41.36-.69a.656.656 0 00-.26-.55c-.166-.134-.456-.244-.87-.33l-1.1-.23c-1.253-.267-1.88-.91-1.88-1.93 0-.44.117-.824.35-1.15.234-.327.557-.58.97-.76.414-.18.89-.27 1.43-.27.48 0 .93.073 1.35.22.42.14.77.34 1.05.6l-.39.96c-.553-.467-1.226-.7-2.02-.7-.46 0-.82.096-1.08.29a.904.904 0 00-.39.76c0 .24.08.433.24.58.16.14.434.25.82.33l1.09.23c.66.14 1.15.36 1.47.66.327.3.49.7.49 1.2 0 .42-.113.79-.34 1.11-.226.32-.55.57-.97.75-.413.173-.903.26-1.47.26z" _fill="#1092DC"/>'
  }
})
