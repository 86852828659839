var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data.totalPages > 1)?_c('div',{staticClass:"z-pagination"},[_c('div',{staticClass:"z-pagination__wrapper"},[(_vm.showMore && !(_vm.data.totalPages === _vm.data.currentPage))?_c('z-button',{class:['z-pagination__button', ("u-bottom-margin--" + (_vm.showMore.marginBottom))],attrs:{"kind":"bordered","size":_vm.showMore.size},on:{"click":function($event){return _vm.changePage(_vm.nextPage, 'load-more')}}},[_c('span',{staticClass:"u-semi-bold"},[_vm._v(_vm._s(_vm.showMore.text ? _vm.showMore.text : 'Загрузить ещё'))])]):_vm._e(),_vm._v(" "),(_vm.showNav)?_c('nav',{staticClass:"z-pagination__nav",attrs:{"aria-label":"pagination navigation"}},[_c('ul',{staticClass:"z-pagination__list"},[(_vm.showPrev)?_c('li',[_c('button',{key:"prev",class:[
                        'z-pagination__item',
                        'z-pagination__item-icon',
                        { 'is-disabled' : _vm.data.currentPage === 1 }
                    ],attrs:{"type":"button","aria-label":"Предыдущая страница"},on:{"click":function($event){return _vm.changePage(_vm.prevPage)}}},[_c('z-icon',{staticClass:"z-pagination__icon z-pagination__icon--prev",attrs:{"name":"arrows/round-arrow","width":"40","height":"40","dir":"left"}})],1)]):_vm._e(),_vm._v(" "),(_vm.hasFirst)?[_c('li',[_c('button',{key:"first",staticClass:"z-pagination__item z-pagination__item-background",attrs:{"type":"button","aria-label":"страница 1"},on:{"click":function($event){return _vm.changePage(1)}}},[_c('span',{staticClass:"z-pagination__number z-pagination__number--single"},[_vm._v("\n                                1\n                            ")])])]),_vm._v(" "),_c('li',[(_vm.isDividerShown('start'))?_c('span',{staticClass:"z-pagination__divider",attrs:{"aria-label":"devider"}},[_c('z-icon',{attrs:{"name":"general/multipoint","width":"24","height":"24"}})],1):_vm._e()])]:_vm._e(),_vm._v(" "),_vm._l((_vm.pages),function(item,index){return _c('li',{key:(item + "-" + index)},[_c('button',{class:[
                            'z-pagination__item',
                            'z-pagination__item-background',
                            { 'is-active' : item === _vm.data.currentPage }
                        ],attrs:{"type":"button"},on:{"click":function($event){return _vm.changePage(item)}}},[_c('span',{class:['z-pagination__number', _vm.getNumberClass(item)],attrs:{"aria-label":("страница " + item)}},[_vm._v("\n                            "+_vm._s(item)+"\n                        ")])])])}),_vm._v(" "),(_vm.hasLast)?[(_vm.isDividerShown('end'))?_c('li',[_c('span',{staticClass:"z-pagination__divider",attrs:{"aria-label":"devider"}},[_c('z-icon',{attrs:{"name":"general/multipoint","width":"24","height":"24"}})],1)]):_vm._e(),_vm._v(" "),_c('li',[_c('button',{key:"last",staticClass:"z-pagination__item z-pagination__item-background",attrs:{"type":"button"},on:{"click":function($event){return _vm.changePage(_vm.data.totalPages)}}},[_c('span',{class:['z-pagination__number', _vm.getNumberClass(_vm.data.totalPages)]},[_vm._v("\n                                "+_vm._s(_vm.data.totalPages)+"\n                            ")])])])]:_vm._e(),_vm._v(" "),(_vm.showNext)?_c('li',[_c('button',{key:"next",class:[
                            'z-pagination__item',
                            'z-pagination__item-icon',
                            { 'is-disabled' : _vm.data.currentPage === _vm.data.totalPages }
                            ],attrs:{"type":"button","aria-label":"Следующая страница"},on:{"click":function($event){return _vm.changePage(_vm.nextPage)}}},[_c('z-icon',{staticClass:"z-pagination__icon",attrs:{"name":"arrows/round-arrow","width":"40","height":"40","dir":"right"}})],1)]):_vm._e()],2)]):_vm._e()],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }