<template>
    <header class="header">
        <div class="container">
            <div class="header__container">
                <div class="header__logo">
                    <z-logo site-dir="/" />
                </div>
                <div class="header__wrapper">
                    <div class="hide-me--tablet">
                        <div class="header__links">
                            <nav class="header-nav">
                                <ul class="header-nav__list">
                                    <!-- ПРИ РЕДАКТИРОВАНИИ ЭТИХ ПУНКТОВ НЕ ЗАБУДЬ ПРОДУБЛИРОВАТЬ В NAVIGATIONMOBILE -->
                                    <li class="header-nav__list-item">
                                        <z-link theme="dark" :underlined="false" href="/contacts/" class="header-nav__link">Контакты</z-link>
                                    </li>
                                    <!-- END ПРИ РЕДАКТИРОВАНИИ ЭТИХ ПУНКТОВ НЕ ЗАБУДЬ ПРОДУБЛИРОВАТЬ В NAVIGATIONMOBILE -->
                                    <li class="header-nav__list-item u-relative">
                                        <lk-info is-button></lk-info>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <navigation-main />
                    </div>
                    <div class="hide-me--default show-me--tablet">
                        <navigation-mobile :static-links="accountLinks"/>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import NavigationMain from '@/components/NavigationMain'
import NavigationMobile from '@/components/NavigationMobile'

export default {
    name: 'header-layout',
    components: {
        NavigationMain,
        NavigationMobile
    },
    props: {
        accountLinks: Array
    }
}
</script>

<style lang="scss">
.header {
    display: flex;
    align-items: center;
    position: relative;
    padding-bottom: $token-spacers-xs;

    &__container {
        padding-top: $token-spacers-xs;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include breakpoint (v-tablet) {
            padding-top: 14px;
        }
    }

    &__wrapper {
        display: flex;
        align-items: center;
        padding-left: $token-spacers-s;
    }

    &__logo {
        max-width: 230px;
        align-self: flex-start;

        @include breakpoint (v-tablet) {
            max-width: 220px;
        }

        @include breakpoint (mobile) {
            max-width: 135px;
        }
    }

    &__links {
        display: flex;
        justify-content: flex-end;
    }

    &-nav {
        &__list {
            display: flex;
            align-items: center;
        }

        &__link {
            font-weight: 600 !important;
        }

        &__list-item {
            font-weight: 600;
            font-size: 14px;
            margin-right: $token-spacers-xs;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.dropdown-enter-active {
    transition: all .3s ease;
}
.dropdown-leave-active {
    transition: all .8s ease;
}
.dropdown-enter, .dropdown-leave-to {
    opacity: 0;
}
</style>
