var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'z-input',
    {
        'z-input--filter': _vm.isFilter,
        'z-input--clearable': _vm.isClearable,
        'is-focused': _vm.isFocused,
        'is-errored': _vm.isValid === false,
        'is-valid': _vm.isValid && !_vm.isFilter,
        'is-filled': _vm.inputValue !== '' && typeof _vm.inputValue !== 'object',
        'is-disabled': _vm.disabled,
        'is-required': _vm.required
    }
]},[_c('div',{staticClass:"z-input__wrapper"},[_c('label',{staticClass:"z-input__label"},[(_vm.title)?_c('p',{staticClass:"z-input__title",domProps:{"innerHTML":_vm._s(_vm.title)}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"z-input__container"},[_c('input',_vm._b({directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"},{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],staticClass:"z-input__input",attrs:{"name":"name","type":"text","disabled":_vm.disabled},domProps:{"value":(_vm.inputValue)},on:{"blur":_vm.onBlur,"focus":_vm.onFocus,"input":[function($event){if($event.target.composing){ return; }_vm.inputValue=$event.target.value},function($event){return _vm.onInput($event)}],"change":function($event){return _vm.onChange($event)},"keypress":_vm.onKeyPress}},'input',_vm.$attrs,false)),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.placeholder),expression:"placeholder"}],staticClass:"z-input__placeholder",domProps:{"innerHTML":_vm._s(_vm.placeholder)}})])]),_vm._v(" "),(_vm.isClearable && _vm.inputValue)?_c('span',{staticClass:"z-input__clear",on:{"click":_vm.clear}}):_vm._e()]),_vm._v(" "),(_vm.error && !_vm.isValid)?_c('span',{class:[
            'z-input__error',
            _vm.errorClass
        ],domProps:{"innerHTML":_vm._s(_vm.error)}}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }