/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'accordions/folder-simple': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.75 4.5A1.5 1.5 0 002.25 6v12.75a1.5 1.5 0 001.5 1.5h16.583a1.417 1.417 0 001.417-1.417V8.25a1.5 1.5 0 00-1.5-1.5h-8L9.65 4.8a1.5 1.5 0 00-.9-.3h-5zm0 1.5h5l2.6 1.95c.26.195.575.3.9.3h8v10.5H3.75V6z" _fill="#81899D"/>'
  }
})
