/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tools/close-small': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<mask id="svgicon_tools_close-small_a" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24"><path pid="0" _fill="#81899D" d="M0 0h24v24H0z"/></mask><g mask="url(#svgicon_tools_close-small_a)"><path pid="1" d="M7.274 18L6 16.726 10.726 12 6 7.274 7.274 6 12 10.726 16.726 6 18 7.274 13.274 12 18 16.726 16.726 18 12 13.274 7.274 18z" _fill="#81899D"/></g>'
  }
})
