/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrows/caret-double': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M20.657 21.905a.75.75 0 101.06-1.06l-9.28-9.281a.75.75 0 00-1.061 0l-9.282 9.28a.75.75 0 101.061 1.061l8.751-8.75 8.751 8.75zm0-9.28a.75.75 0 101.06-1.062l-9.28-9.28a.75.75 0 00-1.061 0l-9.282 9.28a.75.75 0 001.061 1.061l8.751-8.75 8.751 8.75z" _fill="#81899D"/>'
  }
})
