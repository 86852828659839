/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrows/long-arrow': {
    width: 12,
    height: 42,
    viewBox: '0 0 12 42',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.53.47a.75.75 0 00-1.06 0L.697 5.243a.75.75 0 101.06 1.06L5.25 2.811V41.5h1.5V2.81l3.493 3.493a.75.75 0 001.06-1.06L6.53.47z" _fill="#81899D"/>'
  }
})
