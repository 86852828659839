<template>
    <div class="lk-info" v-click-outside="close">
        <template v-if="isButton">
            <z-button size="s" @click="setToggleDropdownState">
                <template v-slot:left>
                    <z-icon name="cabinet/sign_in"></z-icon>
                </template>
                Личный кабинет
                <template v-slot:right>
                    <z-icon name="arrows/caret-small" :dir="isDropdownShow ? 'up' : 'down'" color="#FFFFFF"></z-icon>
                </template>
            </z-button>
            <transition name="dropdown">
                <div class="lk-info__dropdown" v-show="isDropdownShow">
                    <div class="lk-info__item" v-for="(item, id) in accountLinks" :key="id">
                        <a class="lk-info__link" :href="item.link" target="_blank">
                            <z-icon name="tools/external-2"></z-icon>
                            <span>{{item.title}}</span>
                        </a>
                    </div>
                </div>
            </transition>
        </template>
        <template v-else>
            <div class="lk-info__item" v-for="(item, id) in accountLinks" :key="id">
                <a class="lk-info__link" :href="item.link" target="_blank">
                    <z-icon name="tools/external-2"></z-icon>
                    <span>{{item.title}}</span>
                </a>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'lk-info',
    props: {
        isButton: Boolean
    },
    data () {
        return {
            isDropdownShow: false,
            accountLinks: [
                {
                    title: 'Жилые помещения',
                    link: 'https://lk.oao-ntek.ru/login'
                },
                {
                    title: 'Нежилые помещения',
                    link: 'https://ul.oao-ntek.ru/login'
                },
                {
                    title: 'Технологическое присоединение',
                    link: 'https://tp.oao-ntek.ru/login'
                }
            ]
        }
    },
    methods: {
        close () {
            this.isDropdownShow = false
        },
        setToggleDropdownState () {
            this.isDropdownShow = !this.isDropdownShow
        }
    }
}
</script>

<style lang="scss">
.lk-info {
    &__dropdown {
        position: absolute;
        padding: $token-spacers-2-xs;
        width: 100%;
        background: $token-colors-white;
        border-radius: 4px;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
        z-index: 2;
    }

    &__item {
        &:not(:last-child) {
            margin-bottom: $token-spacers-2-xs;
        }
    }

    &__link {
        font-weight: 400;
        font-size: 14px;
        line-height: 1.3;
        text-decoration: none;
        color: $token-colors-link-dark-default;
        transition: color $transition;
        display: inline-flex;
        align-items: flex-start;

        &:hover {
            color: $token-colors-link-dark-hover;
            text-decoration: underline;

            .z-icon {
                path {
                    fill: $token-colors-link-dark-hover;
                }
            }
        }

        &:active {
            color: $token-colors-link-dark-active;

            .z-icon {
                path {
                    fill: $token-colors-link-dark-active;
                }
            }
        }

        .z-icon {
            margin-right: 6px;
            margin-top: 0.1em;
        }
    }
}
</style>
