/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrows/round-arrow': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12 23C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11-4.925 11-11 11zm0 1C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12zm.026-15.764a.498.498 0 00-.24.134l-3.414 3.414a.5.5 0 10.707.707l2.56-2.561v5.622a.5.5 0 101 0V9.93l2.561 2.561a.5.5 0 00.707-.707l-3.405-3.405a.502.502 0 00-.476-.142z" _fill="#81899D"/>'
  }
})
