<template>
    <div class="z-hero">
        <div class="z-hero__wrapper">
            <div class="container">
                <slot></slot>
                <h1 v-html="title"></h1>
            </div>
        </div>
        <div class="z-hero__image" v-if="image" :style="`background-image: url(${image}); background-position: ${position};`"></div>
    </div>
</template>

<script>
export default {
    name: 'z-hero',
    props: {
        title: {
            type: String,
            default: ''
        },
        image: {
            type: String
        },
        position: {
            type: String
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
