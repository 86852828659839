/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'social/share': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.55 6.6a1.65 1.65 0 113.3 0 1.65 1.65 0 01-3.3 0zm1.65-3.15a3.15 3.15 0 00-2.98 4.174l-3.252 2.09a3.15 3.15 0 100 4.57l3.252 2.092a3.15 3.15 0 10.812-1.261l-3.252-2.091a3.145 3.145 0 000-2.048l3.252-2.09A3.15 3.15 0 1016.2 3.45zm-8.4 6.9a1.65 1.65 0 100 3.3 1.65 1.65 0 000-3.3zm8.4 5.4a1.65 1.65 0 100 3.3 1.65 1.65 0 000-3.3z" _fill="#626C84"/>'
  }
})
