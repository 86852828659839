<template>
    <div class="purchase-results u-bottom-margin--xl">
        <div class="purchase-results__item" v-for="item in data" :key="Date.now() + item.id">
            <z-caption
               weight="600"
               size="m"
               tag="h3"
               :href="item.detailPageUrl"
               v-html="item.name"
            ></z-caption>
            <z-table
                :columns="['Запрос предложений', 'Номер лота:', 'Приём заявок до:', 'Заказчик:', 'Электронная площадка:']"
                :scrollable="true"
            >
                <z-table-row>
                    <z-table-col index="0" v-html="`№ ${item.id}`"></z-table-col>
                    <z-table-col index="1" v-html="item.number.value"></z-table-col>
                    <z-table-col index="2">{{ item.endDate.timestamp | momentFilter('DD.MM.YYYY') }}</z-table-col>
                    <z-table-col index="3" v-html="item.customer.value"></z-table-col>
                    <z-table-col index="4"  v-html="item.area.value"></z-table-col>
                </z-table-row>
            </z-table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'results',
    props: {
        data: {
            type: Array,
            default: () => []
        }
    }
}
</script>
