/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tools/burger': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3 4.5A.75.75 0 003 6h17.875a.75.75 0 000-1.5H3zM3 11a.75.75 0 000 1.5h17.875a.75.75 0 000-1.5H3zm-.75 7.25A.75.75 0 013 17.5h17.875a.75.75 0 010 1.5H3a.75.75 0 01-.75-.75z" _fill="#81899D"/>'
  }
})
