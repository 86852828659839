<template>
    <div class="col-default-4 col-tablet-6 col-mobile-12">
        <div class="media-library-card">
            <div class="media-library-card__image"
                 :class="{'media-library-card__image--video': item.type.xmlId === 'video'}"
                 :style="`background-image: url('${item.previewPicture.link}')`"/>
            <div class="media-library-card__type">
                <z-icon :name="item.type.xmlId === 'photo' ? 'media/image' : 'media/video'" width="24" height="24" color="#ffffff"/>
            </div>
            <div class="media-library-card__controls">
                <template v-if="item.type.xmlId === 'photo'">
                    <button class="media-library-card__button" type="button" @click="openModal">
                        <z-icon name="tools/magnifier-plus" width="17" height="17" color="#ffffff"/>
                    </button>
                    <a class="media-library-card__button" target="_blank" :href="item.detailPicture.link">
                        <z-icon name="tools/download-1" width="17" height="17" color="#ffffff"/>
                    </a>
                </template>
                <template v-else>
                    <button class="media-library-card__video-button" type="button" @click="openModal">
                        <z-icon class="media-library-card__play" name="tools/play_small" width="57" height="57"/>
                    </button>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Card',
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    methods: {
        openModal () {
            this.$emit('openModal')
        }
    }
}
</script>

<style lang="scss">
.media-library-card {
    position: relative;
    $parent: &;
    border-radius: 4px;
    overflow: hidden;

    &__image {
        width: 100%;
        padding-top: 76%;
        background-position: center;
        background-size: cover;
        position: relative;
        $image: &;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            width: 100%;
            height: 100%;
            background-color: #000000;
            opacity: 0.15;
            transition: opacity 300ms ease;
        }

        &:after {
            content: '';
            background: linear-gradient(180deg, rgba(0, 25, 39, 0.5) 0%, rgba(0, 25, 39, 0) 61.46%);
            display: block;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
        }

        &--video {
            &:before {
                opacity: 0.3;
            }
        }
    }

    &__type {
        position: absolute;
        top: 24px;
        right: 24px;
    }

    &:hover &__image:before {
        opacity: 0.6;
    }

    &:hover &__image--video:before {
        opacity: 0.3;
    }

    &:hover &__button {
        display: flex;
    }

    &__controls {
        position: absolute;
        top: 50%;
        left: 50%;
        display: flex;
        justify-content: center;
        transform: translate(-50%, -50%);
    }

    &__button {
        margin: 0;
        padding: 0;
        background-color: $token-colors-button-primary-bg-default;
        width: 33px;
        height: 33px;
        border: none;
        border-radius: 4px;
        align-items: center;
        justify-content: center;
        display: none;

        &:not(:last-child) {
            margin-right: 16px;
        }

        @include breakpoint(tablet) {
            display: flex;
        }
    }

    &__video-button {
        padding: 0;
        margin-right: 0;
        border: none;
        background-color: transparent;

        .z-icon {
            path {
                fill: $token-colors-white;
                transition: fill 300ms ease;
            }
        }

        &:hover {
            .z-icon {
                path {
                    fill: $token-colors-primary;
                }
            }
        }
    }
}
</style>
