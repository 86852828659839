<template>
    <div
        class="z-tabs"
        :class="classObject"
        ref="tab"
    >
        <div class="z-tabs__container">
            <button class="z-tabs__button z-tabs__button--left" v-if="showControl" @click="scrollToLeft" :disabled="disabledLeftButton"/>
            <div ref="header" class="z-tabs__header">
                <slot name="label"/>
            </div>
            <button class="z-tabs__button z-tabs__button--right" v-if="showControl" @click="scrollToRight" :disabled="disabledRightButton"/>
        </div>
        <slot name="content"/>
    </div>
</template>

<script>
export default {
    name: 'z-tabs',
    props: {
        nested: Boolean,
        onlyVisual: Boolean,
        theme: {
            type: String,
            validator: prop => ['light', 'dark'].includes(prop),
            default: 'light'
        },
        size: {
            type: String,
            default: 'm',
            validator: prop => ['m', 's'].includes(prop)
        },
        padding: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            name: 'z-tabs',
            scrollValue: 0,
            currentWidth: 0,
            maxWidth: 0,
            hash: window.location.hash,
            openedItem: {
                id: {
                    type: String,
                    default: ''
                }
            }
        }
    },
    mounted () {
        this.$nextTick(() => this.initScroll())
        window.addEventListener('resize', this.widthHandler)
        if (!this.onlyVisual) {
            this.defaultState()
            this.$nextTick(() => this.checkHash())
            this.$on('toggle-item', data => this.openHandler(data))
        } else {
            this.$on('toggle-item', data => this.openHandler(data))
        }
    },
    beforeDestroy () {
        if (!this.onlyVisual) {
            this.$off('toggle-item', data => this.openHandler(data))
        }
    },
    computed: {
        showControl () {
            return this.currentWidth < this.maxWidth
        },
        disabledLeftButton () {
            return this.scrollValue === 0
        },
        disabledRightButton () {
            return this.scrollValue === this.maxWidth - this.currentWidth
        },
        currentHash () {
            return this.hash ? this.hash.split('#')[1] : ''
        },
        classObject () {
            return [
                `z-tabs--size-${this.size}`,
                `z-tabs--theme-${this.theme}`,
                {'z-tabs--without-padding': !this.padding}
            ]
        }
    },
    methods: {
        widthHandler () {
            this.currentWidth = this.$refs.header.offsetWidth
            this.maxWidth = this.$refs.header.scrollWidth
        },
        initScroll () {
            let currentElement
            this.$children.forEach((item) => {
                if (item.$el.getAttribute('href') === this.hash) {
                    currentElement = item.$el
                }
            })
            if (currentElement) {
                const parent = this.$refs.header.offsetLeft
                this.scrollValue = currentElement.offsetLeft - parent
                this.$refs.header.scrollTo({
                    left: this.scrollValue,
                    behavior: 'smooth'
                })
            }
        },
        scrollToLeft () {
            if ((this.scrollValue - this.currentWidth) > 0) {
                this.scrollValue -= this.currentWidth
            } else {
                this.scrollValue = 0
            }
            this.$refs.header.scrollTo({
                left: this.scrollValue,
                behavior: 'smooth'
            })
        },
        scrollToRight () {
            if ((this.scrollValue + this.currentWidth) <= this.maxWidth - this.currentWidth) {
                this.scrollValue += this.currentWidth
            } else {
                this.scrollValue = this.maxWidth - this.currentWidth
            }
            this.$refs.header.scrollTo({
                left: this.scrollValue,
                behavior: 'smooth'
            })
        },
        openHandler (data) {
            if (!this.onlyVisual) {
                this.openedItem.id = data

                for (let i = 0; i < this.$children.length; i++) {
                    if (this.$children[i].itemId === this.openedItem.id) {
                        this.$children[i].state = true
                    } else {
                        this.$children[i].state = false
                    }
                }

                this.$emit('open-tab', this.openedItem.id)
            } else {
                const filter = data.split('-')
                this.openedItem.id = data

                for (let i = 0; i < this.$children.length; i++) {
                    if (this.$children[i].itemId === this.openedItem.id) {
                        this.$children[i].state = true
                    } else {
                        this.$children[i].state = false
                    }
                }

                this.$emit('open-tab', filter[1])
            }
        },
        checkHash () {
            if (!this.onlyVisual) {
                if (this.currentHash.length) {
                    let matched = this.$children.filter(item => item.itemId === this.currentHash)

                    if (matched.length) this.openItem()
                }
            }
        },
        defaultState () {
            let label = this.$children.filter(item => item.name === 'z-tabs-label')[0]
            let content = this.$children.filter(item => item.name === 'z-tabs-content')[0]

            label.state = true
            content.state = true
        },
        closeAll () {
            for (let i = 0; i < this.$children.length; i++) {
                this.$children[i].state = false
            }
        },
        openItem () {
            console.log('lol')
            for (let i = 0; i < this.$children.length; i++) {
                if (this.$children[i].itemId === this.currentHash) {
                    this.$children[i].state = true

                    this.openedItem.id = this.$children[i].itemId

                    if (this.nested) this.openParent(this.$parent)
                    if (this.$parent.name === 'z-accordion-item') this.openAccordion(this.$parent)
                } else {
                    this.$children[i].state = false
                }
            }
        },
        openParent (parent) {
            let grandParent = parent.$parent
            let grandParentChildrens = grandParent.$children
            let grandParentTabs = grandParentChildrens.filter(item => item.name === 'z-tabs-label')
            let grandParentContent = grandParentChildrens.filter(item => item.name === 'z-tabs-content')

            for (let i = 0; i < grandParentTabs.length; i++) {
                if (grandParentTabs[i].itemId === parent.itemId) {
                    grandParentTabs[i].state = true
                    grandParentContent[i].state = true
                } else {
                    grandParentTabs[i].state = false
                    grandParentContent[i].state = false
                }
            }

            if (grandParent.$parent.name === 'z-accordion-item') this.openAccordion(grandParent.$parent)

            if (grandParent.name === 'z-tabs' && grandParent.nested) {
                grandParent.$parent.state = true
                this.openParent(grandParent.$parent)
            }
        },
        openAccordion (parent) {
            let accordion = parent.$parent

            accordion.openParent(parent)
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
