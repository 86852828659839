<template>
    <component
        :is="href ? 'a' : tag"
        class="z-caption"
        :class="classObject"
        :href="href"
        :style="styleObject"
    >
        <z-icon
            v-if="icon"
            :name="icon"
            class="z-caption__icon"
        />
        <span class="z-caption__text">
            <slot />
            <z-icon
                v-if="href"
                class="z-caption__arrow"
                name="slider"
                dir="right"
            />
        </span>
    </component>
</template>

<script>
export default {
    name: 'z-caption',
    props: {
        tag: {
            type: String,
            default: 'p',
            validator: prop => ['a', 'span', 'p', 'caption', 'figcaption', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(prop)
        },
        weight: {
            type: String,
            validator: prop => ['100', '200', '300', '400', '500', '600', '700', '800', '900'].includes(prop)
        },
        size: {
            type: String,
            default: 'xl',
            validator: prop => ['5xl', '4xl', '3xl', '2xl', 'xl', 'l', 'm', 's', 'xs', '2xs'].includes(prop)
        },
        italic: {
            type: Boolean,
            default: false
        },
        uppercase: {
            type: Boolean,
            default: false
        },
        lowercase: {
            type: Boolean,
            default: false
        },
        icon: String,
        theme: {
            type: String,
            validator: prop => ['indent'].includes(prop)
        },
        borderTop: {
            type: Boolean,
            default: false
        },
        borderTopWidth: {
            type: Number,
            default: 10
        },
        borderBottom: {
            type: Boolean,
            default: false
        },
        borderBottomWidth: {
            type: Number,
            default: 4
        },
        href: [String, Boolean],
        bgImage: String,
        bgIndent: {
            type: Number,
            default: 64
        },
        bgSize: {
            type: String,
            default: 'xl',
            validator: prop => ['xl', 'l', 'm'].includes(prop)
        },
        bgCentered: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            bgImageHeight: {
                xl: 200,
                l: 140,
                m: 80
            }
        }
    },
    computed: {
        classObject () {
            return ({
                'u-italic': this.italic,
                'u-uppercase': this.uppercase,
                'u-lowercase': this.lowercase,
                'z-caption--link': this.href,
                'z-caption--with-icon': this.icon,
                'z-caption--border-top': this.borderTop,
                'z-caption--border-bottom': this.borderBottom,
                'z-caption--bg': this.bgImage,
                'z-caption--bg-centered': this.bgCentered,
                [`z-caption--size-${this.size}`]: this.size,
                [`z-caption--weight-${this.weight}`]: this.weight,
                [`z-caption--theme-${this.theme}`]: this.theme
            })
        },
        styleObject () {
            let result = {}

            if (this.borderTop) result.borderTopWidth = `${this.borderTopWidth}px`
            if (this.borderBottom) result.borderBottomWidth = `${this.borderBottomWidth}px`

            if (this.bgImage) {
                result.backgroundImage = `url(${this.bgImage})`
                result.paddingLeft = `${this.bgIndent}px`
                result.backgroundSize = `auto ${this.bgImageHeight[this.bgSize]}px`
                result.minHeight = `${this.bgImageHeight[this.bgSize]}px`
            }

            return result
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
