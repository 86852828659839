/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/big/zip': {
    width: 56,
    height: 56,
    viewBox: '0 0 56 56',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.933 4.667H30.6a1 1 0 01.707.293l1.807 1.807h-.011l.472.472L45.1 18.765v-.01l1.807 1.806a1 1 0 01.293.707V46.9a5.6 5.6 0 01-5.6 5.6H14.933a5.6 5.6 0 01-5.6-5.6V10.267a5.6 5.6 0 015.6-5.6zM45.101 20.94H32.875a1.4 1.4 0 01-1.4-1.4V6.767H14.933a3.5 3.5 0 00-3.5 3.5V46.9a3.5 3.5 0 003.5 3.5h26.668a3.5 3.5 0 003.5-3.5V20.941zm-11.526-2.1v-8.633l8.633 8.633h-8.633z" _fill="#1092DC"/><path pid="1" d="M19.57 37.833v-1.128l4.464-6.048H19.57v-1.284h6.264v1.128L21.37 36.55h4.644v1.284H19.57zm7.385 0v-8.46h1.536v8.46h-1.536zm3.135 0v-8.46h3.672c.928 0 1.644.232 2.148.696.504.464.756 1.112.756 1.944 0 .832-.252 1.484-.756 1.956-.504.464-1.22.696-2.148.696h-2.136v3.168H30.09zm1.536-4.38h1.896c1.104 0 1.656-.48 1.656-1.44 0-.952-.552-1.428-1.656-1.428h-1.896v2.868z" _fill="#1092DC"/>'
  }
})
