/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/big/png': {
    width: 56,
    height: 56,
    viewBox: '0 0 56 56',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.934 4.667H30.6a1 1 0 01.707.293l1.807 1.807h-.01l.471.472 11.526 11.526v-.01l1.808 1.806a1 1 0 01.293.707V46.9a5.6 5.6 0 01-5.6 5.6H14.934a5.6 5.6 0 01-5.6-5.6V10.267a5.6 5.6 0 015.6-5.6zM45.102 20.94H32.874a1.4 1.4 0 01-1.4-1.4V6.767H14.934a3.5 3.5 0 00-3.5 3.5V46.9a3.5 3.5 0 003.5 3.5h26.668a3.5 3.5 0 003.5-3.5V20.941zm-11.527-2.1v-8.633l8.633 8.633h-8.633z" _fill="#1092DC"/><path pid="1" d="M16.344 37.833v-8.46h3.672c.928 0 1.644.232 2.148.696.504.464.756 1.112.756 1.944 0 .832-.252 1.484-.756 1.956-.504.464-1.22.696-2.148.696H17.88v3.168h-1.536zm1.536-4.38h1.896c1.104 0 1.656-.48 1.656-1.44 0-.952-.552-1.428-1.656-1.428H17.88v2.868zm6.052 4.38v-8.46h1.152l4.548 5.904v-5.904h1.428v8.46h-1.14l-4.56-5.928v5.928h-1.428zm12.804.12c-.936 0-1.728-.176-2.376-.528a3.531 3.531 0 01-1.464-1.5c-.336-.648-.504-1.416-.504-2.304 0-.896.172-1.668.516-2.316a3.57 3.57 0 011.488-1.512c.648-.36 1.424-.54 2.328-.54.592 0 1.148.088 1.668.264.52.168.952.404 1.296.708l-.516 1.2a3.752 3.752 0 00-1.152-.636 4.157 4.157 0 00-1.296-.192c-.896 0-1.576.26-2.04.78-.456.52-.684 1.268-.684 2.244 0 .984.236 1.732.708 2.244.472.512 1.172.768 2.1.768.264 0 .532-.02.804-.06.28-.048.556-.116.828-.204v-1.968h-1.944v-1.14h3.228v4.068a5.921 5.921 0 01-1.392.456 7.786 7.786 0 01-1.596.168z" _fill="#1092DC"/>'
  }
})
