<template>
    <div class="modal-html">
        <h4
            class="modal-html__name"
            v-html="data.name"
            v-if="!hasSlotData"
        ></h4>
        <vue-raw
            class="modal-html__detail html-content"
            v-if="!hasSlotData"
            :raw="data.detail"
        ></vue-raw>
        <slot v-else></slot>
    </div>
</template>

<script>
export default {
    props: {
        data: Object,
        hasSlotData: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss">
    .modal-html {
        width: 100%;
        min-height: 320px;
        position: relative;
        font-size: $token-typo-s-desktop-size;
        padding: 40px 96px 40px 40px;

        @include breakpoint (mobile) {
           padding: 32px 20px;
        }

        &__name {
            font-size: 1.75em;
            line-height: 1.2;
            color: $token-colors-black;
            padding-bottom: 16px;
            margin: 0 !important;
        }

        &__detail {
            font-size: 13px;
            line-height: 1.2;
        }
    }

</style>
