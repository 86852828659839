<template>
    <section v-if="data.length" class="management row">
        <a :href="item.detailPageUrl" v-for="(item, index) in data" class="col-default-4 col-tablet-6 col-mobile-12 management__card" :key="`management-${index}`">
            <div class="management__avatar" :style="`background-image: url('${getAvatar(item)}')`" />
            <span class="management__name" v-html="item.name" />
            <span class="text-size-s u-align-center black-60-text" v-html="item.position.value" />
        </a>
    </section>
</template>

<script>
export default {
    name: 'management',
    data () {
        return {
            data: this.$root.app.components.governance?.items || []
        }
    },
    methods: {
        getAvatar (item) {
            if (item.previewPicture.link) {
                return item.previewPicture.link
            }

            return item.gender.xmlId === 'female' ? '/images/management/female.jpg' : '/images/management/male.jpg'
        }
    }
}
</script>

<style lang="scss">
.management {
    &__avatar {
        width: 100%;
        padding-top: 100%;
        @include margin-level(bottom, XS);
        background-position: center;
        background-size: cover;
        border-radius: 4px;
    }

    &__name {
        font-weight: 600;
        line-height: 1.15;
        @include typo-level(L);
        text-align: center;
        margin-bottom: $token-spacers-3-xs;
        transition: color $transition;
    }

    &__card {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: $token-spacers-3-xs;
        transition: background $transition;
        border-radius: 4px;
        padding: $token-spacers-2-xs;
        text-decoration: none;

        &:hover {
            cursor: pointer;
            background: $token-colors-bg-10;
        }
    }
}
</style>
