/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'corporate/utiso': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M27.048 23.347H28.93V7.26H11.295v7.312H4.534c-.837 0-1.534.697-1.534 1.532v7.104h2.08a2.024 2.024 0 003.925 0h6.044a2.024 2.024 0 003.924 0h2.708v-2.995c0-.487-.21-.905-.627-1.184l-.419-.26V9.56H26.7v13.156a.388.388 0 00-.07.214c0 .209.21.418.418.418zm-7.25-13.789v8.693l-.697-.433a.399.399 0 00-.069-.186v-.762a2.32 2.32 0 00-2.3-2.298h-2.997V9.558h6.064zm.42-.835h7.249V22.51h.697V8.026H12.13v6.546h.767v-5.85h7.32zm-8.291 6.546H15.059v2.994h3.32l2.256 1.393c.21.07.28.279.28.488h-.698a.45.45 0 00-.418.417c0 .21.209.418.418.418h.697v1.393h-1.912a2.023 2.023 0 00-3.983 0H9.034a2.023 2.023 0 00-3.982 0H3.767v-1.393h.697c.278 0 .418-.209.418-.418a.45.45 0 00-.418-.418h-.697v-.905h.697c.278 0 .418-.209.418-.418a.45.45 0 00-.418-.417h-.697v-2.368c0-.418.348-.766.767-.766H11.5c.065.043.139.07.213.07a.39.39 0 00.214-.07zm3.969 2.159v-2.16h.836c.836 0 1.533.697 1.533 1.533v.627h-2.37zm-.043 5.53c.118.524.611.946 1.158.946.627 0 1.185-.557 1.185-1.184 0-.627-.488-1.184-1.185-1.184-.583 0-1.106.481-1.177 1.052a.383.383 0 01.062.202.37.37 0 01-.043.169zm-8.81-1.422c-.627 0-1.185.557-1.185 1.184 0 .627.558 1.184 1.185 1.184s1.185-.557 1.185-1.184c0-.627-.488-1.184-1.185-1.184z" _fill="#81899D"/>'
  }
})
