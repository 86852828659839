<template>
    <button class="download-button">
        <z-icon :name="icon" v-if="icon"></z-icon>
        <z-link external :href="href" v-if="href">{{ text }}</z-link>
        <span v-html="text" v-else></span>
    </button>
</template>

<script>
export default {
    name: 'download-button',
    props: {
        icon: String,
        text: String,
        href: String
    }

}
</script>

<style lang="scss">
.download-button {
    @include padding-level(top, S);
    @include padding-level(bottom, S);
    padding-left: 90px;
    padding-right: 90px;
    background: $token-colors-bg-5;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    outline: 0;
    user-select: none;
    cursor: pointer;
    border: none;
    transition: background $transition;
    display: block;
    width: 100%;
    color: $token-colors-link-default;
    @include typo-level(S);

    .z-link--external .z-link__icon {
        color: $token-colors-link-default;
    }

    &:hover {
        background: $token-colors-bg-10;
    }

    &:active {
        background: $token-colors-bg-15;
    }

    path {
        fill: currentColor;
    }
}
</style>
