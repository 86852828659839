/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'media/presentation': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.25 4.5A1.5 1.5 0 013.75 3h16.5a1.5 1.5 0 011.5 1.5V6a1.5 1.5 0 01-1.5 1.5v9H21a.75.75 0 110 1.5H19.5 19.5h-6.75v1.628a2.251 2.251 0 11-1.5 0V18H4.501 4.5 3a.75.75 0 010-1.5h.75v-9A1.5 1.5 0 012.25 6V4.5zm3 12h13.5v-9H5.25v9zm15-10.5H3.75V4.5h16.5V6zm-10.5 6a.75.75 0 00-1.5 0v1.5a.75.75 0 001.5 0V12zM12 10.5a.75.75 0 01.75.75v2.25a.75.75 0 01-1.5 0v-2.25a.75.75 0 01.75-.75zm3.75 0a.75.75 0 00-1.5 0v3a.75.75 0 101.5 0v-3zm-4.5 11.25a.75.75 0 111.5 0 .75.75 0 01-1.5 0z" _fill="#81899D"/>'
  }
})
