<template>
    <div :class="classObject" ref="tooltip">
        <slot />
        <z-icon
            v-show="!iconHidden"
            width="16"
            height="16"
            name="tools/tooltip"
            dir="down"
            class="z-tooltip__icon"
        />
    </div>
</template>

<script>
import tippy from 'tippy.js'

export default {
    name: 'z-tooltip',
    props: {
        arrow: {
            type: Boolean,
            default: true
        },
        placement: {
            type: String,
            default: 'right'
        },
        content: {
            type: String,
            required: true
        },
        trigger: {
            type: String,
            default: 'mouseenter'
        },
        interactive: {
            type: Boolean,
            default: false
        },
        iconHidden: Boolean
    },
    computed: {
        classObject () {
            return [
                'z-tooltip',
                { 'z-tooltip--click': this.trigger === 'click' }
            ]
        }
    },
    created () {
        this.$nextTick(() => {
            tippy(this.$refs.tooltip, {
                content: this.content,
                placement: this.placement,
                arrow: this.arrow,
                trigger: this.trigger,
                allowHTML: true,
                interactive: this.interactive
            })
        })
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
