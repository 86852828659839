/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/big/pdf': {
    width: 56,
    height: 56,
    viewBox: '0 0 56 56',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.934 4.667H30.6a1 1 0 01.707.293l1.807 1.807h-.01l.471.472 11.526 11.526v-.01l1.808 1.806a1 1 0 01.293.707V46.9a5.6 5.6 0 01-5.6 5.6H14.934a5.6 5.6 0 01-5.6-5.6V10.267a5.6 5.6 0 015.6-5.6zM45.102 20.94H32.874a1.4 1.4 0 01-1.4-1.4V6.767H14.934a3.5 3.5 0 00-3.5 3.5V46.9a3.5 3.5 0 003.5 3.5h26.668a3.5 3.5 0 003.5-3.5V20.941zm-11.527-2.1v-8.633l8.633 8.633h-8.633z" _fill="#1092DC"/><path pid="1" d="M17.31 37.833v-8.46h3.673c.927 0 1.643.232 2.148.696.503.464.756 1.112.756 1.944 0 .832-.253 1.484-.756 1.956-.505.464-1.22.696-2.148.696h-2.137v3.168h-1.535zm1.537-4.38h1.895c1.104 0 1.656-.48 1.656-1.44 0-.952-.552-1.428-1.655-1.428h-1.896v2.868zm6.052 4.38v-8.46h3.144c1.416 0 2.508.364 3.276 1.092.776.728 1.164 1.772 1.164 3.132 0 1.352-.388 2.396-1.164 3.132-.768.736-1.86 1.104-3.276 1.104h-3.144zm1.536-1.272h1.512c1.952 0 2.928-.988 2.928-2.964 0-1.968-.976-2.952-2.928-2.952h-1.512v5.916zm7.364 1.272v-8.46h5.532v1.224h-3.996v2.388h3.744v1.224h-3.744v3.624H33.8z" _fill="#1092DC"/>'
  }
})
