/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'contacts/phone': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8.67 11.701a7.908 7.908 0 003.66 3.645.742.742 0 00.735-.056l2.347-1.565a.75.75 0 01.711-.065l4.391 1.882a.748.748 0 01.45.778 4.501 4.501 0 01-4.464 3.93A12.75 12.75 0 013.75 7.5a4.5 4.5 0 013.93-4.464.747.747 0 01.778.45l1.884 4.394a.751.751 0 01-.062.706L8.72 10.97a.743.743 0 00-.05.731z" _stroke="#81899D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
