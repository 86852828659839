<template>
    <div class="purchase-filters u-bottom-margin--xl">
        <div class="row">
            <div class="col-default-12">
                <z-input
                    class="purchase-filters__search"
                    name="suppliers"
                    placeholder="Предмет закупки или № закупки"
                    @input="send"
                    @keyup.enter="send"
                    v-model="formData.query"
                />
            </div>
            <div class="col-default-6 col-mobile-12">
               <z-select
                    placeholder="Заказчик"
                    :data="selects"
                    @change="send"
                    v-model="formData.customer"
                    :clearable="true"
                />
            </div>
            <div class="col-default-6 col-mobile-12">
                <z-date-picker
                    placeholder="Дата окончания приема заявок"
                    @change="send"
                    v-model="formData.date"
                    :clearable="true"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { queryString } from '@/utils/queryString'

export default {
    name: 'filters',
    props: {
        selects: {
            type: Array
        }
    },
    data () {
        return {
            formData: {
                query: '',
                customer: '',
                date: ''
            }
        }
    },
    methods: {
        send () {
            this.$emit('search', this.formData)
            queryString(this.clearEmptyParams)
        }
    },
    computed: {
        clearEmptyParams () {
            let params = Object.assign({}, this.formData)
            Object.keys(params).forEach(function (key, index) {
                if (Array.isArray(params[key])) {
                    if (!(params[key].filter(val => { return val !== null && val !== undefined && val !== '' }).length)) delete params[key]
                } else if (!params[key]) {
                    delete params[key]
                }
            })
            return params
        }
    }
}
</script>
