<template>
    <div class="layout">
        <div v-if="!!this.$slots['top']" class="layout__top">
            <slot name="top" />
        </div>
        <div
            class="layout__container"
            :class="{ 'container': type !== 'wide' }"
        >
            <div class="layout__content" :class="{ 'layout__content--wide': type === 'wide' || type === 'one-column' }">
                <slot name="content" />
            </div>
            <aside class="layout__aside" v-if="type === 'two-columns'">
                <slot name="aside" />
            </aside>
        </div>
    </div>
</template>

<script>
export default {
    name: 'layout',
    props: {
        type: {
            type: String,
            default: 'one-column',
            validator: prop => ['wide', 'two-columns', 'one-column'].includes(prop)
        }
    }
}
</script>

<style lang="scss">
.layout {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;

    &__container {
        flex-grow: 0;
        flex-shrink: 1;
        overflow: hidden;
        display: flex;
    }

    &__top {
        flex-shrink: 0;
        flex-grow: 0;
    }

    &__content {
        flex-basis: 71%;
        max-width: 71%;
        flex-shrink: 0;

        &--wide {
            flex-basis: 100%;
            max-width: 100%;
        }
    }

    &__aside {
        margin-left: $token-spacers-2-xl;
        max-width: 29%;
        flex-basis: 29%;

        @include breakpoint (tablet) {
            display: none;
        }
    }

    &__content,
    &__aside {
        @include padding-level (bottom, XL);
        @include padding-level (top, M);

        @include breakpoint (tablet) {
            flex-basis: 100%;
            max-width: 100%;
        }
    }
}
</style>
