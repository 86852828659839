/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrows/triangle': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12 9.444L7 15h10l-5-5.556z" _fill="#81899D"/>'
  }
})
