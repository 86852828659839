/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tools/magnifier-plus': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.875 3.75a7.125 7.125 0 100 14.25 7.125 7.125 0 000-14.25zM2.25 10.875a8.625 8.625 0 1115.23 5.545l4.05 4.05a.75.75 0 01-1.06 1.06l-4.05-4.049a8.625 8.625 0 01-14.17-6.607zm9.375-3a.75.75 0 00-1.5 0v2.25h-2.25a.75.75 0 000 1.5h2.25v2.25a.75.75 0 001.5 0v-2.25h2.25a.75.75 0 000-1.5h-2.25v-2.25z" _fill="#81899D"/>'
  }
})
