/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'social/ok': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" d="M26.238 22.793c.524-.525.6-1.274.15-2.024-.375-.6-1.05-.9-1.8-.75-.3.075-.6.225-.899.45-2.324 1.574-5.472 1.5-7.796-.075l-.45-.225c-.824-.375-1.649-.225-2.099.525-.524.75-.45 1.574.225 2.249.15.15.3.225.375.375l.075.074c1.05.825 2.324 1.35 3.973 1.575l-.975.974-2.548 2.549c-.225.225-.525.6-.525 1.124 0 .675.375 1.35 1.05 1.65.224.15.45.15.674.15.375 0 .825-.15 1.125-.525l2.923-2.924.075-.075s.075 0 .075.075c.6.675 1.274 1.274 1.874 1.95l.9.899c.374.375.674.524 1.124.524.675.075 1.424-.374 1.724-1.05.3-.599.15-1.348-.375-1.798-.75-.75-1.499-1.574-2.324-2.324l-1.274-1.274c.675-.075 1.424-.225 2.099-.525 1.124-.375 1.949-.9 2.624-1.574z" _fill="#81899D"/><path pid="1" d="M19.791 20.694c3.223 0 5.847-2.549 5.847-5.772 0-1.574-.6-3.074-1.724-4.198C22.789 9.6 21.365 9 19.866 9c-3.223 0-5.772 2.624-5.847 5.772 0 1.574.6 2.998 1.724 4.123 1.05 1.2 2.474 1.799 4.048 1.799zm-1.65-7.496c.45-.45 1.05-.675 1.65-.675 1.35 0 2.324 1.05 2.324 2.324 0 1.35-1.05 2.324-2.324 2.324-1.35 0-2.324-1.05-2.324-2.324 0-.6.225-1.2.675-1.65z" _fill="#81899D"/><path pid="2" d="M26.238 22.793c.524-.525.6-1.274.15-2.024-.375-.6-1.05-.9-1.8-.75-.3.075-.6.225-.899.45-2.324 1.574-5.472 1.5-7.796-.075l-.45-.225c-.824-.375-1.649-.225-2.099.525-.524.75-.45 1.574.225 2.249.15.15.3.225.375.375l.075.074c1.05.825 2.324 1.35 3.973 1.575l-.975.974-2.548 2.549c-.225.225-.525.6-.525 1.124 0 .675.375 1.35 1.05 1.65.224.15.45.15.674.15.375 0 .825-.15 1.125-.525l2.923-2.924.075-.075s.075 0 .075.075c.6.675 1.274 1.274 1.874 1.95l.9.899c.374.375.674.524 1.124.524.675.075 1.424-.374 1.724-1.05.3-.599.15-1.348-.375-1.798-.75-.75-1.499-1.574-2.324-2.324l-1.274-1.274c.675-.075 1.424-.225 2.099-.525 1.124-.375 1.949-.9 2.624-1.574z" _fill="#81899D"/>'
  }
})
