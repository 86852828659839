/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'development': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<g clip-path="url(#clip0_3611_15822)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M35.875 62H34V51.687h-1.875v-1.874H40.563c2.625 0 4.687-2.063 4.687-4.688V35.75h5.625V35l-2.537-5.497-25.682-4.066h-1.781v-1.875h2.063l24.445 3.87-2.133-4.62v-.187c0-2.835-.638-5.53-1.777-7.945l-20.63 3.258h-1.968v-1.875h1.781l19.877-3.13c-3.297-5.42-9.26-9.058-16.033-9.058-4.5 0-8.812 1.594-12.281 4.594L13 6.969A20.855 20.855 0 0126.5 2a20.596 20.596 0 0118.054 10.614l13.821-2.177h1.875v1.876h-1.781l-13.048 2.06a20.592 20.592 0 011.704 8.065l2.467 5.344 8.877 1.405h1.781v1.875h-2.063l-7.64-1.209 2.203 4.772v3h-5.625v7.5a6.522 6.522 0 01-6.562 6.563h-4.688V62zm-18.75-36.563H19v-9.75l-5.25-5.25h-2.625l-5.25 5.25v9.75H7.75v-9l4.125-4.125H13l2.813 2.813-2.438 2.438v7.875h1.875v-7.125l1.875-1.875v9zM4 27.313h16.875v1.875H19v1.875h1.875v1.875H19V54.5h1.875V62H19v-5.625H4V54.5h13.125v-1.875H7.75V50.75h9.375v-1.875H7.75V47h9.375v-1.875H7.75V43.25h9.375V32.937H4v-1.874h1.875v-1.875H4v-1.875zm3.75 1.875v1.875h1.875v-1.875H7.75zm9.375 1.875H15.25v-1.875h1.875v1.875zm-3.75-1.875v1.875H11.5v-1.875h1.875zM15.25 58.25h1.875v1.875H15.25V58.25zm-1.875 0H11.5v1.875h1.875V58.25zm-5.625 0h1.875v1.875H7.75V58.25z" _fill="#0077C8"/></g><defs><clipPath id="clip0_3611_15822"><path pid="1" _fill="#fff" d="M0 0h64v64H0z"/></clipPath></defs>'
  }
})
