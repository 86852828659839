/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar/ms-outlook': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12 2.25A9.737 9.737 0 0121.75 12 9.737 9.737 0 0112 21.75 9.737 9.737 0 012.25 12 9.737 9.737 0 0112 2.25zM5.825 7.19l7.28-1.43v12.48l-7.28-1.495V7.19zm7.735 3.705v-2.47h4.095c.195 0 .455.26.455.52l-3.64 2.47h-.065l-.845-.52zm-2.86-.52c-.325-.39-.715-.585-1.17-.585-.52 0-.91.26-1.17.585-.325.455-.455.975-.455 1.625s.13 1.17.455 1.56c.325.39.715.585 1.17.585.455 0 .845-.195 1.17-.585.325-.455.455-.975.455-1.625s-.13-1.17-.455-1.56zm3.9 1.95c-.13.065 3.575-2.405 3.575-2.405v4.485c0 .52-.325.715-.65.715H13.56v-3.315l.78.52c.13.065.26 0 .26 0zm-5.72-1.3c.195-.26.39-.39.65-.39s.455.13.715.39c.13.26.195.585.195 1.04 0 .39-.13.715-.26.975-.195.195-.39.325-.65.325a.695.695 0 01-.585-.39c-.195-.26-.26-.585-.26-.975s.065-.715.195-.975z" _fill="#81899D"/>'
  }
})
