/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tools/unlock': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12 2.25a2.625 2.625 0 00-2.625 2.625V7.5H19.5A1.5 1.5 0 0121 9v10.5a1.5 1.5 0 01-1.5 1.5h-15A1.5 1.5 0 013 19.5V9a1.5 1.5 0 011.5-1.5h3.375V4.875a4.125 4.125 0 018.25 0 .75.75 0 01-1.5 0A2.625 2.625 0 0012 2.25zM4.5 9h15v10.5h-15V9zm9 5.25a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" _fill="#81899D"/>'
  }
})
