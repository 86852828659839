<template>
    <div class="news-filters">
        <div class="news-filters__content">
            <div class="news-filters__item">
                <span class="news-filters__caption">
                    {{ text.subtitles.search }}
                </span>
                <z-input
                    name="search"
                    theme="search"
                    :value="updatedFilters.search"
                    @change="onChange"
                    :placeholder="text.placeholders.search"
                />
            </div>
            <div
                class="news-filters__item news-filters__item--full-width"
                v-if="updatedFilters.years && updatedFilters.years.length"
            >
                <span class="news-filters__caption">
                    {{ text.subtitles.years }}
                </span>
                <div class="z-tabs__container">
                    <div class="z-tabs__header">
                        <button
                            v-for="year in updatedFilters.years"
                            :key="year.id"
                            @click="onChange({
                                value: year.id,
                                name: 'years'
                            })"
                            class="z-tabs-label"
                            :class="{
                                'z-tabs-label--opened' : year.selected
                            }"
                        >{{ year.text }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'news-filters',
    props: {
        data: Object
    },
    data () {
        return {
            text: {
                subtitles: {
                    search: this.$root.lang === 'ru' ? 'Поиск' : 'Search',
                    years: this.$root.lang === 'ru' ? 'Год' : 'Year'
                },
                placeholders: {
                    years: this.$root.lang === 'ru' ? 'Выберите год' : 'Select year',
                    search: this.$root.lang === 'ru' ? 'Введите ключевое слово' : 'Enter a keyword'
                }
            }
        }
    },
    computed: {
        updatedFilters () {
            return this.data
        }
    },
    methods: {
        onChange (data) {
            this.$emit('change', data)
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
