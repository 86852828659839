/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tools/link': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M16.485 3.75A3.66 3.66 0 0119.073 10l-2.835 2.835a3.662 3.662 0 01-5.177 0A.75.75 0 1010 13.896a5.16 5.16 0 007.297 0l2.835-2.835a5.159 5.159 0 00-1.673-8.418 5.16 5.16 0 00-5.625 1.12l-1.984 1.984a.75.75 0 101.06 1.061l1.985-1.984a3.661 3.661 0 012.589-1.074zm-7.639 6.517a3.661 3.661 0 013.99.794.75.75 0 001.06-1.06 5.16 5.16 0 00-7.298 0l-2.835 2.834a5.16 5.16 0 107.298 7.298l1.984-1.984a.75.75 0 10-1.06-1.06L10 19.072a3.66 3.66 0 11-5.177-5.177l2.836-2.835c.34-.34.743-.61 1.187-.794z" _fill="#81899D"/>'
  }
})
