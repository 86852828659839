/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/big/mp3': {
    width: 56,
    height: 56,
    viewBox: '0 0 56 56',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.934 4.667H30.6a1 1 0 01.707.293l1.807 1.807h-.01l.471.472 11.526 11.526v-.01l1.808 1.806a1 1 0 01.293.707V46.9a5.6 5.6 0 01-5.6 5.6H14.934a5.6 5.6 0 01-5.6-5.6V10.267a5.6 5.6 0 015.6-5.6zM45.102 20.94H32.874a1.4 1.4 0 01-1.4-1.4V6.767H14.934a3.5 3.5 0 00-3.5 3.5V46.9a3.5 3.5 0 003.5 3.5h26.668a3.5 3.5 0 003.5-3.5V20.941zm-11.527-2.1v-8.633l8.633 8.633h-8.633z" _fill="#1092DC"/><path pid="1" d="M16.467 37.833v-8.46h1.284l3 5.532 2.988-5.532H25v8.46h-1.392v-5.7l-2.424 4.416h-.9l-2.424-4.392v5.676h-1.392zm10.154 0v-8.46h3.672c.928 0 1.644.232 2.148.696.504.464.756 1.112.756 1.944 0 .832-.252 1.484-.756 1.956-.504.464-1.22.696-2.148.696h-2.136v3.168h-1.536zm1.536-4.38h1.896c1.104 0 1.656-.48 1.656-1.44 0-.952-.552-1.428-1.656-1.428h-1.896v2.868zm8.56 4.5a5.429 5.429 0 01-1.656-.252c-.52-.176-.952-.412-1.296-.708l.504-1.176c.72.56 1.52.84 2.4.84 1.08 0 1.62-.424 1.62-1.272 0-.4-.136-.696-.408-.888-.264-.2-.652-.3-1.164-.3h-1.356v-1.272h1.212c.448 0 .804-.108 1.068-.324.264-.224.396-.532.396-.924 0-.368-.124-.648-.372-.84-.24-.192-.592-.288-1.056-.288-.832 0-1.564.288-2.196.864l-.504-1.152c.336-.32.756-.568 1.26-.744a4.734 4.734 0 011.572-.264c.88 0 1.564.2 2.052.6.488.392.732.94.732 1.644 0 .456-.116.856-.348 1.2a1.948 1.948 0 01-.948.744c.488.136.868.388 1.14.756.272.36.408.8.408 1.32 0 .752-.272 1.348-.816 1.788-.544.432-1.292.648-2.244.648z" _fill="#1092DC"/>'
  }
})
