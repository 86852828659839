<template>
    <div class="main-page">
        <div class="container">
            <video class="u-bottom-margin--xl" muted="muted" autoplay loop preload src="/video/main-page.mp4" ref="video"></video>

            <div class="row">
                <div class="col-default-4 col-v-tablet-12">
                    <z-card class="u-entire-height" theme="gray">
                        <template v-slot:title>
                            <p class="text-size-xl u-bolder black-90-text">Личный кабинет</p>
                        </template>
                        <template v-slot:content>
                            <p class="u-top-margin--s u-bottom-margin--xs black-50-text">Потребителей</p>
                            <p class="u-top-margin--2xs u-bottom-margin--2xs"><z-link href="https://lk.oao-ntek.ru/login" target="_blank" external>Жилые помещения</z-link></p>
                            <p class="u-top-margin--2xs u-bottom-margin--2xs"><z-link href="https://ul.oao-ntek.ru/login" target="_blank" external>Нежилые помещения</z-link></p>
                            <p class="u-top-margin--s u-bottom-margin--xs black-50-text">Заявителей</p>
                            <p class="u-top-margin--2xs"><z-link href="https://tp.oao-ntek.ru/login" target="_blank" external>Технологическое присоединение</z-link></p>
                        </template>
                    </z-card>
                </div>

                <div class="col-default-4 col-v-tablet-12">
                    <z-card class="u-entire-height"  theme="white">
                        <template v-slot:title>
                            <p class="text-size-xl u-bolder black-90-text">Последние новости</p>
                        </template>
                        <template v-slot:content>
                            <div class="u-top-margin--s u-bottom-margin--m">
                                <ul class="main-page-news" v-if="news.length > 0">
                                    <li v-for="(item, index) in news" :key="index">
                                        <z-link :href="item.detailPageUrl" v-html="item.name"></z-link>
                                        <p class="main-page-news__date">{{ item.activeFrom | momentFilter('DD MMMM YYYY') }}</p>
                                    </li>
                                </ul>
                            </div>
                            <z-link href="/press-center/news/" icon="arrows/round-arrow" dir="right" icon-position="right">Все новости</z-link>
                        </template>
                    </z-card>
                </div>

                <div class="col-default-4 col-v-tablet-12">
                    <z-card class="u-entire-height" size="l" image="/images/main-page/back-card.jpg">
                        <template v-slot:title>
                            <p class="text-size-xl u-bolder black-90-text">История</p>
                        </template>
                        <template v-slot:content>
                            <p class="u-top-margin--s u-bottom-margin--m text-size-m--important">
                                Дарит свет, тепло, обеспечивает водой&nbsp;&mdash; без АО&nbsp;&laquo;НТЭК&raquo; сложно представить жизнь в&nbsp;северном городе. Компания поставляет энергоресурсы не&nbsp;только предприятиям &laquo;Норникеля&raquo;, но&nbsp;и&nbsp;жителям Норильска, Дудинки, Игарки, Светлогорска и&nbsp;Снежногорска. А&nbsp;все начиналось так.
                            </p>
                            <z-link href="/company/about/history" icon="arrows/round-arrow" dir="right" icon-position="right">Подробнее</z-link>
                        </template>
                    </z-card>
                </div>
            </div>

            <div class="main-page-about u-top-margin--xl" alt="О компании.">
                <p class="text-size-2xl u-bolder">О компании</p>

                <div class="row">
                    <div class="col-default-7 col-v-tablet-12">
                        <p>9&nbsp;миллиардов кВТ/час хватит, чтобы более 6&nbsp;миллиардов лампочек горели целые сутки. Эта невероятная цифра&nbsp;&mdash; ежегодная (и&nbsp;даже не&nbsp;пиковая) выработка электроэнергии АО&nbsp;&laquo;Норильско-Таймырская энергетическая компания&raquo;.</p>
                        <p>С&nbsp;2005 года АО&nbsp;&laquo;НТЭК&raquo;&nbsp;&mdash; главный поставщик электрической и&nbsp;тепловой энергии на&nbsp;Крайнем Севере. При выработке используют как возобновляемые источники энергии (гидрогенерация), так и&nbsp;невозобновляемые (природный газ).</p>
                        <p class="u-bottom-margin--m">АО&nbsp;&laquo;НТЭК&raquo; закрывает потребности не&nbsp;только жителей Норильского промрайона, Дудинки, Игарки, Светлогорска и&nbsp;Снежногорска, но&nbsp;и&nbsp;предприятий ПАО &laquo;ГМК &laquo;Норильский никель&raquo;. Именно &laquo;Норникелю&raquo; уходит&nbsp;73% производимой энергии, что полностью покрывает её&nbsp;потребности.</p>
                        <z-button href="/company/about/profile/" tag="a">Подробнее о компании</z-button>
                    </div>
                    <div class="col-default-5 col-v-tablet-12 hide-me--v-tablet">
                        <div class="main-page-about__star">
                            <img src="/images/main-page/star.svg">
                        </div>
                    </div>
                    <div class="col-default-12">
                        <div class="row u-space-between">
                            <div class="col-default-2 col-v-tablet-12">
                                <z-key-indicator
                                    :column="true"
                                    value="5"
                                    text-after="Электростанций"
                                    detail-bordered
                                    size="l"
                                ></z-key-indicator>
                            </div>
                            <div class="col-default-2 col-v-tablet-12">
                                <z-key-indicator
                                    :column="true"
                                    value="13"
                                    text-after="Структурных подразделений"
                                    detail-bordered
                                    size="l"
                                ></z-key-indicator>
                            </div>
                            <div class="col-default-2 col-v-tablet-12">
                                <z-key-indicator
                                    :column="true"
                                    value="25"
                                    text-after="Воздушных линий связи"
                                    detail-bordered
                                    size="l"
                                ></z-key-indicator>
                            </div>
                            <div class="col-default-2 col-v-tablet-12">
                                <z-key-indicator
                                    :column="true"
                                    value="17 лет"
                                    text-after="Бесперебойной качественной работы"
                                    detail-bordered
                                    size="l"
                                ></z-key-indicator>
                            </div>
                            <div class="col-default-3 col-v-tablet-12">
                                <z-key-indicator
                                    :column="true"
                                    value="2 246 Мвт"
                                    text-after="Суммарной мощности"
                                    detail-bordered
                                    size="l"
                                ></z-key-indicator>
                            </div>
                        </div>
                    </div>
                    <div class="col-default-5 col-v-tablet-12 hide-me--default show-me--v-tablet">
                        <div class="main-page-about__star">
                            <img src="/images/main-page/star.svg" alt="Созвездие.">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'main-page',
    data () {
        return {
            news: this.$root.app.components.main.news || []
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
