/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrows/caret': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M20.657 17.103a.75.75 0 001.06-1.06l-9.26-9.26a.75.75 0 00-1.06 0l-9.26 9.26a.75.75 0 001.06 1.06l8.73-8.73 8.73 8.73z" _fill="#81899D"/>'
  }
})
