/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'not-found': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M32.923 10.007c0-2.125 1.21-4.035 3.456-5.46 2.254-1.429 5.49-2.312 9.273-2.312 3.76 0 7.118.994 9.506 2.55 2.398 1.561 3.71 3.602 3.71 5.71 0 2.124-1.21 4.034-3.456 5.458-2.254 1.43-5.49 2.313-9.273 2.313-2.46 0-4.756-.426-6.72-1.161a1 1 0 00-.823.054l-5.393 2.885 1.475-5.263a1 1 0 00-.203-.92c-1.016-1.187-1.552-2.5-1.552-3.854zM45.652.235c-4.068 0-7.698.946-10.344 2.624-2.656 1.683-4.385 4.159-4.385 7.148 0 1.735.628 3.343 1.69 4.731l-1.969 7.024a1 1 0 001.435 1.151l7.069-3.78c2.098.726 4.477 1.133 6.991 1.133 4.068 0 7.697-.946 10.344-2.624 2.656-1.683 4.385-4.159 4.385-7.148 0-3.006-1.87-5.595-4.618-7.385C53.493 1.313 49.743.235 45.652.235zM5.134 24.09a1 1 0 01.998-1.06h8.075a3 3 0 012.294 1.067l2.336 2.773a3 3 0 002.294 1.066h27.353v8.507H13.828a1 1 0 00-.96.72L7.08 56.954 5.134 24.092zm45.35 2.846v9.507h8.474a1 1 0 01.965 1.263l-6.088 22.371a5 5 0 01-4.824 3.687H6.42a1 1 0 01-.998-.94L3.137 24.208a3 3 0 012.995-3.177h8.075a5 5 0 013.824 1.778l2.336 2.772a1 1 0 00.764.355h28.353a1 1 0 011 1zm-1.473 34.828H7.756l6.822-23.32h43.071l-5.743 21.108a3 3 0 01-2.895 2.212zM23.224 46.61a1 1 0 100 2h4.327a1 1 0 000-2h-4.327zm17.95 0a1 1 0 100 2h4.328a1 1 0 100-2h-4.328zm-8.824 9.843c.566-.629 1.204-.964 1.855-1.003.643-.038 1.475.204 2.457 1.08a1 1 0 001.331-1.492c-1.268-1.132-2.6-1.662-3.907-1.584-1.299.076-2.397.744-3.222 1.66a1 1 0 101.486 1.34zm11.791-49.94a1 1 0 10-1.414 1.415L44.72 9.92l-1.993 1.993a1 1 0 001.414 1.414l1.993-1.993 1.993 1.993a1 1 0 001.414-1.414L47.548 9.92l1.993-1.993a1 1 0 10-1.414-1.414l-1.993 1.993-1.993-1.993z" _fill="#0077C8"/>'
  }
})
