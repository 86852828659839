/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'efficiency': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<g clip-path="url(#clip0_3611_15700)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M30.125 24.383v-8.438h-2.813c-.375 0-.656-.187-.843-.468-.188-.282-.094-.657.093-.938L35 2.352c.375-.47 1.219-.47 1.5 0l8.438 12.187c.187.281.187.656.093.938-.187.28-.468.468-.843.468h-2.813V32.82H39.5V15.945h-3.75V14.07h6.656L35.75 4.414l-6.656 9.656h1.968c.563 0 .938.375.938.938v9.375h-1.875zM20.75 45.008V36.57h-2.813c-.375 0-.656-.187-.843-.468-.188-.282-.094-.657.093-.938l8.438-12.187c.375-.47 1.219-.47 1.5 0l8.438 12.187c.187.281.187.656.093.938-.187.28-.468.468-.843.468H32v14.063h-1.875V36.57h-3.75v-1.875h6.656l-6.656-9.656-6.656 9.656h1.968c.563 0 .938.375.938.938v9.375H20.75zM17 57.195h3.75v4.688h1.875v-4.688h2.813c.375 0 .656-.187.844-.468.093-.282.093-.657-.094-.938L17.75 43.602c-.281-.47-1.125-.47-1.5 0L7.813 55.789c-.188.281-.281.656-.094.938.188.28.469.468.844.468h2.812v4.688h1.875v-5.625c0-.563-.375-.938-.937-.938h-1.969L17 45.664l6.657 9.656H17v1.875zm28.125-12.187h3.75v16.875h1.875V45.008h2.813c.375 0 .656-.188.843-.469.094-.281.094-.656-.093-.937l-8.438-12.188c-.281-.469-1.125-.469-1.5 0l-8.437 12.188c-.188.28-.282.656-.094.937.188.281.469.469.844.469H39.5v16.875h1.875V44.07c0-.562-.375-.937-.937-.937h-1.969l6.656-9.656 6.657 9.656h-6.657v1.875zm-15 15H32v1.875h-1.875v-1.875zM32 56.258h-1.875v1.875H32v-1.875zm-1.875-3.75H32v1.875h-1.875v-1.875zM62 25.32H44.187v1.875H62V25.32zm-60 0h17.813v1.875H2V25.32zm53.438-3.75h-7.5v1.875h7.5V21.57zm-11.25 0h1.874v1.875h-1.874V21.57zm-18.75-3.75h-1.875v1.875h1.875V17.82zm-5.625 0h1.875v1.875h-1.875V17.82z" _fill="#0077C8"/></g><defs><clipPath id="clip0_3611_15700"><path pid="1" _fill="#fff" d="M0 0h64v64H0z"/></clipPath></defs>'
  }
})
