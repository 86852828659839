/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'worker': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<g clip-path="url(#clip0_3611_15778)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M44.375 14.188H42.5c0-4.152-2.421-7.71-5.936-9.35A1.882 1.882 0 0035 6.688h-1.875c0-1.017.41-1.942 1.072-2.62a10.466 10.466 0 00-4.02 0 3.737 3.737 0 011.073 2.62h-1.875c0-.926-.68-1.7-1.564-1.85a10.282 10.282 0 00-5.936 9.35H20C20 7.438 25.438 2 32.188 2c6.75 0 12.187 5.438 12.187 12.188zm0 0h1.875c0 3.656-7.219 5.624-14.063 5.624-6.843 0-14.062-1.968-14.062-5.625H20c0 1.5 4.781 3.75 12.188 3.75 7.406 0 12.187-2.25 12.187-3.75zM27.5 33.629c1.299.74 2.851 1.184 4.688 1.184 1.836 0 3.389-.444 4.687-1.184v2.606l-4.781 5.609-4.594-5.39V33.63zm18.469 8.59a48.145 48.145 0 01-2.532-.882V62h-1.874V40.564c-1.633-.73-3.066-1.546-3.905-2.384l-4.908 5.726c0 .188-.281.282-.563.282-.28 0-.562-.094-.75-.282l-4.778-5.575c-.846.818-2.251 1.613-3.846 2.327V62h-1.875V41.43c-.847.323-1.706.62-2.532.883-.406.133-.813.27-1.218.411V62h-1.875V43.419c-4.547 1.798-8.438 4.28-8.438 8.269V62H5V51.687c0-6.093 6.469-9.093 12.844-11.156 3.843-1.312 7.5-3.093 7.781-3.843v-4.483c-2.53-2.484-3.635-6.269-3.75-9.205l-.656-.656c-.188-.188-.282-.375-.282-.657v-1.875h1.875v1.5l.657.657a.851.851 0 01.281.656c0 4.031 2.25 10.313 8.438 10.313 6.187 0 8.437-6.282 8.437-10.313 0-.281.094-.469.281-.656l.657-.657v-1.5h1.874v1.875c0 .282-.093.47-.28.657L42.5 23c-.115 2.936-1.22 6.721-3.75 9.205v4.483c.281.656 3.938 2.53 7.781 3.843 6.375 2.063 12.844 5.063 12.844 11.157V62H57.5V51.687c0-3.988-3.89-6.47-8.438-8.32V62h-1.874V42.65c-.406-.148-.813-.291-1.22-.431zM35 6.688h-1.875v7.5H35v-7.5zm-3.75 0h-1.875v7.5h1.875v-7.5zm-6.563 42.187V47h15v1.875h-15zm0 3.75V54.5h15v-1.875h-15zm0 7.5V58.25h15v1.875h-15z" _fill="#0077C8"/></g><defs><clipPath id="clip0_3611_15778"><path pid="1" _fill="#fff" d="M0 0h64v64H0z"/></clipPath></defs>'
  }
})
