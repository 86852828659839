/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bullet/check/s': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.03 5.03l.53-.53-1.06-1.06-.53.53-6.47 6.47-2.97-2.97L3 6.94 1.94 8l.53.53 3.5 3.5a.75.75 0 001.06 0l7-7z" _fill="#0077C8"/>'
  }
})
