<template>
    <footer class="footer">
        <div class="container">
            <div class="footer__wrapper">
                <div class="row">
                    <div class="col-default-2 col-v-tablet-12">
                        <div class="footer__logo hide-me--v-tablet">
                            <z-logo site-dir="/" />
                        </div>
                        <z-copyright
                            year-current
                            text-after="АО «Норильско-Таймырская энергетическая компания»"
                        ></z-copyright>
                    </div>
                    <div class="col-default-3 col-default-offset-1 hide-me--v-tablet">
                        <span class="u-block black-30-text text-size-xs u-semi-bold u-bottom-margin--2xs">Личный кабинет</span>
                        <lk-info></lk-info>
                    </div>
                    <div class="col-default-6 hide-me--v-tablet">
                        <navigation-bottom :data="footerNavData" columns="2" :additional-data="footerNavAddedData" />
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import NavigationBottom from '@/components/NavigationBottom'

export default {
    name: 'footer-layout',
    components: {
        NavigationBottom
    },
    data () {
        return {
            footerNavData: this.$root.app.components.navigation.top,
            footerNavAddedData: this.$root.app.components.navigation.bottom,
            social: this.$root.app.components.navigation.social,
            accountLinks: [
                {
                    title: 'Жилые помещения',
                    link: '#'
                },
                {
                    title: 'Нежилые помещения',
                    link: '#'
                },
                {
                    title: 'Технологическое присоединение',
                    link: '#'
                }
            ]
        }
    }
}
</script>

<style lang="scss">
.footer {
    background: $token-colors-black-5;

    &__wrapper {
        padding: 48px 0;
    }

    &__logo {
        max-width: 262px;
        margin-bottom: 60px;

        @include breakpoint (tablet) {
            max-width: 198px;
        }
    }
}
</style>
