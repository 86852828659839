/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/big/xlsx': {
    width: 56,
    height: 56,
    viewBox: '0 0 56 56',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.933 4.667H30.6a1 1 0 01.707.293l1.807 1.807h-.011l.472.472L45.1 18.765v-.01l1.807 1.806a1 1 0 01.293.707V46.9a5.6 5.6 0 01-5.6 5.6H14.933a5.6 5.6 0 01-5.6-5.6V10.267a5.6 5.6 0 015.6-5.6zM45.101 20.94H32.875a1.4 1.4 0 01-1.4-1.4V6.767H14.933a3.5 3.5 0 00-3.5 3.5V46.9a3.5 3.5 0 003.5 3.5h26.668a3.5 3.5 0 003.5-3.5V20.941zm-11.526-2.1v-8.633l8.633 8.633h-8.633z" _fill="#1092DC"/><path pid="1" d="M14.45 37.833l2.959-3.949-2.85-3.806h1.64l2.012 2.783 2.014-2.783h1.65l-2.85 3.806 2.949 3.95h-1.65l-2.113-2.905-2.1 2.904h-1.662zm8.083 0v-7.755h1.408v6.556h3.718v1.2h-5.126zm8.469.11a5.95 5.95 0 01-1.694-.23 4.268 4.268 0 01-1.32-.65l.429-1.11c.36.256.752.454 1.177.593.432.14.902.21 1.408.21.58 0 1-.096 1.265-.287.264-.198.396-.45.396-.759a.721.721 0 00-.286-.605c-.184-.146-.503-.267-.957-.363l-1.21-.253c-1.379-.293-2.068-1-2.068-2.123 0-.484.128-.905.385-1.265.256-.36.612-.638 1.067-.836.454-.198.979-.297 1.573-.297.528 0 1.023.08 1.485.242a3.12 3.12 0 011.155.66l-.43 1.056c-.608-.513-1.349-.77-2.221-.77-.506 0-.902.107-1.188.32a.994.994 0 00-.43.835c0 .264.089.477.265.638.176.154.476.275.902.363l1.199.253c.726.154 1.265.396 1.617.726.36.33.539.77.539 1.32 0 .462-.125.87-.374 1.221-.25.352-.605.627-1.067.825-.455.19-.994.286-1.617.286zm3.026-.11l2.959-3.949-2.85-3.806h1.64l2.013 2.783 2.013-2.783h1.65l-2.85 3.806 2.949 3.95h-1.65l-2.112-2.905-2.101 2.904h-1.661z" _fill="#1092DC"/>'
  }
})
