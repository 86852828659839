/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/big/xls': {
    width: 56,
    height: 56,
    viewBox: '0 0 56 56',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.934 4.667H30.6a1 1 0 01.707.293l1.807 1.807h-.01l.471.472 11.526 11.526v-.01l1.808 1.806a1 1 0 01.293.707V46.9a5.6 5.6 0 01-5.6 5.6H14.934a5.6 5.6 0 01-5.6-5.6V10.267a5.6 5.6 0 015.6-5.6zM45.102 20.94H32.874a1.4 1.4 0 01-1.4-1.4V6.767H14.934a3.5 3.5 0 00-3.5 3.5V46.9a3.5 3.5 0 003.5 3.5h26.668a3.5 3.5 0 003.5-3.5V20.941zm-11.527-2.1v-8.633l8.633 8.633h-8.633z" _fill="#1092DC"/><path pid="1" d="M17.07 37.833l3.228-4.308-3.108-4.152h1.788l2.196 3.036 2.196-3.036h1.8l-3.108 4.152 3.216 4.308h-1.8l-2.304-3.168-2.292 3.168H17.07zm8.819 0v-8.46h1.536v7.152h4.056v1.308h-5.592zm9.238.12a6.49 6.49 0 01-1.848-.252c-.568-.176-1.048-.412-1.44-.708l.468-1.212c.392.28.82.496 1.284.648.472.152.984.228 1.536.228.632 0 1.092-.104 1.38-.312.288-.216.432-.492.432-.828 0-.28-.104-.5-.312-.66-.2-.16-.548-.292-1.044-.396l-1.32-.276c-1.504-.32-2.256-1.092-2.256-2.316 0-.528.14-.988.42-1.38.28-.392.668-.696 1.164-.912.496-.216 1.068-.324 1.716-.324a4.88 4.88 0 011.62.264c.504.168.924.408 1.26.72l-.468 1.152c-.664-.56-1.472-.84-2.424-.84-.552 0-.984.116-1.296.348a1.084 1.084 0 00-.468.912.9.9 0 00.288.696c.192.168.52.3.984.396l1.308.276c.792.168 1.38.432 1.764.792.392.36.588.84.588 1.44 0 .504-.136.948-.408 1.332-.272.384-.66.684-1.164.9-.496.208-1.084.312-1.764.312z" _fill="#1092DC"/>'
  }
})
