/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/small/tif': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.666 3.333h11.072a1 1 0 01.708.293l1.207 1.207h-.008l.337.337 8.233 8.233v-.007l1.207 1.207a1 1 0 01.293.707V33.5a4 4 0 01-4 4H10.666a4 4 0 01-4-4V7.333a4 4 0 014-4zm21.55 11.625h-8.734a1 1 0 01-1-1V4.833H10.666a2.5 2.5 0 00-2.5 2.5V33.5a2.5 2.5 0 002.5 2.5h19.05a2.5 2.5 0 002.5-2.5V14.958zm-8.234-1.5V7.292l6.166 6.166h-6.166z" _fill="#1092DC"/><path pid="1" d="M15.35 27.167v-5.98h-2.42v-1.07h6.12v1.07h-2.42v5.98h-1.28zm4.312 0v-7.05h1.28v7.05h-1.28zm2.612 0v-7.05h4.61v1.02h-3.33v1.99h3.12v1.02h-3.12v3.02h-1.28z" _fill="#1092DC"/>'
  }
})
