<template>
    <nav class="z-breadcrumbs">
        <ul class="z-breadcrumbs__list">
            <li
                class="z-breadcrumbs__item"
                v-for="(item, index) in data"
                :class="{'is-current': isCurrent(item.url)}"
                :key="index"
            >
                <template v-if="!isCurrent(item.url)">
                    <z-link
                        theme="dark"
                        class="z-breadcrumbs__link"
                        :href="item.url"
                        :title="item.name"
                        :underlined="false"
                    >
                        <span v-html="item.name"></span>
                    </z-link>
                </template>
                <template v-else>
                    <span class="z-breadcrumbs__link">
                        <span v-html="item.name"></span>
                    </span>
                </template>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: 'z-breadcrumbs',
    props: {
        detail: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            data: this.$root.app.components.breadcrumbs,
            pathname: window.location.pathname
        }
    },
    computed: {
        backLink () {
            return this.$store.getters.backLink
        },
        backText () {
            return this.$store.getters.backText
        }
    },
    methods: {
        isCurrent (link) {
            return this.pathname === link
        }
    }
}

</script>

<style lang="scss" src="./index.scss"></style>
