/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tools/play_small': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M52.786 29.408c1.987 1.157 1.987 4.027 0 5.184L18.82 54.382c-2 1.165-4.51-.278-4.51-2.593V12.211c0-2.315 2.51-3.758 4.51-2.593l33.967 19.79z" _fill="#81899D"/>'
  }
})
