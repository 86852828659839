/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'media/photo': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9 3a.75.75 0 00-.625.334L7.098 5.25H4.5A2.25 2.25 0 002.25 7.5V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V7.5a2.25 2.25 0 00-2.25-2.25h-2.6l-1.276-1.916A.75.75 0 0014.998 3H9zm-.877 3.416L9.401 4.5h5.197l1.277 1.916a.75.75 0 00.625.334h3a.75.75 0 01.75.75V18a.75.75 0 01-.75.75h-15a.75.75 0 01-.75-.75V7.5a.75.75 0 01.75-.75h3a.75.75 0 00.623-.334zm1.252 5.96a2.625 2.625 0 115.25 0 2.625 2.625 0 01-5.25 0zM12 8.25a4.125 4.125 0 100 8.25 4.125 4.125 0 000-8.25z" _fill="#81899D"/>'
  }
})
