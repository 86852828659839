var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.news.list)?_c('div',{staticClass:"news"},[_c('news-filter',{staticClass:"news__filter",attrs:{"data":_vm.news.filter},on:{"change-filter":_vm.changeFilter,"update-filter":_vm.updateFilter}}),_vm._v(" "),(_vm.news.list && _vm.news.list.length)?_c('div',{staticClass:"news__list"},_vm._l((_vm.news.list),function(item,index){return _c('div',{key:index,staticClass:"news__list-item news-item"},[_c('div',{staticClass:"news-item__date"},[_vm._v("\n                "+_vm._s(_vm._f("momentFilter")(item.activeFrom,'DD MMMM YYYY'))+"\n            ")]),_vm._v(" "),_c('z-caption',{staticClass:"news-item__title",attrs:{"weight":"600","href":item.detailPageUrl}},[_c('span',{domProps:{"innerHTML":_vm._s(item.name)}})])],1)}),0):_c('p',[_vm._v("По вашему запросу новостей не найдено")]),_vm._v(" "),(_vm.isLoading)?[_c('z-preloader',{staticClass:"u-bottom-margin--xs"})]:_vm._e(),_vm._v(" "),_c('z-pagination',{attrs:{"show-nav":"","show-more":{
            marginBottom: 'm',
            size: 'm'
        },"show-next":"","show-prev":"","data":{
            currentPage: this.news.nav.current,
            totalItems: this.news.nav.count,
            totalPages: this.news.nav.total,
            pageRange: 1
        }},on:{"change-page":_vm.onPageChange}})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }