/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'contacts/mappin': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.227 4.977A6.75 6.75 0 0118.75 9.75c0 3.105-1.734 5.932-3.566 8.04A23.633 23.633 0 0112 20.812a23.64 23.64 0 01-3.184-3.023C6.984 15.681 5.25 12.854 5.25 9.75a6.75 6.75 0 011.977-4.773zm4.342 17.387L12 21.75l-.43.614a.75.75 0 00.86 0L12 21.75l.43.614h.001l.002-.002.005-.003.02-.014a10.138 10.138 0 00.32-.238c.214-.164.517-.403.88-.711a25.156 25.156 0 002.658-2.623c1.918-2.205 3.934-5.378 3.934-9.023a8.25 8.25 0 10-16.5 0c0 3.645 2.016 6.818 3.934 9.023a25.146 25.146 0 003.538 3.334 14.102 14.102 0 00.32.238l.02.014.005.003.002.002zM9.75 9.75a2.25 2.25 0 114.5 0 2.25 2.25 0 01-4.5 0zM12 6a3.75 3.75 0 100 7.5A3.75 3.75 0 0012 6z" _fill="#81899D"/>'
  }
})
