/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tools/download-2': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.938 2.25a.75.75 0 01.75.75v9.565l2.985-2.985a.75.75 0 111.06 1.06l-4.265 4.265a.75.75 0 01-1.06 0l-4.266-4.264a.75.75 0 011.06-1.06l2.986 2.984V3a.75.75 0 01.75-.75zM3.75 14.375a.75.75 0 00-1.5 0v5.688a1.563 1.563 0 001.563 1.562h16.25a1.563 1.563 0 001.562-1.563v-5.687a.75.75 0 00-1.5 0v5.688a.063.063 0 01-.063.062H3.813a.063.063 0 01-.062-.063v-5.687z" _fill="#81899D"/>'
  }
})
