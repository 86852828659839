/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tools/download-1': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.688 3a.75.75 0 00-1.5 0v9.561L8.03 9.404a.75.75 0 00-1.06 1.06l4.437 4.438a.75.75 0 001.06 0l4.438-4.437a.75.75 0 10-1.06-1.06l-3.158 3.156V3zM3.75 20.19a.75.75 0 000 1.5h17.16a.75.75 0 000-1.5H3.75z" _fill="#81899D"/>'
  }
})
