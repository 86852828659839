/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'contacts/desktop': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M20.25 16.5a.75.75 0 01-.75.75h-15a.75.75 0 01-.75-.75V15h16.5v1.5zm-7.5 2.25h6.75a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25h-15A2.25 2.25 0 002.25 6v10.5a2.25 2.25 0 002.25 2.25h6.75v1.5H9a.75.75 0 000 1.5h6a.75.75 0 000-1.5h-2.25v-1.5zM3.75 6v7.5h16.5V6a.75.75 0 00-.75-.75h-15a.75.75 0 00-.75.75z" _fill="#81899D"/>'
  }
})
