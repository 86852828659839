/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tools/magnifier-minus': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.875 3.75a7.125 7.125 0 100 14.25 7.125 7.125 0 000-14.25zM2.25 10.875a8.625 8.625 0 1115.23 5.546l4.05 4.049a.75.75 0 01-1.06 1.06l-4.05-4.049a8.625 8.625 0 01-14.17-6.606zm4.875 0a.75.75 0 01.75-.75h6a.75.75 0 010 1.5h-6a.75.75 0 01-.75-.75z" _fill="#81899D"/>'
  }
})
