/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tools/presentation': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M19.5 17.25a.75.75 0 00.75-.75V6a.75.75 0 00-.75-.75h-15a.75.75 0 00-.75.75v10.5c0 .414.336.75.75.75h15zm2.25-.75a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6A2.25 2.25 0 014.5 3.75h15A2.25 2.25 0 0121.75 6v10.5zM8.25 21a.75.75 0 01.75-.75h6a.75.75 0 010 1.5H9a.75.75 0 01-.75-.75zm2.666-13.374a.75.75 0 00-1.166.624v6a.75.75 0 001.166.624l4.5-3a.75.75 0 000-1.248l-4.5-3zm2.732 3.624l-2.398 1.599V9.65l2.398 1.599z" _fill="#81899D"/>'
  }
})
