/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bullet/check/m': {
    width: 27,
    height: 27,
    viewBox: '0 0 27 27',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M21.61 9.121l.706-.707L20.902 7l-.707.707-9.618 9.618L6.12 12.87l-.707-.707L4 13.577l.707.707 5.163 5.162a1 1 0 001.414 0L21.609 9.121z" _fill="#0077C8"/>'
  }
})
