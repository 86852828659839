/* eslint-disable */
require('./accordions')
require('./arrows')
require('./bullet')
require('./cabinet')
require('./calendar')
require('./contacts')
require('./cooperation')
require('./corporate')
require('./development')
require('./efficiency')
require('./external')
require('./file')
require('./general')
require('./igarka')
require('./media')
require('./not-found')
require('./people-default')
require('./professionalism')
require('./quote')
require('./reliability')
require('./social')
require('./tools')
require('./worker')
