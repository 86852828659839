<template>
    <div class="z-key-indicator" :class="classObject">
        <div class="z-key-indicator__icon" v-if="!!this.$slots.icon">
            <slot name="icon" />
        </div>
        <div class="z-key-indicator__container">
            <div class="z-key-indicator__header" v-if="caption">
                <vue-raw class="z-key-indicator__caption" :raw="caption" />
            </div>
            <div class="z-key-indicator__body">
                <div class="z-key-indicator__value-wrapper">
                    <span
                        v-if="textBefore"
                        class="z-key-indicator__value-text z-key-indicator__value-text--before"
                        v-html="textBefore"
                    ></span>
                    <span
                        ref="value"
                        class="z-key-indicator__value"
                    >
                        {{ value | numberFilter() }}
                    </span>
                    <span
                        v-if="textAfter"
                        class="z-key-indicator__value-text z-key-indicator__value-text--after"
                        v-html="textAfter"
                    ></span>
                </div>
                <div class="z-key-indicator__detail" v-if="detail || !!this.$slots.detail">
                    <vue-raw :raw="detail" v-if="detail" />
                    <slot name="detail" v-if="!!this.$slots.detail"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import verge from 'verge'
import { CountUp } from 'countup.js'

export default {
    name: 'z-key-indicator',
    data () {
        return {
            lang: this.$root.lang,
            speed: 2,
            viewport: false,
            counter: null,
            isAnimated: false
        }
    },
    props: {
        value: [String, Number],
        textBefore: String,
        textAfter: String,
        valueTextColumn: Boolean,
        valueTextCentered: {
            type: Boolean,
            default: false
        },
        detail: String,
        caption: String,
        detailPosition: {
            type: String,
            default: 'bottom',
            validator: prop => ['bottom', 'right'].includes(prop)
        },
        iconCentered: Boolean,
        detailCentered: Boolean,
        detailBordered: Boolean,
        iconPosition: {
            type: String,
            default: 'top',
            validator: prop => ['top', 'left'].includes(prop)
        },
        size: {
            type: String,
            validator: (prop) => ['l', 'm', 's'].includes(prop),
            default: 'm'
        },
        animation: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        classObject () {
            return [
                `z-key-indicator--size-${this.size}`,
                `z-key-indicator--icon-position-${this.iconPosition}`,
                `z-key-indicator--detail-position-${this.detailPosition}`,
                { 'z-key-indicator--icon-centered': this.iconCentered },
                { 'z-key-indicator--detail-centered': this.detailCentered },
                { 'z-key-indicator--detail-bordered': this.detailBordered },
                { 'z-key-indicator--value-column': this.valueTextColumn },
                { 'z-key-indicator--value-centered': this.valueTextCentered }
            ]
        },
        options () {
            return ({
                duration: this.speed,
                useEasing: true,
                useGrouping: true,
                decimalPlaces: this.getDecimals(),
                separator: this.lang === 'ru' ? '<span class="symbol"> </span>' : '',
                decimal: this.lang === 'ru' ? '<span class="symbol">,</span>' : '<span class="symbol">.</span>',
                prefix: '',
                suffix: ''
            })
        }
    },
    methods: {
        getDecimals () {
            if (!this.value) return 0
            let value = Number.parseFloat(this.value.replace(',', '.')).toString()
            return value.includes('.') ? value.split('.').pop().length : 0
        },
        startAnimation () {
            if (this.inView() && this.counter) {
                this.counter.start()
                setTimeout(() => { this.isAnimated = true }, this.speed * 1000)
                window.removeEventListener('scroll', this.startAnimation)
            }
        },
        inView () {
            return verge.inViewport(this.$el)
        },
        init () {
            if (!(this.animation && !isNaN(Number(this.value)))) return
            let value = Number.parseFloat(this.value.replace(',', '.')).toString()
            this.counter = new CountUp(this.$el.getElementsByClassName('z-key-indicator__value')[0], value, this.options)
        }
    },
    mounted () {
        window.addEventListener('scroll', this.startAnimation)
        this.init()
        this.startAnimation()
    },
    beforeDestroy () {
        window.removeEventListener('scroll', this.startAnimation)
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
