/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'social/vk': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" d="M20.983 27C13.466 27 9.179 21.745 9 13h3.765c.124 6.418 2.9 9.137 5.098 9.698V13h3.546v5.535c2.17-.238 4.452-2.76 5.221-5.535h3.546a10.789 10.789 0 01-1.698 4.003 10.558 10.558 0 01-3.126 2.976 10.895 10.895 0 013.563 2.893A11.153 11.153 0 0131 27h-3.903a6.94 6.94 0 00-2.104-3.377 6.726 6.726 0 00-3.584-1.626V27h-.426z" _fill="#81899D"/>'
  }
})
