/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/small/zip': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.667 3.333h11.072a1 1 0 01.707.293l1.207 1.207h-.008l.337.337 8.233 8.233v-.007l1.207 1.207a1 1 0 01.293.707V33.5a4 4 0 01-4 4H10.667a4 4 0 01-4-4V7.333a4 4 0 014-4zm21.548 11.625h-8.733a1 1 0 01-1-1V4.833H10.667a2.5 2.5 0 00-2.5 2.5V33.5a2.5 2.5 0 002.5 2.5h19.048a2.5 2.5 0 002.5-2.5V14.958zm-8.233-1.5V7.292l6.166 6.166h-6.166z" _fill="#1092DC"/><path pid="1" d="M12.272 28.167v-1.034l4.092-5.544h-4.092v-1.177h5.742v1.034l-4.092 5.544h4.257v1.177h-5.907zm6.77 0v-7.755h1.408v7.755h-1.408zm2.874 0v-7.755h3.366c.85 0 1.507.212 1.97.638.461.425.692 1.02.692 1.782s-.23 1.36-.693 1.793c-.462.425-1.118.638-1.969.638h-1.958v2.904h-1.408zm1.408-4.015h1.738c1.012 0 1.518-.44 1.518-1.32 0-.873-.506-1.31-1.518-1.31h-1.738v2.63z" _fill="#1092DC"/>'
  }
})
