/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'media/image': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M19.951 4.2H4a.8.8 0 00-.8.8v9.906l2.39-1.854a1.356 1.356 0 011.534-.127l3.237 1.88 4.71-4.104c.194-.169.433-.272.681-.307.248-.034.504-.001.735.1l.006.002 4.258 1.913V5a.8.8 0 00-.8-.8zm.8 9.525l-4.746-2.132a.169.169 0 00-.086-.01.122.122 0 00-.06.023h-.001l-5.032 4.386-.324.282-.372-.215-3.608-2.095a.158.158 0 00-.09-.018.127.127 0 00-.07.025l-.012.011-.014.01L3.2 16.424v3.197a.8.8 0 00.8.8h15.951a.8.8 0 00.8-.8v-5.896zM4 3a2 2 0 00-2 2v14.621a2 2 0 002 2h15.951a2 2 0 002-2V5a2 2 0 00-2-2H4zm2.792 4.791a.546.546 0 10-.772-.772.546.546 0 00.772.772zm.848.849a1.746 1.746 0 10-2.468-2.47A1.746 1.746 0 007.64 8.64z" _fill="#81899D"/>'
  }
})
