/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cabinet/user_circle': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12 3.75a8.25 8.25 0 00-6.167 13.73 7.503 7.503 0 013.383-2.694 4.5 4.5 0 115.568 0 7.501 7.501 0 013.383 2.694A8.25 8.25 0 0012 3.75zm6.529 15.491A9.725 9.725 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.725 9.725 0 003.24 7.258A9.714 9.714 0 0012 21.75a9.714 9.714 0 006.529-2.509zm-1.472-.722a6.003 6.003 0 00-10.114 0A8.214 8.214 0 0012 20.25c1.906 0 3.66-.646 5.057-1.731zM12 14.25a3 3 0 100-6 3 3 0 000 6z" _fill="#81899D"/>'
  }
})
