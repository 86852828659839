/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'social/youtube': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.262 27.057a2.568 2.568 0 01-1.043-.602 2.487 2.487 0 01-.642-1.004c-.734-1.964-.952-10.164.462-11.783a2.742 2.742 0 011.849-.902c3.792-.398 15.51-.345 16.855.133.379.12.725.321 1.012.59.287.269.508.598.646.962.802 2.03.83 9.408-.108 11.358a2.538 2.538 0 01-1.183 1.168c-1.413.69-15.972.677-17.848.08zm6.022-3.848l6.797-3.45-6.797-3.477v6.927z" _fill="#81899D"/>'
  }
})
