<template>
    <div class="slider">
        <div class="swiper-container slider__container" ref="slider">
            <div class="swiper-wrapper">
                <slot></slot>
            </div>
        </div>
        <div class="slider__controls">
            <button class="slider__nav-btn slider__nav-btn--prev" ref="prev">
                <z-icon
                    name="arrows/caret"
                />
            </button>
            <button class="slider__nav-btn slider__nav-btn--next" ref="next">
                <z-icon
                    name="arrows/caret"
                />
            </button>
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper/dist/js/swiper.js'

export default {
    name: 'slider',
    data () {
        return {
            slider: null
        }
    },
    props: {
        swiperOptions: {
            type: Object
        },
        effect: {
            type: String,
            default: 'slide'
        }
    },
    methods: {
        init () {
            let options = {
                observer: true,
                loop: true,
                observeParents: true,
                navigation: {
                    prevEl: this.$refs.prev,
                    nextEl: this.$refs.next
                },
                effect: this.effect,
                slidesPerView: 1,
                on: {
                    slideChange () {
                    }
                }
            }

            this.slider = new Swiper(this.$refs.slider, Object.assign({}, this.swiperOptions, options))
        }
    },
    mounted () {
        this.init()
    }
}
</script>

<style lang="scss">
.slider {
    $parent: &;
    position: relative;
    width: 100%;

    @include breakpoint (mobile) {
        width: auto;
    }

    img {
        display: block;
        width: 100%;
    }

    &__container {
        border-radius: 4px;
    }

    &__controls {
        display: flex;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 3;
        width: 100%;
        justify-content: space-between;
        padding: 0px $token-spacers-xs;

        @include breakpoint (v-tablet) {
            padding: 0 $token-spacers-2-xs;
        }
    }

    &__nav-btn {
        border: 1px solid $token-colors-button-bordered-default;
        pointer-events: all;
        background: none;
        padding: 0;
        transition: all $transition;
        cursor: pointer;
        background: $token-colors-white;
        border-radius: 50%;
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include breakpoint(v-tablet) {
            width: 40px;
            height: 40px;
        }

        @include breakpoint(mobile) {
            width: 24px;
            height: 24px;
        }

        .z-icon {
            width: 18px;
            height: 18px;

            path {
                fill: $token-colors-button-bordered-default;
            }

            @include breakpoint(v-tablet) {
                width: 15px;
                height: 15px;
            }

            @include breakpoint(mobile) {
                width: 9px;
                height: 9px;
            }
        }

        &--prev {
            .z-icon {
                margin-left: -2px;
                transform: rotate(-90deg);
            }

            &:hover {
                transform: translateX(-2px);
            }
        }

        &--next {
            .z-icon {
                margin-left: 2px;
                transform: rotate(90deg);
            }

            &:hover {
                transform: translateX(2px);
            }
        }
    }

    .swiper-slide {
        height: auto;
        border-radius: 4px;

        &__content,
        &__wrapper {
            height: 100%;
        }
    }
}
</style>
