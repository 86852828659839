<template>
    <div :class="['history', {'history--workpage': isWorkpage }]">
        <template v-for="(item, index) in data">
            <section class="history-card" :key="index">
                <z-caption class="history-card__title u-collapse--top u-collapse--bottom"
                    size="3xl"
                    weight="600"
                    v-html="item.year"
                ></z-caption>
                <div class="history-card__wrapper">
                    <div :class="['history-card__goals', { 'history-card__goals--blockquote' : item.blockquote}]" v-if="item.goals">
                        <z-list size="m" icon="general/check">
                            <z-list-item
                                v-for="(goal, index) in item.goals"
                                :key="`goal-${index}`"
                            >
                                <span v-html="goal"></span>
                            </z-list-item>
                        </z-list>
                    </div>
                    <z-blockquote :class="[{ 'history-card__blockquote' : item.goals}]" v-if="item.blockquote"
                        :quote="true"
                        :author="{
                            name: item.blockquote.name,
                            info: item.blockquote.info
                        }"
                    >
                        <template v-slot:text>
                            <p v-html="item.blockquote.text"></p>
                        </template>
                    </z-blockquote>
                    <div class="history-card__event" v-for="(event, index) in item.items" :key='index'>
                        <div class="history-card__event--date" v-html="event.date" v-if="event.date"></div>
                        <vue-raw class="history-card__event--content" :raw="event.content"></vue-raw>
                    </div>
                </div>
            </section>
        </template>
    </div>
</template>

<script>
export default {
    name: 'history-list',
    props: {
        data: Array,
        isWorkpage: Boolean
    }
}
</script>

<style lang="scss">
.history-card {
    display: flex;
    flex-direction: column;
    padding: 0 $token-spacers-3-xs 0 $token-spacers-3-xs;
    background-color: $token-colors-white;
    border-radius: 20px;
    position: relative;

    &__wrapper {
        padding: $token-spacers-s 0 $token-spacers-3-xl $token-spacers-l;
        border-left: 2px solid rgba($token-colors-black-30, 0.1);

        @include breakpoint(v-tablet) {
            padding: $token-spacers-xs 0 $token-spacers-xl $token-spacers-xs;
        }

        @include breakpoint(mobile) {
            padding: $token-spacers-2-xs 0 $token-spacers-s $token-spacers-2-xs;
        }
    }

    &__goals {
        padding: $token-spacers-m;
        background: $token-colors-black-5;
        border-radius: 8px;
        margin-bottom: $token-spacers-m;
        margin-left: -$token-spacers-xs;

        ul {
            margin: 0;
        }

        &:only-child {
            margin-bottom: 0;
        }

        &--blockquote {
            border-radius: 8px 8px 0 0;
            padding: $token-spacers-m $token-spacers-m 0;
            margin-bottom: 0;
            margin-left: -$token-spacers-xs;

            + .history-card__blockquote {
                border-radius: 0 0 8px 8px;
                margin-bottom: 0;
                margin-left: -$token-spacers-xs;

                @include breakpoint (v-tablet) {
                    margin-left: 0;
                }
            }
        }

        @include breakpoint(v-tablet) {
            padding: $token-spacers-s;
            margin-left: 0;

            &--blockquote {
                padding: $token-spacers-s $token-spacers-s 0;
                margin-left: 0;
            }
        }

        @include breakpoint(mobile) {
            padding: $token-spacers-xs;

            &--blockquote {
                padding: $token-spacers-xs $token-spacers-xs 0;
            }
        }

        span {
            font-weight: 400;
            @include typo-level(M);
            color: $token-colors-black-90;
        }

        .z-icon {
            margin-right: $token-spacers-2-xs;
        }
    }

    &__goal {
        margin-bottom: $token-spacers-2-xs;
        display: flex;
    }

    &__goal:last-of-type {
        margin-bottom: 0;
    }

    &__blockquote {
        border-radius: 0 0 8px 8px;
        margin-bottom: $token-spacers-m;
    }

    &:before {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        background-color: $token-colors-primary;
        border-radius: 50%;
        position: absolute;
        top: 10px;
        left: 1px;

        @include breakpoint(v-tablet) {
            top: 7px;
        }

        @include breakpoint(mobile) {
            top: 5px;
        }
    }

    &__title {
        padding-left: $token-spacers-l;
        color: $token-colors-primary !important;
        line-height: 1;

        @include breakpoint (v-tablet) {
            @include padding-level (left, M);
        }
    }

    &__event {
        position: relative;
        margin-top: $token-spacers-m;

        & + .history-card__event {
            margin-top: $token-spacers-m;
        }

        &:first-child {
            margin-top: 0;
        }

        &--date {
            font-weight: 600;
            @include typo-level(2XL);
            line-height: 1.15;
            color: $token-colors-black-90;
            margin-bottom: $token-spacers-3-xs;

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 10px;
                left: -62px;
                width: 11px;
                height: 11px;
                background: white;
                border: 2px solid $token-colors-primary;
                border-radius: 50%;
                z-index: 1;

                @include breakpoint(v-tablet) {
                    left: -30px;
                }

                @include breakpoint(mobile) {
                    left: -22px;
                }
            }

            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: -72px;
                width: 30px;
                height: 30px;
                background: white;
                border-radius: 50%;

                @include breakpoint(v-tablet) {
                    left: -40px;
                }

                @include breakpoint(mobile) {
                    left: -33px;
                }
            }
        }

        &--content {
            font-weight: 400;
            @include typo-level(M);
            color: $token-colors-black-90;
        }
    }

    &:last-of-type .history-card__wrapper {
        padding-bottom: 0;
    }
}

.history--workpage {
    .history-card {
        &__wrapper {
            @include padding-level (bottom, M);
        }
    }
}
</style>
