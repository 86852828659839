<template>
    <div class="media-library-content row">
        <template v-for="(item, index) in items">
            <card
                :item="item" :key="`media-card-${index}`"
                @openModal="$root.$bus.$emit('open-modal', {id: 'media-modal', index: index})"
            />
        </template>
        <z-modal id="media-modal" :data="slidesForModal"/>
    </div>
</template>

<script>
import Card from './Card.vue'

export default {
    name: 'media-content',
    components: {
        Card
    },
    props: {
        items: {
            type: Array,
            required: true
        }
    },
    computed: {
        slidesForModal () {
            return this.items.map((item) => ({
                type: item.type.xmlId,
                detail: item.detailText,
                image: {
                    preview: item.previewPicture.link,
                    full: item.type.xmlId === 'video' ? item.video.value : item.detailPicture.link
                }
            })
            )
        }
    }
}
</script>

<style lang="scss">

</style>
