/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'contacts/email': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3 4.5a.75.75 0 00-.75.75V18a1.5 1.5 0 001.5 1.5h16.5a1.5 1.5 0 001.5-1.5V5.25A.75.75 0 0021 4.5H3zM3.75 18V6h16.5v12H3.75z" _fill="#81899D"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M3.507 4.697a.75.75 0 10-1.014 1.106l9 8.25a.75.75 0 001.014 0l9-8.25a.75.75 0 00-1.014-1.106L12 12.483 3.507 4.697z" _fill="#81899D"/>'
  }
})
