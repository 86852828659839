<template>
    <transition
        @before-enter="appearAnimationBefore"
        @enter="appearAnimationEnter"
        @leave="appearAnimationLeave"
    >
        <div
            class="cookie-popup"
            v-if="visible"
        >
            <div class="cookie-popup__body">
                <div class="cookie-popup__text" v-html="text.description"/>
                <div class="cookie-popup__buttons">
                    <div class="cookie-popup__button">
                        <z-button
                            @click="submit"
                        >{{ text.button.agree }}</z-button>
                    </div>
                    <div class="cookie-popup__button">
                        <z-button
                            tag="a"
                            target="_blank"
                            kind="secondary"
                            href="/cookie-policy/"
                        >{{ text.button.more }}</z-button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import Velocity from 'velocity-animate'
import {localize} from '@/utils/i18n'
const cookie = require('cookie_js')

export default {
    name: 'cookie-popup',
    data () {
        return {
            duration: 500,
            visible: false,
            text: {
                description: localize({
                    ru: 'Мы используем Cookies для того, чтобы сделать ваше пребывание на&nbsp;нашем сайте максимально комфортным. Если вы&nbsp;продолжаете просмотр страниц без изменения настроек, это будет означать, что вы&nbsp;принимаете все Cookies сайта &laquo;Корпоративный сайт НТЭК&raquo;. Вы&nbsp;также можете нажать кнопку &laquo;Согласен&raquo;, чтобы скрыть это сообщение. Детали о&nbsp;Cookies доступны по&nbsp;ссылке подробнее',
                    en: 'We are uses cookies for the convenience of&nbsp;using the website. Cookies are small files containing information about previous visits to&nbsp;the website. By&nbsp;continuing to&nbsp;use our site, you consent to&nbsp;their processing.'
                }),
                button: {
                    agree: localize({
                        ru: 'Согласен',
                        en: 'I agree'
                    }),
                    more: localize({
                        ru: 'Подробнее',
                        en: 'More'
                    })
                }
            }
        }
    },
    mounted () {
        this.visible = !cookie.get('cookies_policy')
    },
    methods: {
        submit () {
            cookie.set({
                cookies_policy: true
            }, {
                domain: '.' + location.host,
                path: '/',
                expires: 365
            })

            this.$nextTick(() => { this.visible = false })
        },
        appearAnimationBefore (el) {
            el.style.opacity = 0
        },
        appearAnimationEnter (el, done) {
            Velocity(el, { opacity: 1 }, { duration: this.duration, delay: this.duration }, { complete: done })
        },
        appearAnimationLeave (el, done) {
            Velocity(el, { opacity: 0 }, { duration: this.duration })
            Velocity(el, { display: 'none' }, { complete: done })
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
