/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cabinet/sign_out': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.5 3A1.5 1.5 0 003 4.5v15A1.5 1.5 0 004.5 21h5.25a.75.75 0 000-1.5H4.5v-15h5.25a.75.75 0 000-1.5H4.5zm11.283 4.532a.75.75 0 011.061 0l3.936 3.938a.75.75 0 010 1.06l-3.936 3.938a.75.75 0 01-1.06-1.06l2.656-2.658H9.75a.75.75 0 010-1.5h8.69l-2.657-2.657a.75.75 0 010-1.06z" _fill="#81899D"/>'
  }
})
