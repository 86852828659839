/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tools/play-circle': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12 3.75a8.25 8.25 0 100 16.5 8.25 8.25 0 000-16.5zM2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm7.896-3.661a.75.75 0 01.77.037l4.5 3a.75.75 0 010 1.248l-4.5 3A.75.75 0 019.75 15V9a.75.75 0 01.396-.661zm1.104 5.26L13.648 12l-2.398-1.599V13.6z" _fill="#81899D"/>'
  }
})
