/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/small/docx': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.667 3.333h11.072a1 1 0 01.707.293l1.207 1.207h-.008l.337.337 8.233 8.233v-.007l1.207 1.207a1 1 0 01.293.707V33.5a4 4 0 01-4 4H10.667a4 4 0 01-4-4V7.333a4 4 0 014-4zm21.548 11.625h-8.733a1 1 0 01-1-1V4.833H10.667a2.5 2.5 0 00-2.5 2.5V33.5a2.5 2.5 0 002.5 2.5h19.048a2.5 2.5 0 002.5-2.5V14.958zm-8.233-1.5V7.292l6.166 6.166h-6.166z" _fill="#1092DC"/><path pid="1" d="M9.992 26.167v-5.288h1.965c.885 0 1.567.228 2.047.683.486.455.728 1.107.728 1.957 0 .845-.242 1.498-.727 1.958-.48.46-1.163.69-2.048.69H9.992zm.96-.795h.945c1.22 0 1.83-.618 1.83-1.853 0-1.23-.61-1.845-1.83-1.845h-.945v3.698zm6.973.87c-.51 0-.958-.113-1.343-.338a2.307 2.307 0 01-.885-.945c-.21-.41-.315-.89-.315-1.44 0-.555.105-1.035.315-1.44.21-.405.505-.717.885-.937.38-.225.828-.338 1.343-.338s.962.113 1.342.338c.38.22.675.532.885.937.21.405.315.883.315 1.433 0 .555-.105 1.037-.315 1.447-.21.405-.505.72-.885.945-.38.225-.827.338-1.342.338zm0-.825c.485 0 .862-.168 1.132-.503.27-.335.405-.8.405-1.395 0-.6-.135-1.065-.405-1.395-.265-.33-.642-.495-1.132-.495-.485 0-.863.165-1.133.495-.27.33-.405.795-.405 1.395 0 .595.135 1.06.405 1.395.27.335.648.503 1.133.503zm5.828.825c-.55 0-1.022-.11-1.417-.33-.39-.225-.69-.54-.9-.945-.21-.41-.315-.893-.315-1.448 0-.555.105-1.035.315-1.44.21-.405.51-.717.9-.937.395-.225.867-.338 1.418-.338.354 0 .69.055 1.005.165.32.11.582.268.787.473l-.315.765a2.275 2.275 0 00-.69-.413 2.1 2.1 0 00-.758-.135c-.535 0-.942.163-1.222.488-.28.32-.42.777-.42 1.372 0 .595.14 1.055.42 1.38.28.325.688.488 1.223.488.27 0 .522-.043.757-.128.235-.09.465-.23.69-.42l.315.765a2.15 2.15 0 01-.787.473c-.316.11-.65.165-1.006.165zm1.748-.075l2.018-2.693-1.942-2.595h1.117l1.373 1.898 1.372-1.898h1.125l-1.942 2.595 2.01 2.693h-1.126l-1.44-1.98-1.432 1.98h-1.133z" _fill="#1092DC"/>'
  }
})
