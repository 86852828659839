/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/small/rar': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.666 3.333h11.072a1 1 0 01.708.293l1.207 1.207h-.008l.337.337 8.233 8.233v-.007l1.207 1.207a1 1 0 01.293.707V33.5a4 4 0 01-4 4H10.666a4 4 0 01-4-4V7.333a4 4 0 014-4zm21.55 11.625h-8.734a1 1 0 01-1-1V4.833H10.666a2.5 2.5 0 00-2.5 2.5V33.5a2.5 2.5 0 002.5 2.5h19.05a2.5 2.5 0 002.5-2.5V14.958zm-8.234-1.5V7.292l6.166 6.166h-6.166z" _fill="#1092DC"/><path pid="1" d="M10.457 27.167v-7.05h3.06c.774 0 1.37.186 1.79.56.42.366.63.883.63 1.55 0 .526-.136.963-.41 1.31-.273.34-.666.57-1.18.69.34.106.62.363.84.77l1.18 2.17h-1.41l-1.22-2.25c-.12-.22-.263-.37-.43-.45-.16-.08-.366-.12-.62-.12h-.95v2.82h-1.28zm1.28-3.77h1.56c.934 0 1.4-.38 1.4-1.14 0-.754-.466-1.13-1.4-1.13h-1.56v2.27zm4.489 3.77l3.19-7.05h1.05l3.19 7.05h-1.3l-.7-1.63h-3.44l-.69 1.63h-1.3zm3.7-5.66l-1.27 3.01h2.56l-1.27-3.01h-.02zm4.301 5.66v-7.05h3.06c.774 0 1.37.186 1.79.56.42.366.63.883.63 1.55 0 .526-.136.963-.41 1.31-.273.34-.666.57-1.18.69.34.106.62.363.84.77l1.18 2.17h-1.41l-1.22-2.25c-.12-.22-.263-.37-.43-.45-.16-.08-.366-.12-.62-.12h-.95v2.82h-1.28zm1.28-3.77h1.56c.934 0 1.4-.38 1.4-1.14 0-.754-.466-1.13-1.4-1.13h-1.56v2.27z" _fill="#1092DC"/>'
  }
})
