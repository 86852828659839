<template>
    <component
        class="card"
        :class="`card--theme-${theme}`"
        :is="link ? 'a' : 'span'"
        :href="link"
    >
        <div class="card__image-wrapper" v-if="img">
            <img
                class="card__image"
                :src="img"
                :alt="detail"
            />
        </div>
        <div class="card__info">
            <div class="card__log" v-if="theme === 'log'">
                <z-icon name="print" dir="bottom" width="25" height="25"/>
            </div>
            <span class="card__top" v-if="tag || title">
                <span class="card__tags" v-if="tag">
                    <span
                        class="card__tag"
                        v-if="tag"
                        v-html="tag"
                    ></span>
                </span>
                <component
                    class="card__title"
                    :is="title && links.title && !link ? 'a' : 'span'"
                    :href="links.title"
                    v-if="title"
                    v-html="title"
                ></component>
            </span>
            <component
                class="card__detail"
                :is="detail && links.detail && !link ? 'a' : 'span'"
                :href="links.detail"
                v-html="detail"
            ></component>
            <div class="card__bottom">
                <div class="card__date-block" v-if="date">
                    <z-icon
                        v-if="theme === 'rubric'"
                        name="date"
                        dir="bottom"
                        width="25"
                        height="25"
                        color="#e7000f"
                    />
                    <span
                        class="card__date"
                        v-html="date"
                    ></span>
                </div>
                <div class="card__icon-block" v-if="theme !== 'news'">
                    <z-icon
                        v-if="theme === 'rubric'"
                        :color="iconColor"
                        :name="icon"
                        dir="bottom"
                        width="64"
                        height="56"
                    />
                </div>
            </div>
        </div>
    </component>
</template>

<script>
export default {
    name: 'card',
    props: {
        link: String,
        detail: String,
        theme: {
            type: String,
            default: 'news',
            validator: prop => ['news', 'rubric'].includes(prop)
        },
        tag: {
            type: String,
            default: ''
        },
        date: {
            type: String,
            default: ''
        },
        links: {
            type: Object,
            default: () => { return {} }
        },
        title: {
            type: String,
            default: ''
        },
        img: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: 'calendar'
        },
        iconColor: {
            type: String,
            default: '#C4C7CC'
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
