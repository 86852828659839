<template>
    <div class="page-404">
        <img class="page-404__svg" src="/images/page404/404.svg" alt="Картинка страницы 404."/>
        <z-caption tag="h2" size="l" weight="600" class="page-404__title">К&nbsp;сожалению, эта страница не&nbsp;найдена</z-caption>
        <p class="text-size-m">
            Возможно, вы&nbsp;ввели неправильный адрес или страница была удалена.<br />
            Воспользуйтесь <z-link href="/search/">поиском</z-link> или вернитесь на&nbsp;<z-link href="/">главную страницу</z-link>.
        </p>
    </div>
</template>

<script>
export default {
    name: 'page-404'
}
</script>

<style lang="scss">
.page-404 {
    @include padding-level(top, 4XL);
    @include padding-level(bottom, M);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    &__svg {
        @include margin-level(bottom, M);
    }

    &__title {
        margin-bottom: $token-spacers-s !important;
    }
}
</style>
