<template>
    <article class="detail-news detail-page-container">
        <div class="detail-news__date">{{ data.item.activeFrom | momentFilter('DD MMMM YYYY') }}</div>
        <div
            class="detail-news__detail-image"
            :style="`background-image: url(${data.item.detailPicture.link})`"
            v-if="data.item.detailPicture.link"
        />
        <vue-raw class="detail-news__content" :raw="data.item.detailText" />
        <z-link
            class="detail-news__back-link"
            :href="data.nav['back-link']"
            icon="arrows/caret"
        >Вернуться к&nbsp;разделу</z-link>
    </article>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'detail-news',
    data () {
        return {
            data: this.$root.app.components['news-detail']
        }
    },
    created () {
        this.SetHeroTitle(this.data.item.name)
    },
    methods: {
        ...mapActions(['SetHeroTitle'])
    }
}
</script>

<style lang="scss">
.detail-news {
    &__date {
        color: $token-colors-black-40;
        @include margin-level (bottom, S);
        @include typo-level (M);
    }

    &__back-link {
        .z-icon {
            transform: rotate(-90deg);
            margin-right: $token-spacers-3-xs !important;
        }
    }

    &__content {
        @include margin-level (bottom, M);
    }

    &__detail-image {
        padding-bottom: 56%;
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        @include margin-level (bottom, S);
        border-radius: 4px;
    }
}
</style>
