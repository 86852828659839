/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'accordions/plus': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.437 2.25c.19.136.313.358.313.61v8.39h8.39c.252 0 .474.123.61.313v.874a.749.749 0 01-.61.313h-8.39v8.39a.749.749 0 01-.313.61h-.874a.749.749 0 01-.313-.61v-8.39H2.86a.749.749 0 01-.61-.313v-.874a.749.749 0 01.61-.313h8.39V2.86c0-.252.123-.474.313-.61h.874z" _fill="#81899D"/>'
  }
})
