/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/small/jpg': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.666 3.333h11.072a1 1 0 01.708.293l1.207 1.207h-.008l.337.337 8.233 8.233v-.007l1.207 1.207a1 1 0 01.293.707V33.5a4 4 0 01-4 4H10.666a4 4 0 01-4-4V7.333a4 4 0 014-4zm21.55 11.625h-8.734a1 1 0 01-1-1V4.833H10.666a2.5 2.5 0 00-2.5 2.5V33.5a2.5 2.5 0 002.5 2.5h19.05a2.5 2.5 0 002.5-2.5V14.958zm-8.234-1.5V7.292l6.166 6.166h-6.166z" _fill="#1092DC"/><path pid="1" d="M11.494 27.257l-.09-1.05.71-.04c.593-.04.89-.367.89-.98v-5.07h1.28v5.06c0 1.266-.657 1.946-1.97 2.04l-.82.04zm4.134-.09v-7.05h3.06c.774 0 1.37.193 1.79.58.42.386.63.926.63 1.62 0 .693-.21 1.236-.63 1.63-.42.386-1.016.58-1.79.58h-1.78v2.64h-1.28zm1.28-3.65h1.58c.92 0 1.38-.4 1.38-1.2 0-.794-.46-1.19-1.38-1.19h-1.58v2.39zm8.385 3.75c-.78 0-1.44-.147-1.98-.44a2.942 2.942 0 01-1.22-1.25c-.28-.54-.42-1.18-.42-1.92 0-.747.143-1.39.43-1.93.287-.547.7-.967 1.24-1.26.54-.3 1.187-.45 1.94-.45.493 0 .957.073 1.39.22a3 3 0 011.08.59l-.43 1a3.128 3.128 0 00-.96-.53 3.466 3.466 0 00-1.08-.16c-.747 0-1.313.216-1.7.65-.38.433-.57 1.056-.57 1.87 0 .82.197 1.443.59 1.87.393.426.977.64 1.75.64.22 0 .443-.017.67-.05.233-.04.463-.097.69-.17v-1.64h-1.62v-.95h2.69v3.39c-.327.16-.713.286-1.16.38-.447.093-.89.14-1.33.14z" _fill="#1092DC"/>'
  }
})
