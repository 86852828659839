/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bullet/big-dot': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8 12a4 4 0 100-8 4 4 0 000 8z" _fill="#0077C8"/>'
  }
})
