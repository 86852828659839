/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'contacts/user': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.75 9A5.25 5.25 0 1112 14.25 5.25 5.25 0 016.75 9zm8.712 5.796a6.75 6.75 0 10-6.924 0 11.262 11.262 0 00-6.283 5.078.75.75 0 101.3.75A9.753 9.753 0 0112 15.75a9.753 9.753 0 018.445 4.875.75.75 0 001.299-.75 11.253 11.253 0 00-6.282-5.08z" _fill="#81899D"/>'
  }
})
