<template>
    <div class="history">
        <p class="text-size-l u-bold">
            АО &laquo;НТЭК&raquo; было создано в&nbsp;качестве операционной компании, которой переданы в&nbsp;аренду энергетические активы ОАО&nbsp;&laquo;ГМК &laquo;Норильский никель&raquo; и&nbsp;ОАО&nbsp;&laquo;Таймырэнерго&raquo;.
        </p>
        <p class="text-size-m">
            На&nbsp;основании производственного сравнительного анализа различных показателей ОАО&nbsp;&laquo;Таймырэнерго&raquo; и&nbsp;ПО&nbsp;&laquo;Норильскэнерго&raquo; доля РАО&nbsp;&laquo;ЕЭС России&raquo; в&nbsp;уставном капитале АО&nbsp;&laquo;НТЭК&raquo; составила 49%, ОАО&nbsp;&laquo;ГМК &laquo;Норильский никель&raquo;&nbsp;&mdash; 51%
        </p>

        <div class="row">
            <div class="col-default-6 col-mobile-12">
                <z-key-indicator
                    :column="true"
                    value="49%"
                    detail="Доля РАО «ЕЭС России»"
                    size="l"
                ></z-key-indicator>
            </div>
            <div class="col-default-6 col-mobile-12">
                <z-key-indicator
                    :column="true"
                    value="51%"
                    detail="Доля ОАО «ГМК «Норильский никель»"
                    size="l"
                ></z-key-indicator>
            </div>
        </div>

        <z-caption class="history-title" size="xl" weight="700">
            Развитие АО «НТЭК»
        </z-caption>

        <div class="history-filter">
            <span class="history-filter__text u-semi-bold">Сортировка:</span>
            <button class="history-filter__button"
                type="button"
                :disabled="isDisabled(filters.newToOld)"
                @click="filterHandler(filters.newToOld)"
            >
                От новых к&nbsp;старым
            </button>
            <button class="history-filter__button"
                type="button"
                :disabled="isDisabled(filters.oldToNew)"
                @click="filterHandler(filters.oldToNew)"
            >
                От старых к&nbsp;новым
            </button>
        </div>

        <history-list :data="sortedData"></history-list>
    </div>
</template>

<script>
import {historyData} from './data'

export default {
    name: 'history-page',
    data () {
        return {
            data: historyData,
            filters: {
                newToOld: 'new',
                oldToNew: 'old'
            },
            currentFilter: 'new'
        }
    },

    computed: {
        sortedData () {
            switch (this.currentFilter) {
            case (this.filters.newToOld): {
                return this.data.slice().sort((a, b) => Number(b.year) - Number(a.year))
            }
            case (this.filters.oldToNew): {
                return this.data.slice().sort((a, b) => Number(a.year) - Number(b.year))
            }
            }
        }
    },

    methods: {
        isDisabled (filter) {
            return filter === this.currentFilter
        },

        filterHandler (filter) {
            this.currentFilter = filter
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
