<template>
    <div class="heat-calculator">
        <z-caption tag="h2" size="2xl">Расчет стоимости за&nbsp;подключение объекта к&nbsp;системе теплоснабжения</z-caption>
        <z-input class="u-bottom-margin--s" type="number" title="Величина подключаемой тепловой нагрузки:" name="heat" v-model="value" placeholder="Гкал/час"/>
        <z-caption tag="h3" size="xl">Результат: {{result | numberFilter}} руб.</z-caption>
    </div>
</template>

<script>

export default {
    name: 'calculator-heat-supply',
    data () {
        return {
            value: '',
            tax: 1367077
        }
    },
    computed: {
        result () {
            return this.value * this.tax
        }
    }
}
</script>

<style lang="scss">
.heat-calculator {
    &__input-wrapper {
        display: flex;
    }
}
</style>
