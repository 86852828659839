/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'contacts/at': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 15.75a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z" _stroke="#81899D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M16.977 19.5A9 9 0 1121 12c0 2.07-.75 3.75-2.625 3.75S15.75 14.07 15.75 12V8.25" _stroke="#81899D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
