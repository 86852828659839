/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/small/doc': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.666 3.333h11.072a1 1 0 01.708.293l1.207 1.207h-.008l.337.337 8.233 8.233v-.007l1.207 1.207a1 1 0 01.293.707V33.5a4 4 0 01-4 4H10.666a4 4 0 01-4-4V7.333a4 4 0 014-4zm21.55 11.625h-8.734a1 1 0 01-1-1V4.833H10.666a2.5 2.5 0 00-2.5 2.5V33.5a2.5 2.5 0 002.5 2.5h19.05a2.5 2.5 0 002.5-2.5V14.958zm-8.234-1.5V7.292l6.166 6.166h-6.166z" _fill="#1092DC"/><path pid="1" d="M9.842 27.167v-7.05h2.62c1.18 0 2.09.303 2.73.91.647.606.97 1.476.97 2.61 0 1.126-.323 1.996-.97 2.61-.64.613-1.55.92-2.73.92h-2.62zm1.28-1.06h1.26c1.627 0 2.44-.824 2.44-2.47 0-1.64-.813-2.46-2.44-2.46h-1.26v4.93zm9.297 1.16c-.68 0-1.276-.15-1.79-.45-.506-.3-.9-.72-1.18-1.26-.28-.547-.42-1.187-.42-1.92 0-.74.14-1.38.42-1.92a3.01 3.01 0 011.18-1.25c.507-.3 1.104-.45 1.79-.45.687 0 1.284.15 1.79.45.507.293.9.71 1.18 1.25.28.54.42 1.176.42 1.91 0 .74-.14 1.383-.42 1.93-.28.54-.673.96-1.18 1.26-.506.3-1.103.45-1.79.45zm0-1.1c.647 0 1.15-.224 1.51-.67.36-.447.54-1.067.54-1.86 0-.8-.18-1.42-.54-1.86-.353-.44-.856-.66-1.51-.66-.646 0-1.15.22-1.51.66-.36.44-.54 1.06-.54 1.86 0 .793.18 1.413.54 1.86.36.446.864.67 1.51.67zm7.772 1.1c-.733 0-1.363-.147-1.89-.44-.52-.3-.92-.72-1.2-1.26-.28-.547-.42-1.19-.42-1.93s.14-1.38.42-1.92c.28-.54.68-.957 1.2-1.25.527-.3 1.157-.45 1.89-.45.473 0 .92.073 1.34.22.427.146.777.356 1.05.63l-.42 1.02a3.035 3.035 0 00-.92-.55 2.8 2.8 0 00-1.01-.18c-.714 0-1.257.216-1.63.65-.373.426-.56 1.036-.56 1.83 0 .793.186 1.406.56 1.84.373.433.916.65 1.63.65.36 0 .696-.057 1.01-.17.313-.12.62-.307.92-.56l.42 1.02a2.865 2.865 0 01-1.05.63c-.42.146-.867.22-1.34.22z" _fill="#1092DC"/>'
  }
})
