/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tools/gear': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.498 3.793L9.651 2.408a.783.783 0 00-.703-.122c-.572.179-1.127.409-1.658.686a.783.783 0 00-.412.584l-.327 2.286a8.389 8.389 0 00-.71.71l-2.285.326a.783.783 0 00-.583.411 10.116 10.116 0 00-.687 1.658.783.783 0 00.121.704l1.386 1.847a8.386 8.386 0 000 1.004l-1.385 1.847a.783.783 0 00-.122.703c.179.572.409 1.127.686 1.658a.783.783 0 00.584.412l2.286.326a8.35 8.35 0 00.71.71l.326 2.286a.783.783 0 00.411.583 10.12 10.12 0 001.658.688.784.784 0 00.704-.122l1.847-1.386a8.428 8.428 0 001.004 0l1.847 1.385a.783.783 0 00.703.122c.572-.179 1.127-.409 1.658-.686a.784.784 0 00.412-.584l.326-2.285a8.315 8.315 0 00.71-.71l2.286-.327a.782.782 0 00.583-.411c.278-.531.508-1.086.688-1.658a.784.784 0 00-.122-.704l-1.386-1.847a8.428 8.428 0 000-1.004l1.385-1.847a.783.783 0 00.122-.703 10.113 10.113 0 00-.686-1.658.784.784 0 00-.584-.412l-2.285-.327a8.354 8.354 0 00-.71-.71l-.327-2.285a.782.782 0 00-.411-.583 10.118 10.118 0 00-1.658-.687.783.783 0 00-.704.121l-1.847 1.386a8.386 8.386 0 00-1.004 0z" _stroke="#81899D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M12 16.699A4.699 4.699 0 1012 7.3a4.699 4.699 0 000 9.398z" _stroke="#81899D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
