/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tools/external-2': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0_2527_9095)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.25 3c0 .414.336.75.75.75h4.19l-9.72 9.72a.75.75 0 101.06 1.06l9.72-9.72V9a.75.75 0 001.5 0V3a.75.75 0 00-.75-.75h-6a.75.75 0 00-.75.75zM4.116 7.116A1.25 1.25 0 015 6.75h6a.75.75 0 000-1.5H5A2.75 2.75 0 002.25 8v11A2.75 2.75 0 005 21.75h11A2.75 2.75 0 0018.75 19v-6a.75.75 0 00-1.5 0v6A1.25 1.25 0 0116 20.25H5A1.25 1.25 0 013.75 19V8c0-.332.132-.65.366-.884z" _fill="#81899D"/></g><defs><clipPath id="clip0_2527_9095"><path pid="1" _fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs>'
  }
})
