/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tools/pause_small': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.905 11h8.571c1.052 0 1.905.814 1.905 1.818v36.364c0 1.004-.853 1.818-1.905 1.818h-8.571C13.853 51 13 50.186 13 49.182V12.818c0-1.004.853-1.818 1.905-1.818zm23.81 0h8.57c1.053 0 1.906.814 1.906 1.818v36.364c0 1.004-.853 1.818-1.905 1.818h-8.572c-1.052 0-1.904-.814-1.904-1.818V12.818c0-1.004.852-1.818 1.904-1.818z" _fill="#81899D"/>'
  }
})
