/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'media/documents': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.75 3.013A2.238 2.238 0 007.513 5.25V6H3.75a1.5 1.5 0 00-1.5 1.5v12a1.5 1.5 0 001.5 1.5h16.5a1.5 1.5 0 001.5-1.5v-12a1.5 1.5 0 00-1.5-1.5h-3.763v-.75a2.238 2.238 0 00-2.238-2.237h-4.5zM15.013 6v-.75a.762.762 0 00-.763-.762h-4.5a.763.763 0 00-.762.762V6h6.025zM3.75 7.5h16.5v3.917a17.18 17.18 0 01-8.25 2.095H12a17.18 17.18 0 01-8.248-2.094V7.5zm8.25 7.488a18.656 18.656 0 008.25-1.91V19.5h-16.5v-6.422a18.655 18.655 0 008.25 1.91zm-1.126-4.476a.738.738 0 000 1.476h2.25a.737.737 0 100-1.476h-2.25z" _fill="#81899D"/>'
  }
})
