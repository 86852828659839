/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/small/mp3': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.666 3.333h11.072a1 1 0 01.708.293l1.207 1.207h-.008l.337.337 8.233 8.233v-.007l1.207 1.207a1 1 0 01.293.707V33.5a4 4 0 01-4 4H10.666a4 4 0 01-4-4V7.333a4 4 0 014-4zm21.55 11.625h-8.734a1 1 0 01-1-1V4.833H10.666a2.5 2.5 0 00-2.5 2.5V33.5a2.5 2.5 0 002.5 2.5h19.05a2.5 2.5 0 002.5-2.5V14.958zm-8.234-1.5V7.292l6.166 6.166h-6.166z" _fill="#1092DC"/><path pid="1" d="M10.39 27.167v-7.05h1.07l2.5 4.61 2.49-4.61h1.05v7.05h-1.16v-4.75l-2.02 3.68h-.75l-2.02-3.66v4.73h-1.16zm8.461 0v-7.05h3.06c.773 0 1.37.193 1.79.58.42.386.63.926.63 1.62 0 .693-.21 1.236-.63 1.63-.42.386-1.017.58-1.79.58h-1.78v2.64h-1.28zm1.28-3.65h1.58c.92 0 1.38-.4 1.38-1.2 0-.794-.46-1.19-1.38-1.19h-1.58v2.39zm7.134 3.75c-.48 0-.94-.07-1.38-.21a3.215 3.215 0 01-1.08-.59l.42-.98c.6.466 1.266.7 2 .7.9 0 1.35-.354 1.35-1.06 0-.334-.114-.58-.34-.74-.22-.167-.544-.25-.97-.25h-1.13v-1.06h1.01c.373 0 .67-.09.89-.27.22-.187.33-.444.33-.77 0-.307-.104-.54-.31-.7-.2-.16-.494-.24-.88-.24-.694 0-1.304.24-1.83.72l-.42-.96a2.87 2.87 0 011.05-.62c.42-.147.856-.22 1.31-.22.733 0 1.303.166 1.71.5.406.326.61.783.61 1.37 0 .38-.097.713-.29 1a1.623 1.623 0 01-.79.62c.406.113.723.323.95.63.226.3.34.666.34 1.1 0 .626-.227 1.123-.68 1.49-.454.36-1.077.54-1.87.54z" _fill="#1092DC"/>'
  }
})
