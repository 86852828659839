<template>
    <div class="site-map">
        <ul class="site-map__list">
            <li
                class="site-map__item"
                v-for="(item, index) in data"
                :key="index"
            >
                <z-link
                    :size="isMobile() ? 'm' : 'xl'"
                    :href="item.link"
                ><span v-html="item.name"></span></z-link>
                <ul class="site-map__sublist" v-if="sublist(item)">
                    <li
                        class="site-map__subitem"
                        v-for="(item, index) in item.items"
                        :key="index"
                    >
                        <z-link
                            :size="isMobile() ? 'm' : 'l'"
                            :href="item.link"
                            weight="600"
                        ><span v-html="item.name"></span></z-link>

                        <ul class="site-map__sublist" v-if="item.items.length">
                            <li
                                class="site-map__subitem"
                                v-for="(item, index) in item.items"
                                :key="index"
                            >
                                <z-link
                                    :size="isMobile() ? 's' : 'm'"
                                    :href="item.link"
                                ><span v-html="item.name"></span></z-link>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
import { mixinDevice } from '@/utils/mixin'

export default {
    name: 'site-map',
    mixins: [mixinDevice],
    data () {
        return {
            data: this.$root.app.components.sitemap
        }
    },
    methods: {
        sublist (item) {
            return item.hasOwnProperty('items') ? item.items.length > 0 : false
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
