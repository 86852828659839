/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'external/m': {
    width: 27,
    height: 27,
    viewBox: '0 0 27 27',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M19.093 5.376a.75.75 0 10-1.06 1.06l3.781 3.783H13.5A10.875 10.875 0 002.625 21.094a.75.75 0 001.5 0 9.375 9.375 0 019.375-9.375h8.314L18.032 15.5a.75.75 0 001.06 1.06l5.063-5.062a.748.748 0 000-1.06l-5.062-5.063z" _fill="#0077C8"/>'
  }
})
