/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/small/txt': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.666 3.333h11.072a1 1 0 01.708.293l1.207 1.207h-.008l.337.337 8.233 8.233v-.007l1.207 1.207a1 1 0 01.293.707V33.5a4 4 0 01-4 4H10.666a4 4 0 01-4-4V7.333a4 4 0 014-4zm21.55 11.625h-8.734a1 1 0 01-1-1V4.833H10.666a2.5 2.5 0 00-2.5 2.5V33.5a2.5 2.5 0 002.5 2.5h19.05a2.5 2.5 0 002.5-2.5V14.958zm-8.234-1.5V7.292l6.166 6.166h-6.166z" _fill="#1092DC"/><path pid="1" d="M13.1 27.167v-5.98h-2.42v-1.07h6.12v1.07h-2.42v5.98H13.1zm3.48 0l2.69-3.59-2.59-3.46h1.49l1.83 2.53 1.83-2.53h1.5l-2.59 3.46 2.68 3.59h-1.5L20 24.527l-1.91 2.64h-1.51zm9.05 0v-5.98h-2.42v-1.07h6.12v1.07h-2.42v5.98h-1.28z" _fill="#1092DC"/>'
  }
})
