/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar/yahoo-icon': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12 21.75c5.385 0 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25 2.25 6.615 2.25 12s4.365 9.75 9.75 9.75zM8.536 9.736H6.15L8.778 15.7l-.958 2.15h2.338l3.499-8.114h-2.323l-1.408 3.427-1.39-3.427zm5.224 2.739h2.602l2.3-5.35H16.07l-2.31 5.35zm2.127 1.905c0-.77-.647-1.393-1.445-1.393s-1.446.624-1.446 1.393c0 .77.647 1.394 1.446 1.394.798 0 1.445-.624 1.445-1.394z" _fill="#81899D"/>'
  }
})
