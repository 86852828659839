/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'general/check': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M21.792 6.163a.75.75 0 10-1.06-1.06L8.526 17.306l-5.258-5.258a.75.75 0 10-1.061 1.06l5.788 5.789a.75.75 0 001.061 0L21.792 6.163z" _fill="#4E566A"/>'
  }
})
