/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'quote': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.916 19.556a.5.5 0 00.38-.825l-5.518-6.467a.5.5 0 01.003-.652l5.5-6.34a.5.5 0 00-.377-.828h-3.43a.5.5 0 00-.378.172l-6.07 6.996a.5.5 0 00-.002.652l6.072 7.117a.5.5 0 00.38.175h3.44zm-8.744 0a.5.5 0 00.38-.825l-5.519-6.467a.5.5 0 01.003-.652l5.501-6.34a.5.5 0 00-.377-.828H7.73a.5.5 0 00-.378.172l-6.07 6.996a.5.5 0 00-.003.652l6.073 7.117a.5.5 0 00.38.175h3.44z" _fill="#D5E5F0"/>'
  }
})
