/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/small/no-file': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.667 3.333h11.072a1 1 0 01.707.293l1.207 1.207h-.008l.337.337 8.233 8.233v-.007l1.207 1.207a1 1 0 01.293.707V33.5a4 4 0 01-4 4H10.667a4 4 0 01-4-4V7.333a4 4 0 014-4zm21.548 11.625h-8.733a1 1 0 01-1-1V4.833H10.667a2.5 2.5 0 00-2.5 2.5V33.5a2.5 2.5 0 002.5 2.5h19.048a2.5 2.5 0 002.5-2.5V14.958zm-8.233-1.5V7.292l6.166 6.166h-6.166z" _fill="#1092DC"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M20.19 18.15a.6.6 0 01.6.6v6.468l2.727-2.726a.6.6 0 01.848.849l-3.747 3.747a.598.598 0 01-.854 0l-3.747-3.747a.6.6 0 01.848-.849l2.726 2.726V18.75a.6.6 0 01.6-.6zm-4.582 9.583a.6.6 0 100 1.2h9.166a.6.6 0 100-1.2h-9.166z" _fill="#1092DC"/>'
  }
})
