<template>
    <div
        ref="container"
        class="modal-video"
    >

        <div
            :class="[
                'modal-video__media',
                { 'modal-video__media--wide' : wide }
                ]"
            >
            <z-video ref="video"
                :muted="false"
                :src="data.image.full"
                :autoplay="data.autoplay"
                :poster="data.image.preview"
            />
        </div>
        <div class="modal-video__info" v-if="data.name || data.detail">
            <div
                ref="name"
                v-if="data.name"
                v-html="data.name"
                class="modal-video__name"
            ></div>
            <div
                ref="detail"
                v-html="data.detail"
                class="modal-video__detail"
            ></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: Object,
        offsetTop: {
            type: Number,
            default: 0
        },
        offsetBottom: {
            type: Number,
            default: 0
        },
        wide: Boolean
    }
}
</script>

<style lang="scss">
    .modal-video {
        width: 100%;
        margin: 0 auto;

        &__name {
            font-size: 28px;
            line-height: 1.2;
            color: $token-colors-black;
            margin: 0 !important;
            padding-bottom: 24px;

        }

        &__info {
            @include padding-level(top, S);
        }

        &__detail {
            @include typo-level(S);
        }

        &__media {

            &--wide {
                margin-left: -40px;
                width: calc(100% + 80px);
            }
        }
    }
</style>
