/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tools/close': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M21.063 3.032a.75.75 0 010 1.06l-7.955 7.955 7.955 7.955a.75.75 0 11-1.06 1.06l-7.956-7.954-7.954 7.955a.75.75 0 01-1.06-1.06l7.954-7.956-7.955-7.954a.75.75 0 011.06-1.06l7.955 7.954 7.955-7.955a.75.75 0 011.06 0z" _fill="#81899D"/>'
  }
})
