/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'people-default/s': {
    width: 52,
    height: 52,
    viewBox: '0 0 52 52',
    data: '<g clip-path="url(#clip0_3360_15571)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8.632 43.368c-9.591-9.591-9.591-25.142 0-34.734 9.592-9.591 25.143-9.591 34.734 0 9.592 9.592 9.592 25.143 0 34.734-9.591 9.592-25.142 9.592-34.734 0zM7.925 7.927c-9.982 9.982-9.982 26.166 0 36.148 9.982 9.982 26.166 9.982 36.148 0 9.982-9.982 9.982-26.166 0-36.148-9.982-9.982-26.166-9.982-36.148 0zm17.691.314c-3.08 0-5.673 2.803-5.673 6.367 0 3.604 2.62 6.776 5.673 6.776 1.524 0 2.935-.78 3.98-2.036a7.506 7.506 0 001.694-4.74c0-3.564-2.586-6.367-5.674-6.367zm-6.673 6.367c0-4.013 2.943-7.367 6.673-7.367 3.739 0 6.674 3.354 6.674 7.367a8.505 8.505 0 01-1.925 5.379c-1.192 1.434-2.867 2.397-4.749 2.397-3.772 0-6.673-3.802-6.673-7.776zm6.673 10.544c-6.648 0-12.034 5.394-12.034 12.035v2.94c0 .197.04.31.08.375a.436.436 0 00.2.167c.235.112.61.15 1.147.15h3.036v-6.065a.5.5 0 111 0v6.065h13.143v-6.065a.5.5 0 111 0v6.065h3.028c.109 0 .209 0 .303.002.156.001.297.003.436 0 .22-.006.374-.024.483-.057.1-.03.133-.063.15-.086.02-.028.061-.108.063-.309v-3.182c0-6.648-5.386-12.035-12.035-12.035zM18.563 41.82H32.67a.385.385 0 00.037 0h3.51c.084 0 .172 0 .261.002h.005c.17.001.344.003.499-.001.243-.006.504-.026.743-.098.249-.074.5-.211.68-.465.176-.25.244-.552.247-.881v-3.189c0-7.2-5.834-13.035-13.035-13.035-7.2 0-13.034 5.843-13.034 13.035v2.94c0 .333.068.635.224.893.157.26.38.436.627.553.463.219 1.051.246 1.576.246h3.517a.38.38 0 00.037 0z" _fill="#0077C8"/></g><defs><clipPath id="clip0_3360_15571"><path pid="1" _fill="#fff" d="M0 0h52v52H0z"/></clipPath></defs>'
  }
})
