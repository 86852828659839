/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bullet/oval': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M9.917 8A1.917 1.917 0 018 9.917v1.5A3.417 3.417 0 0011.417 8h-1.5zM8 6.083c1.059 0 1.917.858 1.917 1.917h1.5A3.417 3.417 0 008 4.583v1.5zM6.083 8c0-1.059.859-1.917 1.917-1.917v-1.5A3.417 3.417 0 004.583 8h1.5zM8 9.917A1.917 1.917 0 016.083 8h-1.5A3.417 3.417 0 008 11.417v-1.5z" _fill="#0077C8"/>'
  }
})
