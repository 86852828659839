var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
        'z-textarea',
        {
            'is-focused': _vm.isFocused,
            'is-errored': _vm.isValid === false,
            'is-valid': _vm.isValid,
            'is-filled': _vm.textareaValue,
            'is-disabled': _vm.disabled,
            'is-resizable': _vm.resize,
            'is-required': _vm.required
        }
    ]},[_c('label',{staticClass:"z-textarea__label"},[_c('textarea',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.textareaValue),expression:"textareaValue"}],staticClass:"z-textarea__textarea",attrs:{"name":"name","disabled":_vm.disabled,"cols":"30","rows":"4"},domProps:{"value":(_vm.textareaValue)},on:{"blur":_vm.onBlur,"focus":_vm.onFocus,"input":[function($event){if($event.target.composing){ return; }_vm.textareaValue=$event.target.value},function($event){return _vm.onInput($event)}],"change":function($event){return _vm.onChange($event)}}},'textarea',_vm.$attrs,false)),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.placeholder),expression:"placeholder"}],staticClass:"z-textarea__placeholder",domProps:{"innerHTML":_vm._s(_vm.placeholder)}})]),_vm._v(" "),(_vm.error && !_vm.isValid)?_c('span',{class:[
            'z-textarea__error',
            _vm.errorClass
        ],domProps:{"innerHTML":_vm._s(_vm.error)}}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }