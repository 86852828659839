<template>
    <div class="z-notification">
        <div class="z-notification__image">
            <template v-if="!!this.$slots.image">
                <slot name="image"></slot>
            </template>
            <z-image v-else :src="image" width="590" height="400"></z-image>
        </div>
        <div class="z-notification__description">
            <template v-if="!!this.$slots.description">
                <slot name="description" v-if="!!this.$slots.description"></slot>
            </template>
            <template v-else>
                <z-caption size="m" decor-left decor-right weight="900" uppercase>
                    <span v-html="text.title"></span>
                </z-caption>
                <p class="text-size-xl u-normal" v-html="text.description"></p>
            </template>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'z-notification',
    data () {
        return {
            image: '/images/not-found/page-nf.svg',
            text: {
                title: localize({
                    ru: 'Ничего не найдено',
                    en: 'No results found'
                }),
                description: localize({
                    ru: 'По&nbsp;Вашему запросу ничего не&nbsp;нашлось.',
                    en: 'Sorry, no matches were found for your request. Try changing your search terms.'
                })
            }
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
