<template>
    <div class="z-lead" :class="classObject">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'z-lead',
    props: {
        colouredBg: {
            type: Boolean,
            default: false
        },
        decorSide: {
            type: String,
            default: 'left',
            validator: (prop) => ['left', 'top', 'bottom'].includes(prop)
        },
        decor: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        classObject () {
            return ({
                [`z-lead--coloured-bg`]: this.colouredBg,
                [`z-lead--with-decor z-lead--with-decor-${this.decorSide}`]: this.decor && this.decorSide
            })
        }
    }
}
</script>

<style lang="scss" src="./index.scss">
</style>
