<template>
    <div class="z-filelist" :class="buildClass">
        <slot/>
    </div>
</template>

<script>
export default {
    name: 'z-filelist',
    props: {
        horizontal: Boolean,
        border: Boolean
    },
    computed: {
        buildClass () {
            return [
                {
                    'z-filelist--horizontal': this.horizontal,
                    'z-filelist--vertical': !this.horizontal,
                    'z-filelist--border': this.border
                }
            ]
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
