/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bullet/dot/m': {
    width: 27,
    height: 27,
    viewBox: '0 0 27 27',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M13 17a4 4 0 100-8 4 4 0 000 8z" _fill="#0077C8"/>'
  }
})
