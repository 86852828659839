<template>
    <div class="search">
        <div class="search__filters">
            <z-input
                @keyup.enter.native="searchValue.length >= 3 ? searchButtonHandler() : false"
                v-model="searchValue"
                placeholder="Введите запрос"
                name="search-value" is-filter
                minVal="3"
            />
            <z-button
                size="l"
                :disabled="isLoading || searchValue.length < 3"
                @click="searchButtonHandler"
            >
                Искать
            </z-button>
        </div>
        <template v-if="!init">
            <div v-if="items && items.length" class="search__content">
                <div class="search__sorting">
                    <span class="search__sorting-text">Сортировка:</span>
                    <div class="search__sorting-wrapper">
                        <button class="search__sorting-button"
                                type="button"
                                :disabled="sortingType === 'r'"
                                @click="sortingHandler('r')"
                        >
                            По релевантности
                        </button>
                        <button class="search__sorting-button"
                                type="button"
                                :disabled="sortingType === 'd'"
                                @click="sortingHandler('d')"
                        >
                            По дате
                        </button>
                    </div>
                </div>
                <div class="search__list u-bottom-margin--m">
                    <div class="search__card" v-for="(item, index) in items" :key="`search-item-${index}`">
                        <z-caption class="search__card-title" :href="item.url" size="xl" weight="600">
                            <span v-html="item.title"/>
                        </z-caption>
                        <p class="search__card-announce">
                            <span v-html="item.text"/>
                        </p>
                        <span class="search__card-date text-size-m" v-if="item.date">
                            {{ item.date | momentFilter('DD MMMM YYYY') }}
                        </span>
                    </div>
                </div>
            </div>
            <z-notification v-else class="u-top-margin--xl search__list">
                <template v-slot:image>
                    <z-image src="/images/znotification/not-found.png" width="120" height="120"></z-image>
                </template>
                <template v-slot:description>
                    <z-caption size="m" decor-left decor-right>
                        Ничего не&nbsp;найдено
                    </z-caption>
                    <p class="text-size-xl u-normal">
                        По&nbsp;вашему запросу ничего не&nbsp;найдено, попробуйте изменить
                        формулировку или выполнить поиск по&nbsp;ключевым словам.
                    </p>
                </template>
            </z-notification>
            <template v-if="isLoading">
                <z-preloader class="u-bottom-margin--xs" />
            </template>
            <z-pagination
                v-if="Boolean(data)"
                :show-more="{ marginBottom: 'xs' }"
                show-next
                show-nav
                show-prev
                :data="adaptedNav"
                @change-page="changePagination"
            />
        </template>
    </div>
</template>

<script>
import { queryString } from '@/utils/queryString'
import { getSearch } from '../../api/search'

export default {
    name: 'search',
    data () {
        return {
            searchValue: '',
            sortingType: 'r',
            init: true,
            isMore: false,
            isLoading: false,
            prevItems: [],
            data: null,
            currentPage: 1,
            items: []
        }
    },
    mounted () {
        this.getInitial()
    },
    computed: {
        adaptedNav () {
            return {
                currentPage: this.data.nav.current,
                totalItems: this.data.nav.count,
                totalPages: this.data.nav.total,
                pageRange: 1
            }
        }
    },
    methods: {
        getData () {
            this.isLoading = true
            const params = {
                page: this.currentPage,
                how: this.sortingType,
                q: this.searchValue
            }
            getSearch(params)
                .then((response) => {
                    this.data = response
                    if (this.isMore) {
                        this.items = [...this.items, ...response.items]
                        this.isMore = false
                        return
                    }
                    this.items = response.items
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    this.isLoading = false
                    this.init = false
                })
        },
        refreshParams () {
            const params = {}
            this.searchValue && (params.q = this.searchValue)
            this.sortingType && (params.how = this.sortingType)
            this.currentPage && (params.currentPage = this.currentPage)
            queryString(params)
        },

        getInitial () {
            const params = new URL(document.location).searchParams
            const init = {
                searchValue: params.get('q') ? params.get('q') : '',
                sortingType: params.get('how') ? params.get('how') : 'r'
            }
            this.searchValue = init.searchValue
            this.sortingType = init.sortingType
            this.currentPage = params.get('currentPage') ? Number(params.get('currentPage')) : 1
            if (this.searchValue !== '') {
                this.getData()
            }
        },

        changePagination (payload) {
            if (payload.type === 'load-more') {
                this.isMore = true
            }
            this.currentPage = payload.page
            this.refreshParams()
            this.getData()
        },

        sortingHandler (type) {
            this.currentPage = 1
            this.sortingType = type
            this.refreshParams()
            this.getData()
        },

        searchButtonHandler () {
            this.currentPage = 1
            this.refreshParams()
            this.getData()
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
