/* eslint-disable */
require('./doc')
require('./docx')
require('./jpg')
require('./mp3')
require('./no-file')
require('./pdf')
require('./png')
require('./ppt')
require('./rar')
require('./rtf')
require('./tif')
require('./txt')
require('./xls')
require('./xlsx')
require('./zip')
