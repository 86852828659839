/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tools/pause_big': {
    width: 123,
    height: 123,
    viewBox: '0 0 123 123',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M36.048 30h13.714c1.683 0 3.047 1.302 3.047 2.91v58.18c0 1.608-1.364 2.91-3.047 2.91H36.048C34.364 94 33 92.698 33 91.09V32.91c0-1.608 1.364-2.91 3.048-2.91zm38.095 0h13.714c1.683 0 3.048 1.302 3.048 2.91v58.18c0 1.608-1.365 2.91-3.048 2.91H74.143c-1.683 0-3.048-1.302-3.048-2.91V32.91c0-1.608 1.365-2.91 3.048-2.91z" _fill="#81899D"/>'
  }
})
