/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'people-default/m': {
    width: 80,
    height: 80,
    viewBox: '0 0 80 80',
    data: '<g clip-path="url(#clip0_3360_15555)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M13.16 66.84c-14.822-14.822-14.822-38.854 0-53.676 14.823-14.822 38.855-14.822 53.677 0 14.822 14.822 14.822 38.854 0 53.676-14.823 14.823-38.854 14.823-53.677 0zm-.848-54.525c-15.291 15.291-15.291 40.083 0 55.374 15.29 15.29 40.082 15.29 55.373 0 15.291-15.291 15.291-40.083 0-55.374-15.29-15.29-40.082-15.29-55.373 0zm27.098.194c-4.85 0-8.898 4.406-8.898 9.966 0 5.607 4.077 10.593 8.898 10.593 2.404 0 4.62-1.231 6.251-3.194 1.631-1.964 2.646-4.627 2.646-7.4 0-5.56-4.037-9.965-8.898-9.965zm-10.098 9.966c0-6.1 4.469-11.166 10.098-11.166 5.641 0 10.097 5.067 10.097 11.166 0 3.056-1.115 5.99-2.923 8.166s-4.34 3.627-7.175 3.627c-5.682 0-10.097-5.742-10.097-11.793zm10.097 16.052c-10.321 0-18.683 8.374-18.683 18.684v4.523c0 .326.066.531.146.664.08.13.198.236.38.323.4.19 1.014.247 1.838.247h4.84v-9.5a.6.6 0 111.2 0v9.5H49.69v-9.5a.6.6 0 111.2 0v9.5h4.827c.163 0 .316.001.461.003.242.002.464.004.681-.001.342-.009.597-.037.788-.093.179-.054.266-.123.319-.197.057-.08.125-.242.128-.572v-4.897c0-10.322-8.362-18.684-18.684-18.684zm-10.854 25.64h21.709a.686.686 0 00.05 0h5.402l.415.003h.001c.26.003.521.005.757 0 .37-.01.753-.04 1.1-.144.357-.106.705-.3.955-.652.245-.345.346-.774.35-1.259V57.21c0-10.984-8.9-19.883-19.885-19.883-10.985 0-19.883 8.912-19.883 19.884v4.523c0 .49.1.922.32 1.286.222.366.538.617.893.785.673.32 1.542.363 2.351.363H28.53a.69.69 0 00.025 0z" _fill="#0077C8"/></g><defs><clipPath id="clip0_3360_15555"><path pid="1" _fill="#fff" d="M0 0h80v80H0z"/></clipPath></defs>'
  }
})
