/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar/calendar-add': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M16.5 2.25a.75.75 0 01.75.75v.75h2.25a1.5 1.5 0 011.5 1.5v15a1.5 1.5 0 01-1.5 1.5h-15a1.5 1.5 0 01-1.5-1.5v-15a1.5 1.5 0 011.5-1.5h2.25V3a.75.75 0 011.5 0v.75h7.5V3a.75.75 0 01.75-.75zm.75 3h2.25v3h-15v-3h2.25V6a.75.75 0 001.5 0v-.75h7.5V6a.75.75 0 001.5 0v-.75zm2.25 4.5h-15v10.5h15V9.75zm-4.318 6a.75.75 0 000-1.5H12.75v-2.432a.75.75 0 00-1.5 0v2.432H8.818a.75.75 0 000 1.5h2.432v2.432a.75.75 0 001.5 0V15.75h2.432z" _fill="#81899D"/>'
  }
})
