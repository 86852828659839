/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tools/external-1': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M17.03 4.72a.75.75 0 10-1.06 1.06L19.19 9H12a9.75 9.75 0 00-9.75 9.75.75.75 0 001.5 0A8.25 8.25 0 0112 10.5h7.19l-3.22 3.22a.75.75 0 101.06 1.06l4.5-4.5a.747.747 0 000-1.06l-4.5-4.5z" _fill="#81899D"/>'
  }
})
