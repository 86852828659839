/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tools/report': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.75 2.25a.75.75 0 00-1.5 0v1.5h-7.5a1.5 1.5 0 00-1.5 1.5V16.5a1.5 1.5 0 001.5 1.5h3.69l-2.026 2.532a.75.75 0 101.172.936L9.36 18h5.28l2.774 3.468a.75.75 0 001.172-.936L16.56 18h3.689a1.5 1.5 0 001.5-1.5V5.25a1.5 1.5 0 00-1.5-1.5h-7.5v-1.5zm-.75 3H3.75V16.5h16.5V5.25H12zM9 10.5a.75.75 0 01.75.75v2.25a.75.75 0 01-1.5 0v-2.25A.75.75 0 019 10.5zm3.75-.75a.75.75 0 00-1.5 0v3.75a.75.75 0 001.5 0V9.75zM15 7.5a.75.75 0 01.75.75v5.25a.75.75 0 01-1.5 0V8.25A.75.75 0 0115 7.5z" _fill="#81899D"/>'
  }
})
