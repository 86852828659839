/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tools/tooltip': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14 8A6 6 0 112 8a6 6 0 0112 0zm1.5 0a7.5 7.5 0 11-15 0 7.5 7.5 0 0115 0zM9.15 4.15a1.15 1.15 0 11-2.3 0 1.15 1.15 0 012.3 0zM8 6.4a.9.9 0 01.9.9v4.564a.9.9 0 01-1.8 0V7.3a.9.9 0 01.9-.9z" _fill="#81899D"/>'
  }
})
