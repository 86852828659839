/* eslint-disable */
require('./audio')
require('./burger')
require('./cast')
require('./close-small')
require('./close')
require('./download-1')
require('./download-2')
require('./external-1')
require('./external-2')
require('./gear')
require('./link')
require('./lock')
require('./magnifier-minus')
require('./magnifier-plus')
require('./magnifier')
require('./paperclip')
require('./pause_big')
require('./pause_small')
require('./pause-circle')
require('./play_big')
require('./play_small')
require('./play-circle')
require('./presentation')
require('./press-release')
require('./printer')
require('./report')
require('./tooltip-hover')
require('./tooltip')
require('./unlock')
