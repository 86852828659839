<template>
    <tr
        class="z-table-row z-table-row--body"
        v-if="(!isExpandable || opened) || (isMobile() || isTablet())"
        :class="buildClass"
        @click="check()"
    >
        <slot />
    </tr>
</template>

<script>
import { mixinDevice } from '@/utils/mixin'

export default {
    name: 'z-table-row',
    mixins: [mixinDevice],
    props: {
        name: {
            type: String,
            default: ''
        },
        isClickable: Boolean,
        isExpandable: Boolean
    },
    data () {
        return {
            opened: false,
            clicked: false
        }
    },
    mounted () {
        if (!this.isExpandable) return false
        this.$root.$bus.$on('open-rows', data => this.toggle(data))
    },
    beforeDestroy () {
        if (!this.isExpandable) return false
        this.$root.$bus.$off('open-rows', data => this.toggle(data))
    },
    computed: {
        buildClass () {
            return {
                'is-opened': this.clicked,
                'z-table-row--clickable': this.isClickable,
                'z-table-row--expandable': this.isExpandable
            }
        }
    },
    methods: {
        check () {
            if (this.isClickable) {
                this.clicked = !this.clicked
                this.$root.$bus.$emit('open-rows', this.name)
            }
        },
        toggle (data) {
            if (data === this.name) this.opened = !this.opened
        }
    }
}
</script>
