<template>
    <div class="nm-dropdown__body">
        <div class="navigation-mobile__header">
            <div class="container">
                <div v-if="currentLevel.header"
                     class="navigation-mobile__item navigation-mobile__item--backlink">
                        <span class="navigation-mobile__item-inner" @click="backToLevel">
                            <z-icon
                                class="navigation-mobile__arrow navigation-mobile__arrow--left"
                                name="arrows/caret-small"
                                dir="left"/>
                            <a href="javascript:void(0);">
                                <span>
                                    {{ currentLevel.header }}
                                </span>
                            </a>
                        </span>
                </div>
                <div v-else class="navigation-mobile__search">
                    <z-input
                        name="input2"
                        theme="search"
                        class="nm-search"
                        placeholder="Поиск"
                        v-model="searchValue"
                        @keyup.enter.native="searchHandler"
                    ></z-input>
                    <a
                        class="navigation-mobile__link"
                        :href="urlFromSearch">
                        <z-icon name="tools/magnifier"></z-icon>
                    </a>
                </div>
            </div>
        </div>
        <div class="navigation-mobile__submenu">
            <div class="container">
                <ul class="navigation-mobile__list">
                    <li v-if="currentLevel.parent" class="navigation-mobile__item navigation-mobile__item--parent">
                        <component
                           :is="currentLevel.parent.pageParams.redirect ? 'span' : 'a'"  :href="currentLevel.parent.link"
                           v-html="currentLevel.parent.name"
                           class="navigation-mobile__item-inner"
                           :class="{'navigation-mobile__item-inner--redirect' : currentLevel.parent.pageParams.redirect}"/>
                    </li>
                    <li class="navigation-mobile__item"
                        v-for="(item, index) in currentLevel.items"
                        :key="index"
                        :class="[{'is-active': checkActiveState(item)}, {'navigation-mobile__item--submenu': levelCount !== 0}]">
                        <template v-if="item.items && item.items.length">
                            <template v-if="showAlternateLink">
                                <accordion-link :item-parent="item">
                                    <span v-html="item.name"/>
                                </accordion-link>
                            </template>
                            <span v-else class="navigation-mobile__item-inner" @click="changeLevel(item, header)">
                                <span v-html="item.name"/>
                                <z-icon class="navigation-mobile__arrow navigation-mobile__arrow--right"
                                        name="arrows/caret-small"
                                        dir="right"/>
                            </span>
                        </template>
                        <a v-else
                           class="navigation-mobile__item-inner"
                           :href="item.link"
                           :title="item.name"
                           :target="item.params && item.params.BLANK ? '_blank' : false">
                            <span>
                                <span v-html="item.name"/>
                                <z-icon class="navigation-mobile__external-icon"
                                        v-if="item.params.EXTERNAL"
                                        name="tools/external-2"/>
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import AccordionLink from './AccordionLink.vue'

export default {
    name: 'navigation-mobile-body',
    components: {
        AccordionLink
    },
    data () {
        return {
            top: this.$root.app.components.navigation.top,
            text: {
                back: 'В главное меню'
            },
            levelMap: [],
            levelCount: 0,
            currentLevel: {
                items: [],
                header: null,
                parent: null
            },
            searchValue: ''
        }
    },
    mounted () {
        this.levelMap.push({
            items: this.top,
            header: null,
            parent: null
        })
        this.updateCurrentLevel()
    },
    computed: {
        header () {
            if (this.currentLevel.parent === null) {
                return 'Главная страница'
            }
            return this.currentLevel.parent.name
        },
        maxLevels () {
            const collection = new Set()
            this.levelsCounter(this.top, collection)
            return Math.max(...collection)
        },
        showAlternateLink () {
            return this.levelCount === this.maxLevels - 2
        },
        urlFromSearch () {
            return `/search/?currentPage=1&how=r&q=${this.searchValue}`
        }
    },
    methods: {
        changeLevel (item, header) {
            this.levelCount = item.level
            this.levelMap.push(this.adaptDataToLevel(item, header))
            this.updateCurrentLevel()
        },
        backToLevel () {
            this.levelCount -= 1
            this.levelMap.splice(this.levelMap.length - 1, 1)
            this.updateCurrentLevel()
        },
        checkActiveState (item) {
            if (item.active === true) return true

            for (let i = 0; i < item.items?.length; i++) {
                if (item.items[i].active === true) return true
            }

            return false
        },
        // levelsCounter считает рекрсивно сколько у нас всего уровней
        levelsCounter (parent, collection) {
            parent.forEach((item) => {
                collection.add(item.level)
                item.items?.length > 0 && (this.levelsCounter(item.items, collection))
            })
        },
        adaptDataToLevel (data, header) {
            return {
                items: data.items,
                header: header,
                parent: data
            }
        },
        /* В связи с тем что watch и computed не могут отследить изменение
        массива пришлось делать отдельный метод для обновления текущего уровня
        вложенности */
        updateCurrentLevel () {
            this.currentLevel = this.levelMap[this.levelMap.length - 1]
        },

        searchHandler () {
            window.location.replace(this.urlFromSearch)
        }
    }
}
</script>

<style lang="scss" src="../index.scss"/>
