/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/small/xlsx': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.667 3.333h11.072a1 1 0 01.707.293l1.207 1.207h-.008l.337.337 8.233 8.233v-.007l1.207 1.207a1 1 0 01.293.707V33.5a4 4 0 01-4 4H10.667a4 4 0 01-4-4V7.333a4 4 0 014-4zm21.548 11.625h-8.733a1 1 0 01-1-1V4.833H10.667a2.5 2.5 0 00-2.5 2.5V33.5a2.5 2.5 0 002.5 2.5h19.048a2.5 2.5 0 002.5-2.5V14.958zm-8.233-1.5V7.292l6.166 6.166h-6.166z" _fill="#1092DC"/><path pid="1" d="M10.761 26.167l2.018-2.693-1.943-2.595h1.118l1.372 1.898 1.373-1.898h1.125l-1.943 2.595 2.01 2.693h-1.125l-1.44-1.98-1.432 1.98H10.76zm5.512 0v-5.288h.96v4.47h2.535v.818h-3.495zm5.774.075c-.415 0-.8-.053-1.155-.158a2.91 2.91 0 01-.9-.442l.292-.758c.245.175.513.31.803.405.295.095.615.143.96.143.395 0 .682-.065.862-.195a.62.62 0 00.27-.518.492.492 0 00-.195-.412c-.125-.1-.342-.183-.652-.248l-.825-.172c-.94-.2-1.41-.683-1.41-1.448 0-.33.087-.617.262-.862.175-.245.418-.435.728-.57.31-.135.667-.203 1.072-.203.36 0 .698.055 1.013.165.315.105.577.255.787.45l-.292.72c-.415-.35-.92-.525-1.515-.525-.345 0-.615.073-.81.218a.678.678 0 00-.293.57c0 .18.06.325.18.435.12.105.325.187.615.247l.818.173c.495.105.862.27 1.102.495.245.225.368.525.368.9 0 .315-.085.592-.255.832a1.67 1.67 0 01-.728.563c-.31.13-.677.195-1.102.195zm2.063-.075l2.017-2.693-1.942-2.595h1.117l1.373 1.898 1.372-1.898h1.125l-1.942 2.595 2.01 2.693h-1.125l-1.44-1.98-1.433 1.98H24.11z" _fill="#1092DC"/>'
  }
})
