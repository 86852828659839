<template>
    <div class="navigation-right"
        v-if="data.length"
        role="navigation"
        aria-label="right navigation"
    >
        <div
            class="navigation-right__item"
            v-for="(item, index) in data"
            :key="index"
            :class="{'is-active': item.active}"
        >
            <z-link
                :href="item.link"
                :title="item.name"
                size="m"
                theme="dark"
                :underlined="false"
                :class="['u-semi-bold', { 'is-active': item.active }]"
            >
                <span v-html="item.name"></span>
            </z-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'navigation-right',
    data () {
        return {
            data: this.$root.app.components.navigation?.left || []
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
