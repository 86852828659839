/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tools/printer': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6 3a.75.75 0 00-.75.75v3h-1c-1.255 0-2.375.952-2.375 2.25v7.5c0 .414.335.75.75.75H5.25v3.375c0 .414.335.75.75.75h12a.75.75 0 00.75-.75V17.25h2.625a.75.75 0 00.75-.75V9c0-1.298-1.122-2.25-2.375-2.25h-1v-3A.75.75 0 0018 3H6zM4.25 8.25h15.5c.54 0 .875.391.875.75v6.75H18.75v-1.5a.75.75 0 00-.75-.75H6a.75.75 0 00-.75.75v1.5H3.375V9c0-.359.334-.75.875-.75zm2.5-1.5h10.5V4.5H6.75v2.25zm0 8.25h10.5v4.875H6.75V15zm12-4.125a1.125 1.125 0 11-2.25 0 1.125 1.125 0 012.25 0z" _fill="#81899D"/>'
  }
})
