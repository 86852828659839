/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'general/multipoint': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.34 16.09a2.09 2.09 0 100-4.18 2.09 2.09 0 000 4.18zM14.09 14a2.09 2.09 0 11-4.18 0 2.09 2.09 0 014.18 0zm7.66 0a2.09 2.09 0 11-4.179 0 2.09 2.09 0 014.179 0z" _fill="#4E566A"/>'
  }
})
