/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tools/press-release': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5.249 2.25a1.5 1.5 0 00-1.5 1.5v16.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5V8.284a.76.76 0 00-.238-.584l-5.23-5.23a.75.75 0 00-.531-.22m-.75 1.5H5.249v16.5h13.5V9h-4.5a.75.75 0 01-.75-.75v-4.5zm4.19 3.75L15 4.81V7.5h2.69zM9 12a.75.75 0 000 1.5h6a.75.75 0 000-1.5H9zm-.75 3.75A.75.75 0 019 15h6a.75.75 0 010 1.5H9a.75.75 0 01-.75-.75z" _fill="#81899D"/>'
  }
})
