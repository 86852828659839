/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'external/s': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.53 2.97a.75.75 0 10-1.06 1.06l1.72 1.72H8a6.75 6.75 0 00-6.75 6.75.75.75 0 001.5 0A5.25 5.25 0 018 7.25h4.19l-1.72 1.72a.75.75 0 101.06 1.06l3-3a.747.747 0 000-1.06l-3-3z" _fill="#0077C8"/>'
  }
})
