/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tools/paperclip': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M16.5 2.25a3.75 3.75 0 00-2.652 1.098l-.004.004-9.308 9.438a5.25 5.25 0 007.426 7.422l7.692-7.682a.75.75 0 00-1.06-1.061l-7.692 7.682a3.75 3.75 0 11-5.304-5.303l.004-.003 9.308-9.438a2.25 2.25 0 013.18 3.183l-.003.004-9.308 9.438a.75.75 0 01-1.06-1.062l.005-.005 7.81-7.94a.75.75 0 10-1.07-1.051L6.658 14.91a2.25 2.25 0 003.184 3.18l.004-.004 9.308-9.438a3.749 3.749 0 00-2.653-6.4z" _fill="#81899D"/>'
  }
})
