<template>
    <div class="z-page-nav" :class="{ 'z-page-nav--with-back-link': data['back-link'] }">
        <a
            :href="data.prev ? data.prev.url : 'javascript:void(0)'"
            :class="[
                'z-page-nav__link',
                { 'is-disabled': !(data.prev && data.prev.url) }
            ]"
        >
            <z-icon
                name="arrows/caret"
                dir="left"
                width="14px"
                height="14px"
                color="#0077c8"
                class="page-changer__link-icon u-right-margin--3xs"
            ></z-icon>
            <span class="z-page-nav__link-text hide-me--mobile" v-html="data.prev.name"></span>
        </a>
        <div class="z-page-nav__button-wrapper">
            <z-button
                v-if="data['back-link']"
                kind="bordered"
                tag="a"
                :href="data['back-link']"
                v-html="buttonText"
            ></z-button>
        </div>
        <a
            :href="data.next ? data.next.url : 'javascript:void(0)'"
            :class="
                ['z-page-nav__link',
                { 'is-disabled': !(data.next && data.next.url) }
            ]"
        >
            <span class="z-page-nav__link-text hide-me--mobile" v-html="data.next.name"></span>
            <z-icon
                name="arrows/caret"
                dir="right"
                color="#0077c8"
                width="14px"
                height="14px"
                class="page-changer__link-icon u-left-margin--3xs"
            ></z-icon>
        </a>
    </div>
</template>

<script>
export default {
    name: 'z-page-nav',
    props: {
        data: {
            required: true,
            type: Object
        },
        buttonText: {
            type: String,
            default: 'К списку'
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
