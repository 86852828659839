/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tools/audio': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M15.816 3.721a9.75 9.75 0 00-3.748-.72A9.75 9.75 0 002.25 12.75V18a2.25 2.25 0 002.25 2.25H6A2.25 2.25 0 008.25 18v-3.75A2.25 2.25 0 006 12H3.784a8.25 8.25 0 018.279-7.5h.01a8.25 8.25 0 018.28 7.5h-2.216a2.25 2.25 0 00-2.25 2.25V18a2.25 2.25 0 002.25 2.25h1.5a2.25 2.25 0 002.25-2.25v-5.25a9.75 9.75 0 00-6.07-9.029zM3.75 18v-4.5H6a.75.75 0 01.75.75V18a.75.75 0 01-.75.75H4.5a.75.75 0 01-.75-.75zm14.387-4.5h2.25V18a.75.75 0 01-.75.75h-1.5a.75.75 0 01-.75-.75v-3.75a.75.75 0 01.75-.75z" _fill="#81899D"/>'
  }
})
