/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'accordions/folders': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5.69 4.19a1.5 1.5 0 011.06-.44H11c.325 0 .64.105.9.3L14.5 6H21a1.5 1.5 0 011.5 1.5v8.333a1.417 1.417 0 01-1.417 1.417H19.5v1.583a1.417 1.417 0 01-1.417 1.417H3.75a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h1.5v-1.5c0-.398.158-.78.44-1.06zM21 15.75h-1.5V10.5A1.5 1.5 0 0018 9h-6.5L8.9 7.05a1.5 1.5 0 00-.9-.3H6.75v-1.5H11l2.6 1.95c.26.195.575.3.9.3H21v8.25zM3.75 8.25H8l2.6 1.95c.26.195.575.3.9.3H18v8.25H3.75V8.25z" _fill="#81899D"/>'
  }
})
