<template>
    <div
        class="z-video"
        @mouseover="handleMouseOver"
        @mouseout="handleMouseOut"
        :style="{width}"
    >
        <div
            class="z-video__title"
            v-if="title"
            :class="{'z-video__title--visible': title && started && (paused || hovered)}"
        >
            <span v-html="title"></span>
        </div>
        <video
            ref="videoPlayer"
            class="video-js vjs-default-skin"
            controls
            preload="auto"
        ></video>
    </div>
</template>

<script>
import videojs from 'videojs-youtube/node_modules/video.js/dist/video.es'
import 'videojs-youtube'

export default {
    name: 'z-video',
    props: {
        poster: {
            type: String
        },
        src: {
            type: [String, Array],
            required: true
        },
        muted: {
            type: Boolean,
            default: true
        },
        loop: {
            type: Boolean,
            default: false
        },
        autoplay: {
            type: Boolean,
            default: true
        },
        title: {
            type: String
        },
        width: {
            type: String
        }
    },
    data () {
        return {
            player: null,
            volume: 0.5,
            playing: false,
            paused: true,
            hovered: null,
            started: false,
            titleTimeout: null
        }
    },
    mounted () {
        this.playerInitialize()
        this.playerSetSrc(this.prepareSrc(this.src))
        this.playerSetupEvents()
        this.playerSetVolume()
    },
    beforeDestroy () {
        this.playerDispose()
    },
    watch: {
        src (newValue) {
            this.playerSetSrc(this.prepareSrc(newValue))
        },
        muted () {
            this.playerSetVolume()
        },
        poster (newValue) {
            this.playerSetPoster(newValue)
        }
    },
    methods: {
        getVideoType (src) {
            return src.match(/mp4|webm$/)[0]
        },
        prepareSrc (src) {
            if (typeof src === 'string' && src.includes('youtu')) {
                return {
                    type: 'video/youtube',
                    src: src
                }
            } else if (Array.isArray(src)) {
                const sources = src.map(item => {
                    return {
                        type: `video/${this.getVideoType(item)}`,
                        src: item
                    }
                })
                return sources
            } else {
                return {
                    type: `video/${this.getVideoType(src)}`,
                    src: src
                }
            }
        },
        playerInitialize () {
            this.player = videojs(
                this.$refs.videoPlayer,
                {
                    aspectRatio: '16:9',
                    poster: this.poster,
                    techOrder: ['youtube', 'html5'],
                    autoplay: this.autoplay,
                    loop: this.loop,
                    children: {
                        posterImage: true,
                        bigPlayButton: true,
                        controlBar: {
                            children: {
                                playToggle: true,
                                currentTimeDisplay: true,
                                timeDivider: true,
                                remainingTimeDisplay: true,
                                progressControl: true,
                                volumePanel: {
                                    inline: false
                                },
                                fullscreenToggle: true
                            }
                        }
                    }
                },
                function () {
                    console.log('video player ready')
                }
            )
        },
        playerDispose () {
            if (this.player) {
                this.player.dispose()
            }
        },

        // Settings
        playerSetSrc (src) {
            this.player.src(src)
        },
        playerSetVolume () {
            if (this.muted) {
                this.player.volume(0)
            } else {
                this.player.volume(this.volume)
            }
        },
        playerSetPoster (url) {
            this.player.poster(url)
        },

        // Events
        handlePlayerErrors () {
            const errorMessage = this.player.error().message
            console.log(errorMessage)
        },
        handleVolumeChange () {
            if (!this.muted) {
                this.volume = this.player.volume()
            }
        },
        handlePlay () {
            this.playing = true
            this.paused = false
            if (!this.started) this.started = true
        },
        handlePause () {
            this.playing = false
            this.paused = true
        },
        handleMouseOver () {
            this.hovered = true
            if (!this.titleTimeout) {
                this.titleTimeout = setTimeout(() => {
                    this.hovered = false
                }, 2000)
            }
        },
        handleMouseOut () {
            this.hovered = false
            clearTimeout(this.titleTimeout)
        },

        playerSetupEvents () {
            this.player.on('error', this.handlePlayerErrors)
            this.player.on('volumechange', this.handleVolumeChange)
            this.player.on('play', this.handlePlay)
            this.player.on('pause', this.handlePause)
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
