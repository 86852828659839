/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar/google-calendar': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8.807 14.27h-7.09l-.71-7.802a1.77 1.77 0 011.762-1.93h19.126a1.77 1.77 0 011.762 1.93l-.71 7.801H17v-4.153h-.087l-3.458 1.281v1.218l2.154-.79v2.444h-4.245a2.571 2.571 0 00-.161-.06 2.237 2.237 0 00.947-.797c.104-.162.18-.33.23-.502.054-.174.081-.347.081-.52 0-.393-.065-.737-.196-1.033a2.076 2.076 0 00-.554-.75c-.235-.201-.52-.351-.855-.451A3.629 3.629 0 009.766 10c-.39 0-.747.058-1.075.173a2.659 2.659 0 00-.842.48 2.258 2.258 0 00-.56.732 2.109 2.109 0 00-.202.924h1.38c0-.18.032-.344.097-.49a1.167 1.167 0 01.693-.612 1.55 1.55 0 01.526-.087c.211 0 .398.029.56.087.165.057.302.138.41.242.107.112.188.246.242.404.058.158.087.337.087.537 0 .185-.031.358-.093.52-.058.157-.142.29-.254.398a1.22 1.22 0 01-.456.289c-.185.065-.4.098-.646.098h-.826v.574z" _fill="#626C84"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M8.807 14.27v.51h.825c.24 0 .455.029.647.087.196.053.364.136.502.248.139.115.245.26.318.433.073.173.11.38.11.623 0 .216-.033.408-.099.578a1.07 1.07 0 01-.27.415c-.124.12-.276.21-.457.272a1.933 1.933 0 01-.6.086c-.208 0-.399-.03-.572-.092a1.314 1.314 0 01-.439-.26 1.1 1.1 0 01-.294-.393 1.328 1.328 0 01-.098-.513H7c0 .392.075.737.225 1.033.154.293.358.539.612.74.254.2.549.35.883.45.335.1.683.15 1.045.15.4 0 .772-.054 1.114-.162.347-.112.647-.274.901-.485.25-.208.447-.464.589-.768a2.42 2.42 0 00.22-1.05c0-.212-.026-.416-.076-.613a1.86 1.86 0 00-.242-.56 1.862 1.862 0 00-.433-.456 1.945 1.945 0 00-.474-.274h4.245v4.252H17v-4.252h5.947l.71 7.801A1.77 1.77 0 0121.895 24H2.769a1.77 1.77 0 01-1.762-1.93l.71-7.8h7.09zM2.518 4.538c-.2 0-.39.033-.568.094l-.217-1.629A1.77 1.77 0 013.486 1h17.19a1.77 1.77 0 011.753 2.003l-.217 1.629a1.769 1.769 0 00-.568-.094H2.518zM7.91 2.77a.885.885 0 11-1.77 0 .885.885 0 011.77 0zm10.616 0a.885.885 0 11-1.77 0 .885.885 0 011.77 0z" _fill="#A1A7B5"/>'
  }
})
