/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'igarka': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M16.561 5.907H15.44l-.093.897h1.285l-.034-.554-.036-.343zM16 5.234zm-.863 3.589l.14-1.346h1.446l.14 1.346h-1.726zm-.07.672l-.11 1.07 1.043.802 1.042-.801-.11-1.07h-1.864zm1.503 2.31l.558-.412.094.913-.652-.501zm-1.698-.412l.558.412-.652.501.094-.913zm-.333 3.225l.105-1.021.722.47-.827.551zm.45-1.646l1.01.673 1.012-.673L16 12.226l-1.01.746zm-.111 2.243L16 14.482l1.122.733-1.122.673-1.122-.673zm-.457.54l.885.549-1.003.6.118-1.148zm-.392 3.804l.155-1.503 1.165.712-1.32.791zm.623-1.989l1.348.808 1.347-.808-1.324-.821-1.37.821zm-.448 2.692L16 19.165l1.796 1.097-1.783 1.583-.013-.013-.013.013-1.783-1.583zm5.837 5.159l-3.517-3.075 1.708-1.544 1.81 4.619zm-3.39-6.653l1.32.791-.155-1.502-1.165.71zm.933-2.955l-.854.512.967.58-.113-1.092zm-.95-1.745l.827.55-.106-1.021-.72.47zm-2.866 6.734l-1.81 4.619 3.518-3.075-1.708-1.544zm3.467-15.12h3.48l-3.33 1.479.169 1.661h5.63l-5.402 2.24.912 8.975L21.949 28 16 22.841 10.05 28l3.256-7.963.916-9.014-5.407-2.2h5.63l.166-1.622-3.326-1.519h3.48L16 4l1.235 1.682zm.399 3.926l.078.772 1.768-.772h-1.846zm-3.43.785l.112-.785h-1.908l1.796.785z" _fill="#81899D"/>'
  }
})
