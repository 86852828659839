/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/big/doc': {
    width: 56,
    height: 56,
    viewBox: '0 0 56 56',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.934 4.667H30.6a1 1 0 01.707.293l1.807 1.807h-.01l.471.472 11.526 11.526v-.01l1.808 1.806a1 1 0 01.293.707V46.9a5.6 5.6 0 01-5.6 5.6H14.934a5.6 5.6 0 01-5.6-5.6V10.267a5.6 5.6 0 015.6-5.6zM45.102 20.94H32.874a1.4 1.4 0 01-1.4-1.4V6.767H14.934a3.5 3.5 0 00-3.5 3.5V46.9a3.5 3.5 0 003.5 3.5h26.668a3.5 3.5 0 003.5-3.5V20.941zm-11.527-2.1v-8.633l8.633 8.633h-8.633z" _fill="#1092DC"/><path pid="1" d="M15.81 37.833v-8.46h3.144c1.416 0 2.508.364 3.276 1.092.776.728 1.165 1.772 1.165 3.132 0 1.352-.389 2.396-1.165 3.132-.768.736-1.86 1.104-3.276 1.104H15.81zm1.536-1.272h1.512c1.953 0 2.928-.988 2.928-2.964 0-1.968-.976-2.952-2.928-2.952h-1.512v5.916zm11.157 1.392c-.816 0-1.532-.18-2.148-.54a3.692 3.692 0 01-1.416-1.512c-.336-.656-.504-1.424-.504-2.304 0-.888.168-1.656.504-2.304a3.612 3.612 0 011.416-1.5c.608-.36 1.324-.54 2.148-.54.824 0 1.54.18 2.148.54.608.352 1.08.852 1.416 1.5.336.648.504 1.412.504 2.292 0 .888-.168 1.66-.504 2.316a3.692 3.692 0 01-1.416 1.512c-.608.36-1.324.54-2.148.54zm0-1.32c.776 0 1.38-.268 1.812-.804.432-.536.648-1.28.648-2.232 0-.96-.216-1.704-.648-2.232-.424-.528-1.028-.792-1.812-.792-.776 0-1.38.264-1.812.792-.432.528-.648 1.272-.648 2.232 0 .952.216 1.696.648 2.232.432.536 1.036.804 1.812.804zm9.326 1.32c-.88 0-1.636-.176-2.268-.528a3.645 3.645 0 01-1.44-1.512c-.336-.656-.504-1.428-.504-2.316 0-.888.168-1.656.504-2.304a3.569 3.569 0 011.44-1.5c.632-.36 1.388-.54 2.268-.54.568 0 1.104.088 1.608.264.512.176.932.428 1.26.756l-.504 1.224a3.64 3.64 0 00-1.104-.66 3.36 3.36 0 00-1.212-.216c-.856 0-1.508.26-1.956.78-.448.512-.672 1.244-.672 2.196 0 .952.224 1.688.672 2.208.448.52 1.1.78 1.956.78.432 0 .836-.068 1.212-.204a3.861 3.861 0 001.104-.672l.504 1.224c-.328.32-.748.572-1.26.756a4.843 4.843 0 01-1.608.264z" _fill="#1092DC"/>'
  }
})
