/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cabinet/sign_in': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.75 3a.75.75 0 000 1.5H18v15h-5.25a.75.75 0 000 1.5H18a1.5 1.5 0 001.5-1.5v-15A1.5 1.5 0 0018 3h-5.25zM8.283 7.532a.75.75 0 011.061 0l3.937 3.938a.75.75 0 010 1.06l-3.937 3.938a.75.75 0 11-1.06-1.06l2.656-2.658H2.25a.75.75 0 010-1.5h8.69L8.283 8.593a.75.75 0 010-1.06z" _fill="#81899D"/>'
  }
})
