<template>
    <div class="news" v-if="news.list">
        <news-filter
            class="news__filter"
            :data="news.filter"
            @change-filter="changeFilter"
            @update-filter="updateFilter"
        />
        <div class="news__list" v-if="news.list && news.list.length">
            <div
                class="news__list-item news-item"
                v-for="(item, index) in news.list" :key="index"
            >
                <div class="news-item__date">
                    {{ item.activeFrom | momentFilter('DD MMMM YYYY') }}
                </div>
                <z-caption
                    class="news-item__title"
                    weight="600"
                    :href="item.detailPageUrl"
                >
                    <span v-html="item.name"></span>
                </z-caption>
            </div>
        </div>

        <p v-else>По вашему запросу новостей не&nbsp;найдено</p>

        <template v-if="isLoading">
            <z-preloader class="u-bottom-margin--xs" />
        </template>

        <z-pagination
            show-nav
            :show-more="{
                marginBottom: 'm',
                size: 'm'
            }"
            show-next
            show-prev
            :data="{
                currentPage: this.news.nav.current,
                totalItems: this.news.nav.count,
                totalPages: this.news.nav.total,
                pageRange: 1
            }"
            @change-page="onPageChange"
        />
    </div>
</template>

<script>
import NewsFilter from './components/NewsFilter.vue'
import { getNews } from '@/api/news'
import { throttle } from 'throttle-debounce'
import { showNotyfications } from '@/utils/notifications'

export default {
    name: 'news',
    components: {
        NewsFilter
    },
    data () {
        return {
            news: {
                list: this.$root.app.components.news.items,
                filter: this.$root.app.components.news.filters,
                nav: this.$root.app.components.news.nav
            },
            isLoading: false,
            params: {
                year: '',
                page: 1
            },
            requestType: ''
        }
    },
    methods: {
        changeFilter (params) {
            this.updateFilter(params)
            this.send()
        },
        updateFilter (params) {
            this.params = { ...params, page: 1 }
            this.requestType = 'change-page'
        },
        send: throttle(500, function () {
            this.isLoading = true

            getNews(this.params)
                .then(res => {
                    this.updateNav(res)
                    this.updateData(res)
                    this.isLoading = false
                })
                .catch((error) => {
                    showNotyfications(error, {type: 'error'})
                })
                .finally(() => {
                    this.isLoading = false
                })
        }),
        onPageChange: function (obj) {
            this.params.page = obj.page
            this.requestType = obj.type
            this.isLoading = true
            this.send()
        },
        updateNav (res) {
            if (res.hasOwnProperty('nav')) {
                this.news.nav.current = res.nav.current
                this.news.nav.total = res.nav.total
            }
        },
        updateData (res) {
            if (res.hasOwnProperty('items')) {
                if (this.requestType === 'change-page') {
                    this.news.list = res.items
                } else {
                    this.news.list = this.news.list.concat(res.items)
                }
            }
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
