<template>
    <div
        class="z-image"
        :class="{'z-image--background': background}"
        :style="backgroundStyles"
    >
        <img
            v-if="!background"
            :src="src"
            class="z-image__img"
            :style="imageStyles"
            :alt="alt"
        >

        <div
            v-if="overlay"
            class="z-image__overlay"
            :style="{background: overlay}"
        ></div>
    </div>
</template>

<script>
export default {
    name: 'z-image',
    props: {
        src: {
            type: String,
            required: true
        },
        overlay: String,
        background: {
            type: Boolean,
            default: false
        },
        width: String,
        height: String,
        alt: String
    },
    computed: {
        backgroundStyles () {
            return {
                width: this.background ? this.width : null,
                height: this.background ? this.height : null,
                'background-image': this.background ? `url(${this.src})` : null
            }
        },
        imageStyles () {
            return {
                width: !this.background ? this.width : null,
                height: !this.background ? this.height : null
            }
        }
    }
}
</script>

<style lang="scss">
.z-image {
    $parent: &;

    position: relative;
    display: inline-block;
    width: 100%;
    overflow: hidden;
    border-radius: 4px;

    &--background {
        display: block;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &__img {
        display: block;
        max-width: 100%;
        height: auto;
        border: 0;
    }

    &__overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        #{$parent}__img {
            display: block;
        }
    }
}
</style>
