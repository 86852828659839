<template>
    <div :class="classObject">
        <a
            @click="toggleItem($event)"
            :href="`#${itemId}`"
            class="z-accordion-item__header"
        >
            <span class="z-accordion-item__header-button">
                <span class="z-accordion-item__header-button--minus"></span>
                <span class="z-accordion-item__header-button--plus"></span>
            </span>
            <slot name="icon" />
            <slot name="header" />
        </a>
        <transition
            @enter="animationEnter"
            @leave="animationLeave"
        >
            <div v-show="state" class="z-accordion-item__body">
                <slot name="body" />
            </div>
        </transition>
    </div>
</template>

<script>
import Velocity from 'velocity-animate'

export default {
    name: 'z-accordion-item',
    props: {
        id: {
            type: String,
            default: '',
            required: true
        },
        parent: Boolean
    },
    data () {
        return {
            state: false,
            duration: 300,
            name: 'z-accordion-item',
            itemId: `accordion-${this.id}`,
            itemChildrens: this.$children,
            hash: window.location.hash
        }
    },
    methods: {
        toggleItem (e) {
            this.state = !this.state

            if (!this.state && e) e.preventDefault()

            this.$emit('toggle-item', {
                itemId: this.itemId,
                itemChildrens: this.itemChildrens
            })
        },
        animationEnter (el, done) {
            Velocity(el, 'slideDown', { duration: this.duration })
        },
        animationLeave (el, done) {
            Velocity(el, 'slideUp', { duration: this.duration })
        },
        changeHash () {
            this.hash = window.location.hash
            this.$nextTick(() => {
                this.checkHash()
            })
        },
        checkHash () {
            if (this.currentHash.length) {
                let matched = this.$children.filter(item => item.itemId === this.currentHash)
                if (matched.length) {
                    this.openItem()
                    this.scrollIntoView(matched[0].$el)
                }
            }
        },
        openItem () {
            this.$parent.openParent(this.$parent.$parent)
            this.state = true
        },
        scrollIntoView (el) {
            setTimeout(() => {
                el.scrollIntoView({behavior: 'smooth', block: 'start'})
            }, 301)
        }
    },
    computed: {
        currentHash () {
            return this.hash ? this.hash.split('#')[1] : ''
        },
        classObject () {
            let arrClass = [
                'z-accordion-item',
                {
                    'z-accordion-item--opened': this.state,
                    'z-accordion-item--parent': this.parent
                }
            ]

            return arrClass
        }
    },
    mounted () {
        this.changeHash()
        window.addEventListener('hashchange', this.changeHash)
        if (this.currentHash.length) {
            let matched = this.$children.filter(item => item.itemId === this.currentHash)
            if (matched.length) {
                this.toggleItem()
            }
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
