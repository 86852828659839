/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'social/linkedin': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" d="M12 13.859c0-.54.189-.984.566-1.334.377-.35.867-.525 1.47-.525.593 0 1.072.172 1.439.517.377.356.565.819.565 1.39 0 .517-.183.948-.55 1.293-.376.356-.872.533-1.486.533h-.016c-.593 0-1.072-.177-1.438-.533-.367-.356-.55-.803-.55-1.341zM12.21 28V17.204h3.588V28H12.21zm5.576 0h3.588v-6.028c0-.377.043-.668.129-.873.15-.366.38-.676.687-.93.307-.253.692-.38 1.155-.38 1.207 0 1.81.814 1.81 2.441V28h3.588v-6.19c0-1.595-.377-2.804-1.13-3.628-.755-.824-1.752-1.236-2.99-1.236-1.39 0-2.474.597-3.25 1.793v.033h-.015l.016-.033v-1.535h-3.588c.021.345.032 1.417.032 3.216 0 1.8-.01 4.326-.032 7.58z" _fill="#81899D"/>'
  }
})
