<template>
    <div class="water-calculator">
        <z-caption tag="h2" size="2xl">Расчет стоимости на&nbsp;подключение к&nbsp;системе холодного водоснабжения</z-caption>
        <div class="u-bottom-margin--s">
            <z-input type="number" title="Объем подключаемой нагрузки:" name="water" v-model="value" placeholder="куб.м./сутки"/>
            <span v-if="this.value > 5"><i>Калькулятор рассчитывает стоимость до 5 куб.м./сутки.</i></span>
        </div>
        <z-caption tag="h3" size="xl">Результат: {{result | numberFilter}} руб.</z-caption>
    </div>
</template>

<script>

export default {
    name: 'calculator-water-supply',
    data () {
        return {
            value: '',
            tax: 201228
        }
    },
    computed: {
        result () {
            const result = this.value > 5 ? 5 : this.value
            return result * this.tax
        }
    }
}
</script>

<style lang="scss">
.water-calculator {
    &__input-wrapper {
        display: flex;
    }
}
</style>
