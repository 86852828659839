/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'professionalism': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<g clip-path="url(#clip0_3611_15738)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M31.977 60.08V27.293h5.62v3.647a.857.857 0 00.094.474c.093.375.468.563.843.563h22.482c.375 0 .656-.188.843-.469a.851.851 0 000-.937l-6.276-9.836L61.86 10.9a.851.851 0 000-.936c-.187-.281-.468-.469-.843-.469H46.028v1.78H59.33l-5.62 8.9c-.188.374-.188.749 0 1.03l5.62 8.899H40.782l3.092-3.091a.85.85 0 00.28-.656V7.621c0-.562-.374-.937-.936-.937H31.977V2h-5.621v58.08h-7.494V39.47h3.747V21.673c0-2.623-2.061-4.684-4.684-4.684H6.684C4.06 16.988 2 19.05 2 21.672v17.799h3.747V60.08H2v1.873h59.953V60.08h-3.747V46.965h-5.62v-3.747H41.344v3.747h-5.62V60.08h-3.747zm11.24-13.115h7.495V45.09h-7.494v1.874zm13.116 1.873v3.747H37.597V48.84h18.736zm-8.431 5.621h8.43v5.62H37.598v-5.62h8.431v1.873h1.874V54.46zM39.47 28.791l1.498-1.498h-1.498v1.498zM30.103 60.08V3.874H28.23V60.08h1.873zm12.178-34.66H31.977V8.556H42.28V25.42zM7.621 60.08h3.747V39.47h1.873v20.61h3.748V37.597H7.62V60.08zm3.747-39.345h1.873v1.874h-1.873v-1.874zM7.62 35.724h9.367V24.482h1.874v13.115h1.873V21.672c0-1.592-1.217-2.81-2.81-2.81H6.684c-1.593 0-2.81 1.218-2.81 2.81v15.925h1.873V24.482h1.874v11.242zm4.683-20.61c-2.623 0-4.683-2.06-4.683-4.683 0-2.623 2.06-4.684 4.683-4.684 2.623 0 4.684 2.061 4.684 4.684s-2.06 4.684-4.684 4.684zm0-7.493c-1.592 0-2.81 1.217-2.81 2.81s1.218 2.81 2.81 2.81c1.593 0 2.81-1.217 2.81-2.81s-1.217-2.81-2.81-2.81zm39.344 12.178h-1.873v1.873h1.873V19.8zm-5.62 0h1.874v1.873h-1.874V19.8z" _fill="#0077C8"/></g><defs><clipPath id="clip0_3611_15738"><path pid="1" _fill="#fff" d="M0 0h64v64H0z"/></clipPath></defs>'
  }
})
