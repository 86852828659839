/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tools/magnifier': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.75 10.875a7.125 7.125 0 1114.25 0 7.125 7.125 0 01-14.25 0zm7.125-8.625a8.625 8.625 0 105.545 15.231l4.05 4.05a.75.75 0 001.06-1.061l-4.05-4.05a8.625 8.625 0 00-6.606-14.17z" _fill="#81899D"/>'
  }
})
