/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tools/play_big': {
    width: 123,
    height: 123,
    viewBox: '0 0 123 123',
    data: '<path pid="0" d="M101.101 56.316c3.973 2.314 3.973 8.054 0 10.368l-64.58 37.625c-4 2.331-9.021-.555-9.021-5.184v-75.25c0-4.63 5.02-7.515 9.02-5.184l64.581 37.625z" _fill="#81899D"/>'
  }
})
