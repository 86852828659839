var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"z-select-wrapper"},[_c('div',{class:[
            'z-select',
            {
                'z-select--filter': _vm.isFilter,
                'z-select--clearable': _vm.isClearable,
                'is-opened': _vm.opened,
                'is-errored': _vm.isValid === false,
                'is-valid': _vm.isValid && !_vm.isFilter,
                'is-filled': _vm.selected.text,
                'is-required': _vm.required,
                'is-disabled': !_vm.data && !_vm.data.length
            }
        ]},[_c('div',{staticClass:"z-select__container",attrs:{"tabindex":"0"},on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }_vm.opened = !_vm.opened}}},[(_vm.placeholder)?_c('span',{staticClass:"z-select__placeholder",domProps:{"innerHTML":_vm._s(_vm.placeholder)}}):_vm._e(),_vm._v(" "),(_vm.selected.text.length)?_c('span',{staticClass:"z-select__selected",domProps:{"innerHTML":_vm._s(_vm.selected.text)}}):_vm._e(),_vm._v(" "),(_vm.selected.text.length && _vm.isClearable)?_c('span',{staticClass:"z-select__clear",on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.removeSelected.apply(null, arguments)}}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"z-select__arrow"})]),_vm._v(" "),(_vm.opened)?_c('div',{staticClass:"z-select__dropdown"},_vm._l((_vm.groupedOptions),function(group,groupIndex){return _c('ul',{key:groupIndex,staticClass:"z-select__options"},[_c('li',[(group.name)?_c('span',{staticClass:"z-select__caption",domProps:{"innerHTML":_vm._s(group.name)}}):_vm._e(),_vm._v(" "),(group.items && group.items.length)?_c('ul',{class:{ 'u-left-margin--one' : groupIndex !== 'nogroup' }},_vm._l((group.items),function(option){return _c('li',{key:option.id,staticClass:"z-select__option",class:_vm.buildClass(option),on:{"click":function($event){return _vm.changeSelected(option)}}},[_c('vue-raw',{attrs:{"raw":option.text}})],1)}),0):_vm._e()])])}),0):_vm._e()]),_vm._v(" "),(_vm.required && _vm.error && !_vm.selected.id.length)?_c('span',{class:['z-select__error', _vm.errorClass]},[_vm._v("\n        "+_vm._s(_vm.text.error[_vm.$root.lang])+"\n    ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }