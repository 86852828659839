<template>
    <div
        :class="`social social--dir-${direction}`"
    >
        <a
            v-for="link in links"
            :key="link.icon"
            target="_blank"
            :href="link.link"
            :class="'social__link social__link--' + link.icon"
        >
            <z-icon
                theme="dark"
                width="100%"
                height="100%"
            ></z-icon>
            <span class="social__name" v-if="showLabels">{{ link.name }}</span>
        </a>
    </div>
</template>

<script>
export default {
    name: 'social',
    props: {
        links: Array,
        direction: {
            type: String,
            default: 'horizontal'
        },
        showCaption: Boolean,
        showLabels: Boolean
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
