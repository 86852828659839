/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/big/rar': {
    width: 56,
    height: 56,
    viewBox: '0 0 56 56',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.934 4.667H30.6a1 1 0 01.707.293l1.807 1.807h-.01l.471.472 11.526 11.526v-.01l1.808 1.806a1 1 0 01.293.707V46.9a5.6 5.6 0 01-5.6 5.6H14.934a5.6 5.6 0 01-5.6-5.6V10.267a5.6 5.6 0 015.6-5.6zM45.102 20.94H32.874a1.4 1.4 0 01-1.4-1.4V6.767H14.934a3.5 3.5 0 00-3.5 3.5V46.9a3.5 3.5 0 003.5 3.5h26.668a3.5 3.5 0 003.5-3.5V20.941zm-11.527-2.1v-8.633l8.633 8.633h-8.633z" _fill="#1092DC"/><path pid="1" d="M16.549 37.833v-8.46h3.672c.928 0 1.644.224 2.148.672.504.44.756 1.06.756 1.86 0 .632-.164 1.156-.492 1.572-.328.408-.8.684-1.416.828.408.128.744.436 1.008.924l1.416 2.604h-1.692l-1.464-2.7c-.144-.264-.316-.444-.516-.54-.192-.096-.44-.144-.744-.144h-1.14v3.384h-1.536zm1.536-4.524h1.872c1.12 0 1.68-.456 1.68-1.368 0-.904-.56-1.356-1.68-1.356h-1.872v2.724zm5.386 4.524l3.828-8.46h1.26l3.828 8.46h-1.56l-.84-1.956h-4.128l-.828 1.956h-1.56zm4.44-6.792l-1.524 3.612h3.072l-1.524-3.612h-.024zm5.162 6.792v-8.46h3.672c.928 0 1.644.224 2.148.672.504.44.756 1.06.756 1.86 0 .632-.164 1.156-.492 1.572-.328.408-.8.684-1.416.828.408.128.744.436 1.008.924l1.416 2.604h-1.692l-1.464-2.7c-.144-.264-.316-.444-.516-.54-.192-.096-.44-.144-.744-.144h-1.14v3.384h-1.536zm1.536-4.524h1.872c1.12 0 1.68-.456 1.68-1.368 0-.904-.56-1.356-1.68-1.356h-1.872v2.724z" _fill="#1092DC"/>'
  }
})
