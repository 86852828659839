export const historyData = [
    {
        year: 2022,
        blockquote: {
            name: 'Липин Сергей Валерьевич',
            text: 'Энергетика всегда была и&nbsp;остается основой экономики, она помогает добывать руду и&nbsp;выплавлять металл&nbsp;&mdash; стабильная работа предприятий &laquo;Норильского никеля&raquo; в&nbsp;значительной мере зависит от&nbsp;бесперебойной работы энергетической отрасли компании. Энергетика наполняет жизненной силой промышленные предприятия и&nbsp;весь город: его дома, больницы, школы, детские сады. Трудом энергетиков освещается каждый, даже самый отдаленный уголок Норильска. Этим светом наполнены улицы города, и&nbsp;оттого полярная ночь уже не&nbsp;кажется такой темной. Наша работа очень ответственна и&nbsp;требует высочайшего профессионализма, строгой дисциплины, а&nbsp;порой и&nbsp;самоотдачи. Всеми этими качествами коллектив &laquo;Норильско-Таймырской энергетической компании&raquo; обладает в&nbsp;полной мере.',
            info: 'Генеральный директор АО&nbsp;&laquo;НТЭК&raquo;'
        },
        items: [
            {
                date: '11 апреля',
                content: 'На&nbsp;водозаборе &#8470;&nbsp;1&nbsp;реки Норильской заменили старое насосное оборудование.'
            }
        ]
    },
    {
        year: 2021,
        goals: [
            'Специалистами экспертно-аналитического центра по&nbsp;проблемам окружающей среды &laquo;Экотерра&raquo; начата работа над проектом рекультивации земель после разлива топлива на&nbsp;ТЭЦ-3.',
            'На&nbsp;Усть-Хантайской ГЭС запустили в&nbsp;работу седьмой гидроагрегат. Модернизация всех семи машин шла поэтапно, в&nbsp;течение семи лет, а&nbsp;стоимость работ составила 7,5 миллиарда рублей.'
        ],
        items: [
            {
                date: '22 декабря',
                content: 'Проведена дублирующая линия прямой ветки теплосети в&nbsp;районе ТЭЦ-2&nbsp;С привлечением специализированных строительно-монтажных организаций заменили&nbsp;60% наиболее категорийных участков трубопровода от&nbsp;ТЭЦ-2 до&nbsp;основной площадки рудника &laquo;Октябрьский&raquo;.'
            },
            {
                date: '11 марта',
                content: 'АО&nbsp;&laquo;НТЭК&raquo; и&nbsp;правительство Красноярского края подписали соглашение о&nbsp;восстановлении природы, стороны пришли к&nbsp;выводу, что наилучший способ восстановления&nbsp;&mdash; возмещение вреда в&nbsp;натуральной форме. Компания взяла на&nbsp;себя проведение компенсационных мероприятий.'
            },
            {
                date: '2 марта',
                content: 'Специалисты ООО &laquo;Нефтетанк&raquo; завершили комплекс работ по&nbsp;возведению временных полевых складов для хранения ГСМ на&nbsp;УстьХантайской и&nbsp;Курейской ГЭС.'
            }
        ]
    },
    {
        year: 2020,
        goals: [
            'На&nbsp;Усть-Хантайской ГЭС запустили шестой гидроагрегат. На&nbsp;реконструкцию шести гидроагрегатов потратили более шести миллиардов рублей.'
        ],
        blockquote: {
            name: 'Липин Сергей Валерьевич',
            text: 'Мы&nbsp;действительно очень гордимся своими сотрудниками. Работники АО&nbsp;&laquo;НТЭК&raquo; награждались государственными наградами Российской Федерации: звания &laquo;Заслуженный энергетик&nbsp;РФ&raquo;, медалью &laquo;За&nbsp;заслуги перед Отечеством&raquo; I&nbsp;и&nbsp;II&nbsp;степени. Многие из&nbsp;них внесли огромный вклад в&nbsp;развитие норильской энергетики.',
            info: 'Генеральный директор АО&nbsp;&laquo;НТЭК&raquo;'
        },
        items: [
            {
                date: '23 ноября',
                content: 'Для рудников Талнахского района заменили деревянные опоры линий электроснабжения на&nbsp;металлические. По&nbsp;проекту на&nbsp;трассе протяженностью в&nbsp;21&nbsp;километр установили 101 металлическую решетчатую опору взамен прежних.'
            },
            {
                date: '22 сентября',
                content: 'АО&nbsp;&laquo;НТЭК&raquo; завершила реализацию проекта по&nbsp;созданию на&nbsp;ПТЭС г. Дудинка резервного источника электроснабжения мощностью 3МВт. Новый источник предназначен для бесперебойной работы котельной &#8470; 7&nbsp;даже в&nbsp;случае аварийного прекращения электроснабжения.'
            },
            {
                date: '8 сентября',
                content: 'На&nbsp;совещании с&nbsp;участием руководителя Главного управления МЧС России по&nbsp;Красноярскому краю Игоря Лисица приняли решение о&nbsp;завершении всех аварийно-спасательных и&nbsp;других неотложных работ на&nbsp;реке Амбарной и&nbsp;в&nbsp;районе ТЭЦ-3.'
            },
            {
                date: '3 августа',
                content: 'Произведен демонтаж аварийного резервуара на&nbsp;ТЭЦ-3.'
            },
            {
                date: '3 июня',
                content: 'На&nbsp;территории Красноярского края из-за разлива топлива объявлена черезвычайная ситуация федерального масштаба.'
            },
            {
                date: '29 мая',
                content: 'На&nbsp;ТЭЦ-3 произошел разлив топлива, было загрязнено более 180&nbsp;тыс.&nbsp;м&sup2;. Из&nbsp;резервуара вылились более 21&nbsp;тысячи тонн дизельного топлива и&nbsp;попали в&nbsp;реки Амбарная и&nbsp;Далдыкан.'
            }
        ]
    },
    {
        year: 2019,
        goals: [
            'На&nbsp;Усть-Хантайской ГЭС запущен пятый по&nbsp;счету гидроагрегат.'
        ],
        blockquote: {
            name: 'Липин Сергей Валерьевич',
            text: 'Ключевые ремонты основного энергетического оборудования, которые важны при подготовке к&nbsp;отопительному сезону 2018-2019, пройдут на&nbsp;генерирующих предприятиях&nbsp;&mdash; ТЭЦ-1, 2, 3, сетевых предприятиях УТВС. Так, например, на&nbsp;ТЭЦ-3 капитально отремонтируют турбоагрегат &#8470; 3, на&nbsp;ТЭЦ-2 также &laquo;откапиталят&raquo; котлоагреат &#8470; 8. Проведут текущие ремонты турбоагрегатов и&nbsp;теплофикационного оборудования (подогреватели, трубопроводы, насосные агрегаты, запорно-регулирующая арматура) на&nbsp;всех электростанциях. Например, на&nbsp;ТЭЦ-1 уже мае приступили к&nbsp;ремонту теплофикационного оборудования, обеспечивающего Норильск теплом.',
            info: 'Генеральный директор АО&nbsp;&laquo;НТЭК&raquo;'
        }
    },
    {
        year: 2018,
        goals: [
            'На&nbsp;Усть-Хантайской ГЭС запущен четвертый по&nbsp;счету гидроагрегат.'
        ],
        items: [
            {
                date: '22 июня',
                content: 'В&nbsp;ходе летней кампании заменено 4,1&nbsp;км магистральных трубопроводов тепловых сетей и&nbsp;2,3&nbsp;км сетей водоснабжения.'
            },
            {
                date: '23 мая',
                content: 'На&nbsp;ТЭЦ-3 &laquo;Норильско-Таймырской энергетической компании&raquo; завершился капитальный ремонт турбоагрегата &#8470; 1. Проектная мощность теплоэлектроцентрали&nbsp;&mdash; 580&nbsp;мВт, установленная тепловая мощность&nbsp;&mdash; 1049&nbsp;Гкал.'
            }
        ]
    },
    {
        year: 2017,
        goals: [
            'Запустили третий замененный гидроагрегат на&nbsp;УстьХантайской ГЭС.',
            'Выработка электроэнергии&nbsp;&mdash; 6&nbsp;036&nbsp;млн. кВт/ч.',
            'Выработка теплоэнергия&nbsp;&mdash; 8&nbsp;969&nbsp;тыс. Гкал.'
        ],
        items: [
            {
                date: '5 августа',
                content: 'Генеральным директором АО&nbsp;&laquo;НТЭК&raquo; назначен Липин Сергей Валерьевич сроком на&nbsp;два года (решение единственного акционера АО&nbsp;&laquo;НТЭК&raquo;&nbsp;&mdash; ПАО &laquo;ГМК &laquo;Норильский никель&raquo; от&nbsp;24.07.2017 &#8470; 31).'
            },
            {
                date: '17 апреля',
                content: '&laquo;Норникель&raquo; и&nbsp;&laquo;Силовые машины&raquo; подписали договор на&nbsp;реконструкцию под ключ двух энергоблоков Норильской ТЭЦ-2, предусматривающий полную замену основного и&nbsp;вспомогательного оборудования.'
            }
        ]
    },
    {
        year: 2016,
        goals: [
            'Выработка электроэнергии&nbsp;&mdash; 6&nbsp;693&nbsp;млн. кВт/ч.',
            'Выработка теплоэнергия&nbsp;&mdash; 9&nbsp;672&nbsp;тыс. Гкал.'
        ],
        items: [
            {
                date: '17 ноября',
                content: 'Второй замененный гидроагрегат запустили на&nbsp;УстьХантайской ГЭС. Таким образом, завершили очередной этап реализации инвестиционного проекта по&nbsp;модернизации гидроэлектростанции, одного из&nbsp;самых масштабных в&nbsp;российской энергетике.'
            },
            {
                date: '31 октября',
                content: 'При поддержке гидростроителей Курейской ГЭС, Администрации поселка, спонсоров, епархии и&nbsp;приходы был построен храм в&nbsp;поселке Светлогорск.'
            }
        ]
    },
    {
        year: 2015,
        goals: [
            'Курейская ГЭС произвела 2&nbsp;млрд.&nbsp;700 млн киловатт-часов, не&nbsp;допустив при этом аварий, инцидентов с&nbsp;признаками аварий и&nbsp;несчастных случаев на&nbsp;производстве.',
            'Принято советом директоров &laquo;Норильского никеля&raquo; стратегии развития топливно-энергетического комплекса компании. Стратегия определила перспективы работы и&nbsp;АО&nbsp;&laquo;НТЭК&raquo; до&nbsp;2025&nbsp;года.',
            'На&nbsp;Усть-Хантайской ГЭС&nbsp;&mdash; начались комплексные испытания нового гидрогенератора. Его замена стала возможной в&nbsp;результате успешной реализации первого этапа масштабного проекта по&nbsp;модернизации ГЭС.',
            'Выработка электроэнергии&nbsp;&mdash; 7&nbsp;572&nbsp;млн. кВт/ч.',
            'Выработка теплоэнергия&nbsp;&mdash; 9&nbsp;752&nbsp;тыс. Гкал.'
        ],
        blockquote: {
            name: 'Липин Сергей Валерьевич',
            text: 'В&nbsp;этом году у&nbsp;нашего предприятия было много праздничных событий, юбилейных дат,&nbsp;&mdash; отметил Сергей Липин. &mdash;&nbsp;Это 75&nbsp;лет управлению &laquo;Высоковольтные сети&raquo;, 55&nbsp;лет предприятию тепловых электрических сетей Дудинки, 45&nbsp;лет со&nbsp;дня запуска первого гидроагрегата на&nbsp;Хантайской ГЭС и&nbsp;35&nbsp;лет со&nbsp;дня ввода в&nbsp;эксплуатацию ТЭЦ-3. Кроме того, в&nbsp;2015-м была еще очень важная для нас дата&nbsp;&mdash; в&nbsp;октябре исполнилось 10&nbsp;лет со&nbsp;дня создания Норильско-Таймырской энергетической компании. Тогда, в&nbsp;2005-м, это был беспрецедентный случай, когда государственное предприятие РАО &laquo;ЕЭС&raquo; и&nbsp;частное &laquo;Норильский никель&raquo; создали совместное предприятие. Как показал опыт 10&nbsp;лет, решение было правильным. В&nbsp;отличие от&nbsp;многих регионов материка Норильск сохранил целую, единую энергосистему. Сегодня предприятие обеспечивает и&nbsp;производство, и&nbsp;транспортировку, и&nbsp;сбыт производимых ресурсов, и&nbsp;управление этими ресурсами, обеспечивая энергией двух главных потребителей&nbsp;&mdash; Заполярный филиал и&nbsp;город.',
            info: 'Генеральный директор АО&nbsp;&laquo;НТЭК&raquo;'
        },
        items: [
            {
                date: '25 декабря',
                content: 'На&nbsp;ТЭЦ-1 запущен новый турбогенератор&nbsp;&mdash; важная составляющая проекта реконструкции теплоэлектроцентрали &#8470; 1, который в&nbsp;целом обеспечил энергосистеме Центрального района Норильска дополнительно 30&nbsp;мегаватт.'
            },
            {
                date: 'В ноябре',
                content: 'Проведена большая работа на&nbsp;подстанции УВВС &laquo;Опорная&raquo;&nbsp;&mdash; в&nbsp;рамках модернизации был установлен четвертый автотрансформатор. Также близилось к&nbsp;окончанию реконструкция системы противоаварийной автоматики, что позволит увеличить прием энергии с&nbsp;ГЭС на&nbsp;100&nbsp;мегаватт.'
            },
            {
                date: '20 ноября',
                content: 'на&nbsp;Усть-Хантайской ГЭС был запущен новый, современный гидроагрегат&nbsp;&mdash; станционный &#8470; 4. Он&nbsp;заменил старое оборудование, которое отработало 45&nbsp;лет. Запустили агрегата в&nbsp;рамках масштабной программы модернизации гидроэлектростанции. По&nbsp;итогам реконструкции мощность ГЭС увеличится на&nbsp;50&nbsp;мегаватт.'
            },
            {
                date: '13 ноября',
                content: 'Подписан Коллективный договор между &laquo;Норникелем&raquo; и&nbsp;работниками компании на&nbsp;2018&ndash;2021&nbsp;годы. В&nbsp;АО&nbsp;&laquo;НТЭК&raquo; подписи под документом, регулирующим социально-трудовые отношения между компанией и&nbsp;ее&nbsp;сотрудниками, поставили: со&nbsp;стороны руководства НТЭК&nbsp;&mdash; генеральный директор Сергей Липин, со&nbsp;стороны работников&nbsp;&mdash; председатель совета трудового коллектива Ольга Каленова. Наличие Коллективного договора стало признаком предприятия с&nbsp;прозрачными и&nbsp;понятными правилами работы в&nbsp;трудовой сфере, предприятия, заботящегося о&nbsp;своем кадровом потенциале. Это подтвердило статус АО&nbsp;&laquo;НТЭК&raquo; как социально ответственной компании, считающей сотрудников своим главным капиталом&quot;.'
            },
            {
                date: '15 октября',
                content: 'Двое работников ПТЭС г. Дудинка удостоены государственных наград. Александру Данилову, электромонтеру оперативно-выездной бригады, присвоено звание &laquo;Заслуженный энергетик Российской Федерации&raquo;. Людмила Таранец, начальник отдела компании &laquo;Таймырбыт&raquo;, поощрена благодарностью президента&nbsp;РФ.'
            },
            {
                date: '1 октября',
                content: 'АО&nbsp;&laquo;НТЭК&raquo; исполнилось 10&nbsp;лет с&nbsp;момента создания. За&nbsp;10&nbsp;лет Компания произвела 100&nbsp;млрд. кВт*ч электроэнергии и&nbsp;125&nbsp;млн. Гкал тепла.'
            },
            {
                date: '4 августа',
                content: 'Генеральным директором АО&nbsp;&laquo;НТЭК&raquo; назначен Липин Сергей Валерьевич (решение единственного акционера АО&nbsp;&laquo;НТЭК&raquo;&nbsp;&mdash; ПАО &laquo;ГМК &laquo;Норильский никель&raquo; от&nbsp;23.07.2015 &#8470; 23).'
            },
            {
                date: '27 февраля',
                content: 'В&nbsp;районе Талнахской обогатительной фабрики Заполярного филиала ПАО &laquo;ГМК &laquo;Норильский никель&raquo; УТВС провела работы по&nbsp;строительству теплотрассы протяженностью около 4&nbsp;км.'
            }
        ]
    },
    {
        year: 2014,
        goals: [
            'Выработка электроэнергии&nbsp;&mdash; 7&nbsp;773&nbsp;млн. кВт/ч.',
            'Выработка теплоэнергия&nbsp;&mdash; 9&nbsp;961&nbsp;тыс. Гкал.'
        ],
        items: [
            {
                date: '28 сентября',
                content: 'АО &laquo;Норильско-Таймырская энергетическая компания&raquo; первым в&nbsp;России получило паспорт готовности к&nbsp;работе в&nbsp;отопительный сезон 2014&ndash;2015&nbsp;гг. Документ выдан под номером 01-2014 и&nbsp;подписан заместителем министра энергетики РФ&nbsp;Андреем Черезовым.'
            }
        ]
    },
    {
        year: 2013,
        goals: [
            'Выработка электроэнергии&nbsp;&mdash; 7&nbsp;737&nbsp;млн. кВт/ч.',
            'Выработка теплоэнергия&nbsp;&mdash; 10&nbsp;047&nbsp;тыс. Гкал..'
        ],
        blockquote: {
            name: 'Егоров Андрей Юрьевич',
            text: 'Опыт, который приобрели компания &laquo;Норильский никель&raquo; и&nbsp;топливно-энергетический комплекс, бесценный! &mdash;&nbsp;Необычные погодные условия, в&nbsp;которых оказался Норильский промрайон в&nbsp;2012/13&nbsp;году. Малоснежная зима, засушливое лето-2013 привели к&nbsp;очень непростой ситуации в&nbsp;энергообеспечении НПР. Мы&nbsp;потеряли уровни на&nbsp;реке Норильской, которая является основным источником воды для всего промышленного района, и&nbsp;на&nbsp;каскаде Курейской и&nbsp;Хантайской ГЭС. Вследствие чего повысилось использование газа норильскими ТЭЦ.',
            info: 'Генеральный директор АО&nbsp;&laquo;НТЭК&raquo;'
        },
        items: [
            {
                date: '1 августа',
                content: 'Генеральным директором АО&nbsp;&laquo;НТЭК&raquo; назначен Егоров Андрей Юрьевич (протокол заседания Совета Директоров от&nbsp;10.06.2013&nbsp;г. &#8470; 12).'
            },
            {
                date: '14 февраля',
                content: 'Инженер АО &laquo;НТЭК&raquo; принял участие в&nbsp;Х&nbsp;Красноярском экономическом форуме и&nbsp;одержал победу в&nbsp;заочном конкурсе эссе, на&nbsp;площадке &laquo;Поколение-2020&raquo;, тема которой была &laquo;Открытое будущее&nbsp;&mdash; как управлять развитием?&raquo;.'
            }
        ]
    },
    {
        year: 2012,
        goals: [
            'Выработка электроэнергии&nbsp;&mdash; 7&nbsp;606&nbsp;млн. кВт/ч.',
            'Выработка теплоэнергия&nbsp;&mdash; 10&nbsp;298&nbsp;тыс. Гкал.',
            'На&nbsp;проведение природоохранных мероприятий в&nbsp;сфере воздухоохранной деятельности израсходовано 1 397,53&nbsp;тыс. руб.'
        ],
        items: [
            {
                date: '7 декабря',
                content: 'Структурному подразделению АО &laquo;Норильско-Таймырская энергетическая компания&raquo; ТЭЦ-1 исполнилось 70&nbsp;лет.&nbsp;11&nbsp;работников теплоэлектроцентрали &#8470;&nbsp;1&nbsp;получили министерские награды.'
            },
            {
                date: '20 декабря',
                content: '236 сотрудников АО &laquo;Норильско-Таймырская энергетическая компания&raquo; получили награды к&nbsp;профессиональному празднику. Почетные звания &laquo;Заслуженный работник АО &laquo;НТЭК&raquo;, &laquo;Ветеран труда АО &laquo;НТЭК&raquo;, &laquo;Кадровый работник АО &laquo;НТЭК&raquo; удостоились 13, 20&nbsp;и&nbsp;38&nbsp;человек соответственно.'
            },
            {
                date: '22 ноября',
                content: 'Подписан договор с&nbsp;российским подрядчиком ОАО &laquo;Тяжмаш&raquo; на&nbsp;выполнение комплекса работ &laquo;под ключ&raquo; по&nbsp;реконструкции Усть-Хантайской ГЭС'
            }
        ]
    },
    {
        year: 2011,
        goals: [
            'Выработка электроэнергии&nbsp;&mdash; 7&nbsp;560&nbsp;млн. кВт/ч.',
            'Выработка теплоэнергия&nbsp;&mdash; 9&nbsp;786&nbsp;тыс. Гкал.',
            'ОАО &laquo;Авиакомпания &laquo;Таймыр&raquo; передала в&nbsp;собственность АО &laquo;НТЭК&raquo; сооружение ЛЭП 6кВ.',
            'Администрация поселка Светлогорск предоставила во&nbsp;временное владение и&nbsp;пользование: водоочистную станцию, здание центральной электрокотельной, Сети ТВС (протяженностью 2178,13&nbsp;метров).',
            'Сделка АО &laquo;НТЭК&raquo; с&nbsp;ОАО &laquo;АльфаСтрахование&raquo;, ООО СК&nbsp;&laquo;ВТБ Страхование&raquo;, ООО &laquo;Росгосстрах&raquo;.',
            'Сделка АО &laquo;НТЭК&raquo; с&nbsp;ОСАО &laquo;РЕСО-Гарантия&raquo;.',
            'Экономический показатель АО &laquo;НТЭК&raquo; вырос с&nbsp;1,7&nbsp;млн. рублей до&nbsp;8,7&nbsp;млн. рублей. Данный эффект был достигнут благодаря усилиям более 100 работников Компании, которые подавали свои рацпредложений. Из&nbsp;них внедрены 70&nbsp;предложений, что составило&nbsp;93% и&nbsp;значительно увеличило фактическую экономию от&nbsp;внедрения результатов интеллектуальной деятельности.'
        ]
    },
    {
        year: 2010,
        goals: [
            'Выработка электроэнергии&nbsp;&mdash; 7&nbsp;758&nbsp;млн. кВт/ч.',
            'Выработка теплоэнергия&nbsp;&mdash; 10&nbsp;748&nbsp;тыс. Гкал.'
        ],
        blockquote: {
            name: 'Клочко Игорь Петрович',
            text: 'Энергосистема Норильского промышленного района функционирует в&nbsp;условиях Крайнего Севера с&nbsp;особыми требованиями к&nbsp;обеспечению надежности энергоснабжения. Поэтому крайне важно применять опробованные и&nbsp;хорошо зарекомендовавшие себя в&nbsp;России и&nbsp;за&nbsp;рубежом технологии и&nbsp;оборудование. В&nbsp;планах ТЭОК внедрение в&nbsp;Норильском промышленном районе целого ряда апробированных современных технологий.',
            info: 'Генеральный директор АО&nbsp;&laquo;НТЭК&raquo;'
        },
        items: [
            {
                date: '12 октября',
                content: 'Генеральным директором АО &laquo;НТЭК&raquo; назначен Клочко Игорь Петрович (решение единственного акционера АО &laquo;НТЭК&raquo;&nbsp;&mdash; ОАО &laquo;ГМК &laquo;Норильский никель&raquo; от&nbsp;12.10.2010 &#8470; 31).'
            },
            {
                date: '27 апреля',
                content: 'Генеральным директором АО &laquo;НТЭК&raquo; назначен Егоров Андрей Юрьевич (решение единственного акционера АО &laquo;НТЭК&raquo;&nbsp;&mdash; ОАО &laquo;ГМК &laquo;Норильский никель&raquo;).'
            }
        ]
    },
    {
        year: 2009,
        goals: [
            'Выработка электроэнергии&nbsp;&mdash; 7&nbsp;511&nbsp;млн. кВт/ч.',
            'Выработка теплоэнергия&nbsp;&mdash; 10&nbsp;854&nbsp;тыс. Гкал.',
            'Произвелась массовая замена выключателей на&nbsp;главных электрических подстанциях, внедрились технологии, не&nbsp;требующие обслуживания.',
            'Строительство новой ЛЭП на&nbsp;металлических опорах Норильск&nbsp;&mdash; Дудинка.',
            'Сделка АО &laquo;НТЭК&raquo; с&nbsp;Акционерным коммерческим Сберегательным банком Российской Федерации.'
        ],
        items: [
            {
                date: '18 марта',
                content: 'Генеральным директором АО&nbsp;&laquo;НТЭК&raquo; назначен Сысоев Виктор Федорович (решение единственного акционера АО &laquo;НТЭК&raquo;&nbsp;&mdash; ОАО &laquo;ГМК &laquo;Норильский никель&raquo;).'
            }
        ]
    },
    {
        year: 2008,
        goals: [
            'Выработка электроэнергии&nbsp;&mdash; 7&nbsp;538&nbsp;млн. кВт/ч.',
            'Выработка теплоэнергия&nbsp;&mdash; 10&nbsp;725&nbsp;тыс. Гкал.',
            'АО &laquo;НТЭК&raquo; заменила изоляцию на&nbsp;40&nbsp;км труб и&nbsp;обновила почти 20&nbsp;км трубопроводов, что составило 12-ую часть всей Норильский сети.',
            'Курейская ГЭС провела модернизацию на&nbsp;открытом распределительном устройстве (ОРУ-220) с&nbsp;заменой высоковольтных масляных выключателей ВМТ-220&nbsp;на элегазовые 3АР1&nbsp;DT-245/ЕК. Так как оборудование ОРУ-220 введено в&nbsp;работу в&nbsp;1987 году и&nbsp;работало при температурах наружного воздуха от&nbsp;плюс 35&nbsp;до&nbsp;минус 55&nbsp;градусов, его замена была особенно актуальна.',
            'ТЭЦ-3 ввели в&nbsp;строй водогрейный котел.'
        ],
        blockquote: {
            name: 'Катасонов Виктор Иванович',
            text: 'На&nbsp;сегодняшний день АО &laquo;НТЭК&raquo;&nbsp;&mdash; это три тепловые и&nbsp;две гидроэлектростанции (Хантайская и&nbsp;Курейская), предприятия сетевого хозяйства, ТЭВК, который непосредственно обслуживает все коллекторное хозяйство города... Плюс вспомогательные предприятия. Наша задача&nbsp;&mdash; обеспечивать нормальную жизнедеятельность Норильска и&nbsp;Дудинки, промпредприятий района и&nbsp;предотвращать возникновение нештатных ситуаций.',
            info: 'Генеральный директор АО&nbsp;&laquo;НТЭК&raquo;'
        }
    },
    {
        year: 2007,
        goals: [
            'Выработка электроэнергии&nbsp;&mdash; 7&nbsp;511&nbsp;млн. кВт/ч.',
            'Выработка теплоэнергия&nbsp;&mdash; 10&nbsp;512&nbsp;тыс. Гкал.',
            'ТЭЦ-1 ввела в&nbsp;эксплуатацию&nbsp;ст. &#8470;&nbsp;20. Произвела работы по&nbsp;замене турбины&nbsp;ст. &#8470;&nbsp;1, по&nbsp;демонтажу котла&nbsp;ст. &#8470;&nbsp;12. Ввела в&nbsp;работу установку обратного осмоса для получения глубоко обессиленной воды, тем самым начата реконструкция оборудования химического цеха.',
            'Курейская ГЭС впервые с&nbsp;начала эксплуатации станция вышла на&nbsp;среднемноголетнюю проектную выработку электроэнергии 2,7&nbsp;млрд. кВт/ч, выработав 2&nbsp;741&nbsp;310&nbsp;936&nbsp;кВт/ч.',
            'Общество является крупнейшим налоплательщиком по&nbsp;Красноярскому краю.',
            'Выполнены работы по&nbsp;капитальному ремонту зданий и&nbsp;сооружений, трубопроводов и&nbsp;сетей ТВГСиК, линий электропередач на&nbsp;объектах АО &laquo;НТЭК&raquo;.',
            'Получена лицензия &#8470; ОТ-70-000200 (84) от&nbsp;22.03.2007&nbsp;г.&nbsp;на&nbsp;осуществление деятельности по&nbsp;сбору, использованию, обезвреживанию, транспортировке, размещению опасных отходов сроком действия 5&nbsp;лет.'
        ],
        items: [
            {
                date: '11 мая',
                content: 'ПАО &laquo;ГМК &laquo;Норильский никель&raquo; выкупило у&nbsp;РАО &laquo;ЕЭС России&raquo; оставшиеся&nbsp;49% акций АО &laquo;НТЭК&raquo;. Сумма сделки составила 26,924&nbsp;млн. рублей, после чего доля участия &laquo;Норильском никеля&raquo; в&nbsp;энергетической компании составила 100%.'
            }
        ]
    },
    {
        year: 2006,
        goals: [
            'Выработка электроэнергии&nbsp;&mdash; 7&nbsp;619&nbsp;млн. кВт/ч.',
            'Выработка теплоэнергия&nbsp;&mdash; 11 054,3&nbsp;тыс. Гкал.',
            'Специалисты АО &laquo;НТЭК&raquo; приняли участие в&nbsp;III Международной научно-практической конференции и&nbsp;специализировнной выставке &laquo;Экология в&nbsp;энергетике &minus;2006&raquo;, конференции ЭКОМОНИТОРИНГ-2006, семинаре-совещании &laquo;Проблемы охраны окружающей среды в&nbsp;металлургической промышленности&raquo;',
            'Специалисты АО &laquo;НТЭК&raquo; прошли обучение по&nbsp;112-часовой программе и&nbsp;получили сертификаты на&nbsp;право обращения с&nbsp;опасными отходами.'
        ],
        blockquote: {
            name: 'Басова Юлия Васильевна',
            text: 'По&nbsp;словам заместителя генерального директора ОАО &laquo;ГМК &laquo;Норильский никель&raquo; Басовой Юлии Васильевна, создание АО &laquo;НТЭК&raquo; позволит обеспечить энергобезопасность бизнеса &laquo;Норникеля&raquo;, в&nbsp;том числе, за&nbsp;счёт инвестиций в&nbsp;обновление мощностей ОАО &laquo;Таймырэнерго&raquo; и&nbsp;обеспечения бесперебойной работы энергосистемы региона.',
            info: 'Заместитель генерального директора ГМК &laquo;Норильский никель&raquo;'
        },
        items: [
            {
                date: '10 октября',
                content: 'В&nbsp;соответствии с&nbsp;приказом 21.06.2006&nbsp;г. &#8470;НТЭК/76-п &laquo;О&nbsp;структурных изменениях&raquo; образовано автотранспортное предприятие на&nbsp;производственно-технической базе Служб механизации и&nbsp;транспорта УВВС, УТВГС и&nbsp;автохозяйства РЭС-3.'
            },
            {
                date: '11 августа',
                content: 'Генеральным директором АО&nbsp;&laquo;НТЭК&raquo; назначен Катасов Виктор Иванович (решение единственного акционера АО &laquo;НТЭК&raquo;&nbsp;&mdash; ОАО &laquo;ГМК &laquo;Норильский никель&raquo;).'
            },
            {
                date: '26 мая',
                content: 'На&nbsp;очередном заседании Совета директоров РАО &laquo;ЕЭС России&raquo; были заслушаны и&nbsp;приняты к&nbsp;сведению отчеты о&nbsp;работе Комитета по&nbsp;аудиту и&nbsp;Комитета по&nbsp;оценке при Совете директоров за&nbsp;2005-2006&nbsp;гг. В&nbsp;частности, Комитет одобрил оценку стартовой цены продажи 100% акций ОАО &laquo;Таймырэнерго&raquo;.'
            },
            {
                date: '12 мая',
                content: '&laquo;Норильско-Таймырская энергетическая компания&raquo; в&nbsp;первый квартал выработала около 4&nbsp;млн. ГКал тепла и&nbsp;3&nbsp;млрд. кВт/ч электроэнергии.'
            }
        ]
    },
    {
        year: 2005,
        items: [
            {
                date: '23 декабря',
                content: 'В&nbsp;соответствии с&nbsp;решением Совета директоров РАО &laquo;ЕЭС России&raquo;, осуществляются запланированные процедуры по&nbsp;прекращению участия РАО &laquo;ЕЭС России&raquo; в&nbsp;ОАО &laquo;Таймырэнерго&raquo;. В&nbsp;частности, проведена работа по&nbsp;оценке рыночной стоимости акций ОАО &laquo;Таймырэнерго&raquo;, отчет об&nbsp;оценке согласован Комитетом по&nbsp;оценке при Совете директоров РАО &laquo;ЕЭС России&raquo; и&nbsp;Федеральным агентством по&nbsp;управлению федеральным имуществом.'
            },
            {
                date: '1 октября',
                content: 'АО &laquo;Норильско-Таймырская энергетическая компания&raquo; приступила к&nbsp;операционной деятельности. Для запуска выполнены все необходимые корпоративные процедуры. Все управление электростанциями Норильском энергоузла было передано АО &laquo;НТЭК&raquo;. Региональная энергетическая комиссия Красноярского края установила компании тарифы на&nbsp;тепловую и&nbsp;электрическую энергию на&nbsp;IV квартал 2005&nbsp;г.&nbsp;и&nbsp;на&nbsp;2006&nbsp;г.'
            },
            {
                date: '2 сентября',
                content: 'После рассмотрения советом директоров РАО &laquo;ЕЭС России&raquo; условий продажи акций ОАО &laquo;Таймырэнерго&raquo;, начинается постепенный процесс входа Курейской ГЭС и&nbsp;Усть-Хантайской ГЭС в&nbsp;состав АО &laquo;НТЭК&raquo;.'
            },
            {
                date: '15 июня',
                content: 'АО &laquo;НТЭК&raquo; создано в&nbsp;соответствии с&nbsp;Протоколом собрания Учредителей, было зарегистрировано в&nbsp;Инспекции ФНС России по&nbsp;г. Норильску Красноярского края 01.06.2005&nbsp;г. (свидетельство серии 24 &#8470;&nbsp;002407929). Основной государственный регистрационный номер&nbsp;&mdash; 1052457013476.'
            },
            {
                date: '18 мая',
                content: 'Первым генеральным директором предприятия по&nbsp;решению собрания учредителей был назначен Абрамов Евгений Юрьевич, перешедший с&nbsp;должности начальника Управления развития энергетического комплекса ОАО &laquo;ГМК &laquo;Норильский никель&raquo;.'
            },
            {
                date: '25 марта',
                content: 'Советом директоров РАО &laquo;ЕЭС России&raquo; одобрены передача в&nbsp;аренду АО &laquo;НТЭК&raquo; активов ОАО &laquo;Таймырэнерго&raquo;. Аналогичное решение было принято органами управления ПАО &laquo;ГМК &laquo;Норильский никель&raquo; в&nbsp;отношении филиала &laquo;ПО&nbsp;&laquo;Норильскэнерго&raquo;.'
            }
        ]
    }
]
