<template>
    <div class="purchase">
        <filters
            @search="onChange"
            :selects="filters"
        />
        <results
            v-if="list.length > 0"
            :data="list"
        />
        <z-pagination
            v-if="!loading && list.length > 0"
            show-next
            show-prev
            show-nav
            @change-page="onPageChange"
            :show-more="{
                marginBottom: 'm',
                size: 'm'
            }"
            :data="adaptedNav"
        >
        </z-pagination>
        <z-preloader v-if="loading" />
        <z-notification v-if="!loading && list.length === 0">
            <template v-slot:image>
                <z-image class="u-right-margin--s" src="/images/znotification/not-found.png" width="120" height="120"></z-image>
            </template>
            <template v-slot:description>
                <z-caption size="2xl" class="u-collapse--top">К&nbsp;сожалению, ничего не&nbsp;найдено</z-caption>
                <p class="text-size-xl u-normal">Воспользуйтесь фильтром еще раз или вернитесь&nbsp;на&nbsp;<z-link href="/">главную страницу</z-link>.</p>
            </template>
        </z-notification>
    </div>
</template>

<script>
import Filters from './Filters.vue'
import Results from './Results.vue'
import getPurchase from '@/api/purchase'
import { debounce } from 'throttle-debounce'
import { showNotyfications } from '@/utils/notifications'

export default {
    name: 'purchase',
    components: {
        Filters, Results
    },
    props: {
        initial: {
            type: Object,
            default: () => ({
                query: '',
                customer: '',
                date: ''
            })
        }
    },
    data () {
        return {
            loading: false,
            list: this.$root.app.components.purchase.items || [],
            nav: this.$root.app.components.purchase.nav || [],
            filters: this.$root.app.components.purchase.filters.customer || [],
            page: 1,
            params: this.initial,
            requestType: ''
        }
    },
    methods: {
        onChange (data) {
            this.page = 1
            this.params = { ...data, page: this.page }
            this.send(this.params)
        },
        send: debounce(1000, function () {
            this.loading = true

            getPurchase(this.params)
                .then(res => {
                    this.updateNav(res)
                    this.updateList(res)
                    this.loading = false
                })
                .catch((error) => {
                    showNotyfications(error, {type: 'error'})
                })
                .finally(() => {
                    this.loading = false
                })
        }),
        onPageChange (obj) {
            this.params.page = obj.page
            this.requestType = obj.type
            this.loading = true
            this.send()
        },
        updateNav (res) {
            if (res.hasOwnProperty('nav')) {
                this.nav.current = res.nav.current
                this.nav.total = res.nav.total
                this.nav.count = res.nav.count
            }
        },
        updateList (res) {
            if (res.hasOwnProperty('items')) {
                if (this.requestType === 'load-more') {
                    this.list = [...this.list, ...res.items]
                } else {
                    this.list = res.items
                }
            }
        }
    },
    computed: {
        adaptedNav () {
            return {
                currentPage: this.nav.current,
                totalItems: this.nav.count,
                totalPages: this.nav.total,
                pageRange: 1
            }
        }
    }
}
</script>

<style lang="scss">
.purchase {
    &-filters {
        background: $token-colors-black-5;
        padding: $token-spacers-xs;

        @include breakpoint(mobile) {
            padding: $token-spacers-3-xs;
        }

        .z-input__input, .z-select {
            background: $token-colors-white;
        }

        &__search {
            .z-input__input {
                padding-right: 32px;
            }

            .z-input__container {
                position: relative;
                display: flex;
                align-items: center;
            }

            .z-input__container:after {
                content: '';
                width: 20px;
                height: 20px;
                display: block;
                right: 10px;
                position: absolute;
                background-position: center;
                background-repeat: no-repeat;
                background-color: $token-colors-white;
                background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zLjI0OTc2IDkuMDYyNUMzLjI0OTc2IDUuODUyMzQgNS44NTIxIDMuMjUgOS4wNjIyNiAzLjI1QzEyLjI3MjQgMy4yNSAxNC44NzQ4IDUuODUyMzQgMTQuODc0OCA5LjA2MjVDMTQuODc0OCAxMi4yNzI3IDEyLjI3MjQgMTQuODc1IDkuMDYyMjYgMTQuODc1QzUuODUyMSAxNC44NzUgMy4yNDk3NiAxMi4yNzI3IDMuMjQ5NzYgOS4wNjI1Wk05LjA2MjI2IDEuNzVDNS4wMjM2NyAxLjc1IDEuNzQ5NzYgNS4wMjM5MiAxLjc0OTc2IDkuMDYyNUMxLjc0OTc2IDEzLjEwMTEgNS4wMjM2NyAxNi4zNzUgOS4wNjIyNiAxNi4zNzVDMTAuODExMyAxNi4zNzUgMTIuNDE2OSAxNS43NjA5IDEzLjY3NTMgMTQuNzM2N0wxNi45NjkgMTguMDMwNEMxNy4yNjE5IDE4LjMyMzMgMTcuNzM2OCAxOC4zMjMzIDE4LjAyOTcgMTguMDMwNEMxOC4zMjI2IDE3LjczNzUgMTguMzIyNiAxNy4yNjI2IDE4LjAyOTcgMTYuOTY5N0wxNC43MzYgMTMuNjc2QzE1Ljc2MDUgMTIuNDE3NiAxNi4zNzQ4IDEwLjgxMTggMTYuMzc0OCA5LjA2MjVDMTYuMzc0OCA1LjAyMzkyIDEzLjEwMDggMS43NSA5LjA2MjI2IDEuNzVaIiBmaWxsPSIjNTY1QzYwIi8+Cjwvc3ZnPgo=);
            }
        }
    }

    &-results {
        .z-caption--link:hover {
            text-decoration: underline;
        }

        &__item {
            border-top: 1px solid $token-colors-black-10;
            padding: $token-spacers-s 0;
        }
    }

    .z-notification {
        margin: 0 auto;
        width: fit-content;
    }

    .z-table-row--head {
        font-size: 14px;
        border-bottom: none;
    }

    .z-table-col--head {
        font-weight: 400 !important;
    }

    .z-table-row--head .z-table-col {
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .z-table-row--body .z-table-col {
        font-family: $monospaceFont;
        color: $token-colors-black-80;
        font-weight: 500;
        font-size: 16px;
        padding-top: 0;
        padding-bottom: 0;
    }

    .z-table-col {
        text-align: left !important;
        padding-left: 8px;
        padding-right: 8px;
        width: 18%;
    }

    .z-table-col:last-of-type {
        width: 28%;
    }

    .z-table-col:first-of-type {
        padding-left: 0;
    }

    .z-table-row--body .z-table-col:first-of-type {
        font-weight: 700;
    }
}
</style>
