/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/big/docx': {
    width: 56,
    height: 56,
    viewBox: '0 0 56 56',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.933 4.667H30.6a1 1 0 01.707.293l1.807 1.807h-.011l.472.472L45.1 18.765v-.01l1.807 1.806a1 1 0 01.293.707V46.9a5.6 5.6 0 01-5.6 5.6H14.933a5.6 5.6 0 01-5.6-5.6V10.267a5.6 5.6 0 015.6-5.6zM45.101 20.94H32.875a1.4 1.4 0 01-1.4-1.4V6.767H14.933a3.5 3.5 0 00-3.5 3.5V46.9a3.5 3.5 0 003.5 3.5h26.668a3.5 3.5 0 003.5-3.5V20.941zm-11.526-2.1v-8.633l8.633 8.633h-8.633z" _fill="#1092DC"/><path pid="1" d="M13.322 37.833v-7.755h2.881c1.299 0 2.3.334 3.003 1.001.712.668 1.067 1.625 1.067 2.871 0 1.24-.355 2.197-1.067 2.871-.703.675-1.704 1.012-3.003 1.012h-2.881zm1.408-1.166h1.386c1.789 0 2.683-.905 2.683-2.717 0-1.804-.894-2.706-2.683-2.706H14.73v5.423zm10.226 1.276c-.748 0-1.404-.165-1.969-.495a3.384 3.384 0 01-1.298-1.386c-.308-.601-.462-1.305-.462-2.112 0-.814.154-1.518.462-2.112a3.31 3.31 0 011.298-1.375c.558-.33 1.214-.495 1.97-.495.755 0 1.411.165 1.968.495.558.323.99.781 1.298 1.375.308.594.462 1.295.462 2.101 0 .814-.154 1.522-.462 2.123a3.383 3.383 0 01-1.298 1.386c-.557.33-1.213.495-1.969.495zm0-1.21c.712 0 1.265-.245 1.661-.737.396-.491.594-1.173.594-2.046 0-.88-.198-1.562-.594-2.046-.388-.484-.942-.726-1.66-.726-.712 0-1.266.242-1.662.726-.396.484-.594 1.166-.594 2.046 0 .873.198 1.555.594 2.046.396.492.95.737 1.661.737zm8.55 1.21c-.807 0-1.5-.161-2.08-.484a3.342 3.342 0 01-1.32-1.386c-.308-.601-.462-1.309-.462-2.123 0-.814.154-1.518.462-2.112a3.271 3.271 0 011.32-1.375c.58-.33 1.273-.495 2.08-.495.52 0 1.011.08 1.473.242.47.162.855.393 1.155.693l-.462 1.122a3.336 3.336 0 00-1.012-.605 3.08 3.08 0 00-1.11-.198c-.785 0-1.383.239-1.794.715-.41.47-.616 1.14-.616 2.013s.206 1.548.616 2.024c.41.477 1.008.715 1.793.715.396 0 .767-.062 1.111-.187a3.542 3.542 0 001.012-.616l.462 1.122c-.3.294-.685.525-1.155.693a4.44 4.44 0 01-1.474.242zm2.563-.11l2.959-3.949-2.85-3.806h1.64l2.013 2.783 2.013-2.783h1.65l-2.85 3.806 2.949 3.95h-1.65l-2.112-2.905-2.101 2.904h-1.661z" _fill="#1092DC"/>'
  }
})
