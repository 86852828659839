export const mockedData = {
    'top': [
        {
            'type': 'link',
            'name': 'Об университете',
            'link': '/about/',
            'level': 1,
            'parent': true,
            'permission': 'R',
            'params': [],
            'blank': false,
            'active': true,
            'pageParams': {
                'title': {
                    'full': 'Об университете',
                    'short': 'Об университете',
                    'show': true
                },
                'redirect': true,
                'showPage': true,
                'meta': {
                    'title': null,
                    'description': null
                },
                'hero': {
                    'enable': true,
                    'type': null,
                    'position': null,
                    'src': null,
                    'preview': null
                },
                'tools': {
                    'share': false,
                    'print': false,
                    'pdf': false
                },
                'type': 'wide',
                'breadcrumbs': true,
                'pageNav': false,
                'footer': null,
                'bodyClass': 'wide',
                'url': '/about/',
                'level': '1',
                'navigation-slider': {
                    'next': {
                        'text': null,
                        'link': null
                    },
                    'prev': {
                        'text': null,
                        'link': null
                    }
                }
            },
            'items': [
                {
                    'type': 'link',
                    'name': 'Тест',
                    'link': '/sveden/',
                    'level': 2,
                    'parent': true,
                    'permission': 'R',
                    'params': [],
                    'blank': false,
                    'active': false,
                    'pageParams': {
                        'title': {
                            'full': 'Тест',
                            'short': 'Тест',
                            'show': true
                        },
                        'redirect': null,
                        'showPage': true,
                        'meta': {
                            'title': null,
                            'description': null
                        },
                        'hero': {
                            'enable': true,
                            'type': null,
                            'position': null,
                            'src': null,
                            'preview': null
                        },
                        'tools': {
                            'share': false,
                            'print': false,
                            'pdf': false
                        },
                        'type': 'wide',
                        'breadcrumbs': true,
                        'pageNav': false,
                        'footer': null,
                        'bodyClass': 'wide',
                        'url': '/sveden/',
                        'level': '1',
                        'navigation-slider': {
                            'next': {
                                'text': null,
                                'link': null
                            },
                            'prev': {
                                'text': null,
                                'link': null
                            }
                        }
                    },
                    'items': [
                        {
                            'type': 'link',
                            'name': 'Основные сведения',
                            'link': '/sveden/common/',
                            'level': 3,
                            'parent': false,
                            'permission': 'R',
                            'params': [],
                            'blank': false,
                            'active': false,
                            'pageParams': {
                                'title': {
                                    'full': 'Основные сведения',
                                    'short': 'Основные сведения',
                                    'show': true
                                },
                                'redirect': null,
                                'showPage': true,
                                'meta': {
                                    'title': null,
                                    'description': null
                                },
                                'hero': {
                                    'enable': true,
                                    'type': null,
                                    'position': null,
                                    'src': null,
                                    'preview': null
                                },
                                'tools': {
                                    'share': false,
                                    'print': false,
                                    'pdf': false
                                },
                                'type': 'one-column',
                                'breadcrumbs': true,
                                'pageNav': false,
                                'footer': null,
                                'bodyClass': 'one-column',
                                'url': '/sveden/common/',
                                'level': '2',
                                'navigation-slider': {
                                    'next': {
                                        'text': null,
                                        'link': null
                                    },
                                    'prev': {
                                        'text': null,
                                        'link': null
                                    }
                                }
                            },
                            'items': [
                                {
                                    'type': 'link',
                                    'name': 'Структура и органы управления',
                                    'link': '/sveden/struct/',
                                    'level': 4,
                                    'parent': false,
                                    'permission': 'R',
                                    'params': [],
                                    'blank': false,
                                    'active': false,
                                    'pageParams': {
                                        'title': {
                                            'full': 'Структура и органы управления образовательной организацией',
                                            'short': 'Структура и органы управления',
                                            'show': true
                                        },
                                        'redirect': null,
                                        'showPage': true,
                                        'meta': {
                                            'title': null,
                                            'description': null
                                        },
                                        'hero': {
                                            'enable': true,
                                            'type': null,
                                            'position': null,
                                            'src': null,
                                            'preview': null
                                        },
                                        'tools': {
                                            'share': false,
                                            'print': false,
                                            'pdf': false
                                        },
                                        'type': 'one-column',
                                        'breadcrumbs': true,
                                        'pageNav': false,
                                        'footer': null,
                                        'bodyClass': 'one-column',
                                        'url': '/sveden/struct/',
                                        'level': '3',
                                        'navigation-slider': {
                                            'next': {
                                                'text': null,
                                                'link': null
                                            },
                                            'prev': {
                                                'text': null,
                                                'link': null
                                            }
                                        }
                                    },
                                    'hide': false
                                }
                            ],
                            'hide': false
                        },
                        {
                            'type': 'link',
                            'name': 'Структура и органы управления',
                            'link': '/sveden/struct/',
                            'level': 3,
                            'parent': false,
                            'permission': 'R',
                            'params': [],
                            'blank': false,
                            'active': false,
                            'pageParams': {
                                'title': {
                                    'full': 'Структура и органы управления образовательной организацией',
                                    'short': 'Структура и органы управления',
                                    'show': true
                                },
                                'redirect': null,
                                'showPage': true,
                                'meta': {
                                    'title': null,
                                    'description': null
                                },
                                'hero': {
                                    'enable': true,
                                    'type': null,
                                    'position': null,
                                    'src': null,
                                    'preview': null
                                },
                                'tools': {
                                    'share': false,
                                    'print': false,
                                    'pdf': false
                                },
                                'type': 'one-column',
                                'breadcrumbs': true,
                                'pageNav': false,
                                'footer': null,
                                'bodyClass': 'one-column',
                                'url': '/sveden/struct/',
                                'level': '2',
                                'navigation-slider': {
                                    'next': {
                                        'text': null,
                                        'link': null
                                    },
                                    'prev': {
                                        'text': null,
                                        'link': null
                                    }
                                }
                            },
                            'hide': false
                        },
                        {
                            'type': 'link',
                            'name': 'Документы',
                            'link': '/sveden/document/',
                            'level': 3,
                            'parent': false,
                            'permission': 'R',
                            'params': [],
                            'blank': false,
                            'active': false,
                            'pageParams': {
                                'title': {
                                    'full': 'Документы',
                                    'short': 'Документы',
                                    'show': true
                                },
                                'redirect': null,
                                'showPage': true,
                                'meta': {
                                    'title': null,
                                    'description': null
                                },
                                'hero': {
                                    'enable': true,
                                    'type': null,
                                    'position': null,
                                    'src': null,
                                    'preview': null
                                },
                                'tools': {
                                    'share': false,
                                    'print': false,
                                    'pdf': false
                                },
                                'type': 'one-column',
                                'breadcrumbs': true,
                                'pageNav': false,
                                'footer': null,
                                'bodyClass': 'one-column',
                                'url': '/sveden/document/',
                                'level': '2',
                                'navigation-slider': {
                                    'next': {
                                        'text': null,
                                        'link': null
                                    },
                                    'prev': {
                                        'text': null,
                                        'link': null
                                    }
                                }
                            },
                            'hide': false
                        },
                        {
                            'type': 'link',
                            'name': 'Образование',
                            'link': '/sveden/education/',
                            'level': 3,
                            'parent': false,
                            'permission': 'R',
                            'params': [],
                            'blank': false,
                            'active': false,
                            'pageParams': {
                                'title': {
                                    'full': 'Образование',
                                    'short': 'Образование',
                                    'show': true
                                },
                                'redirect': null,
                                'showPage': true,
                                'meta': {
                                    'title': null,
                                    'description': null
                                },
                                'hero': {
                                    'enable': true,
                                    'type': null,
                                    'position': null,
                                    'src': null,
                                    'preview': null
                                },
                                'tools': {
                                    'share': false,
                                    'print': false,
                                    'pdf': false
                                },
                                'type': 'one-column',
                                'breadcrumbs': true,
                                'pageNav': false,
                                'footer': null,
                                'bodyClass': 'one-column',
                                'url': '/sveden/education/',
                                'level': '2',
                                'navigation-slider': {
                                    'next': {
                                        'text': null,
                                        'link': null
                                    },
                                    'prev': {
                                        'text': null,
                                        'link': null
                                    }
                                }
                            },
                            'hide': false
                        },
                        {
                            'type': 'link',
                            'name': 'Образовательные стандарты и требования',
                            'link': '/sveden/eduStandarts/',
                            'level': 3,
                            'parent': false,
                            'permission': 'R',
                            'params': [],
                            'blank': false,
                            'active': false,
                            'pageParams': {
                                'title': {
                                    'full': 'Образовательные стандарты и требования',
                                    'short': 'Образовательные стандарты и требования',
                                    'show': true
                                },
                                'redirect': null,
                                'showPage': true,
                                'meta': {
                                    'title': null,
                                    'description': null
                                },
                                'hero': {
                                    'enable': true,
                                    'type': null,
                                    'position': null,
                                    'src': null,
                                    'preview': null
                                },
                                'tools': {
                                    'share': false,
                                    'print': false,
                                    'pdf': false
                                },
                                'type': 'one-column',
                                'breadcrumbs': true,
                                'pageNav': false,
                                'footer': null,
                                'bodyClass': 'one-column',
                                'url': '/sveden/eduStandarts/',
                                'level': '2',
                                'navigation-slider': {
                                    'next': {
                                        'text': null,
                                        'link': null
                                    },
                                    'prev': {
                                        'text': null,
                                        'link': null
                                    }
                                }
                            },
                            'hide': false
                        },
                        {
                            'type': 'link',
                            'name': 'Руководство и педагогический состав',
                            'link': '/sveden/employees/',
                            'level': 3,
                            'parent': false,
                            'permission': 'R',
                            'params': [],
                            'blank': false,
                            'active': false,
                            'pageParams': {
                                'title': {
                                    'full': 'Руководство и педагогический состав',
                                    'short': 'Руководство и педагогический состав',
                                    'show': true
                                },
                                'redirect': null,
                                'showPage': true,
                                'meta': {
                                    'title': null,
                                    'description': null
                                },
                                'hero': {
                                    'enable': true,
                                    'type': null,
                                    'position': null,
                                    'src': null,
                                    'preview': null
                                },
                                'tools': {
                                    'share': false,
                                    'print': false,
                                    'pdf': false
                                },
                                'type': 'one-column',
                                'breadcrumbs': true,
                                'pageNav': false,
                                'footer': null,
                                'bodyClass': 'one-column',
                                'url': '/sveden/employees/',
                                'level': '2',
                                'navigation-slider': {
                                    'next': {
                                        'text': null,
                                        'link': null
                                    },
                                    'prev': {
                                        'text': null,
                                        'link': null
                                    }
                                }
                            },
                            'hide': false
                        },
                        {
                            'type': 'link',
                            'name': 'Материально-техническое обеспечение',
                            'link': '/sveden/objects/',
                            'level': 3,
                            'parent': false,
                            'permission': 'R',
                            'params': [],
                            'blank': false,
                            'active': false,
                            'pageParams': {
                                'title': {
                                    'full': 'Материально-техническое обеспечение и оснащенность образовательного процесса',
                                    'short': 'Материально-техническое обеспечение',
                                    'show': true
                                },
                                'redirect': null,
                                'showPage': true,
                                'meta': {
                                    'title': null,
                                    'description': null
                                },
                                'hero': {
                                    'enable': true,
                                    'type': null,
                                    'position': null,
                                    'src': null,
                                    'preview': null
                                },
                                'tools': {
                                    'share': false,
                                    'print': false,
                                    'pdf': false
                                },
                                'type': 'one-column',
                                'breadcrumbs': true,
                                'pageNav': false,
                                'footer': null,
                                'bodyClass': 'one-column',
                                'url': '/sveden/objects/',
                                'level': '2',
                                'navigation-slider': {
                                    'next': {
                                        'text': null,
                                        'link': null
                                    },
                                    'prev': {
                                        'text': null,
                                        'link': null
                                    }
                                }
                            },
                            'hide': false
                        },
                        {
                            'type': 'link',
                            'name': 'Стипендии и меры поддержки обучающихся',
                            'link': '/sveden/grants/',
                            'level': 3,
                            'parent': false,
                            'permission': 'R',
                            'params': [],
                            'blank': false,
                            'active': false,
                            'pageParams': {
                                'title': {
                                    'full': 'Стипендии и меры поддержки обучающихся',
                                    'short': 'Стипендии и меры поддержки обучающихся',
                                    'show': true
                                },
                                'redirect': null,
                                'showPage': true,
                                'meta': {
                                    'title': null,
                                    'description': null
                                },
                                'hero': {
                                    'enable': true,
                                    'type': null,
                                    'position': null,
                                    'src': null,
                                    'preview': null
                                },
                                'tools': {
                                    'share': false,
                                    'print': false,
                                    'pdf': false
                                },
                                'type': 'one-column',
                                'breadcrumbs': true,
                                'pageNav': false,
                                'footer': null,
                                'bodyClass': 'one-column',
                                'url': '/sveden/grants/',
                                'level': '2',
                                'navigation-slider': {
                                    'next': {
                                        'text': null,
                                        'link': null
                                    },
                                    'prev': {
                                        'text': null,
                                        'link': null
                                    }
                                }
                            },
                            'hide': false
                        },
                        {
                            'type': 'link',
                            'name': 'Платные образовательные услуги',
                            'link': '/sveden/paid_edu/',
                            'level': 3,
                            'parent': false,
                            'permission': 'R',
                            'params': [],
                            'blank': false,
                            'active': false,
                            'pageParams': {
                                'title': {
                                    'full': 'Платные образовательные услуги',
                                    'short': 'Платные образовательные услуги',
                                    'show': true
                                },
                                'redirect': null,
                                'showPage': true,
                                'meta': {
                                    'title': null,
                                    'description': null
                                },
                                'hero': {
                                    'enable': true,
                                    'type': null,
                                    'position': null,
                                    'src': null,
                                    'preview': null
                                },
                                'tools': {
                                    'share': false,
                                    'print': false,
                                    'pdf': false
                                },
                                'type': 'one-column',
                                'breadcrumbs': true,
                                'pageNav': false,
                                'footer': null,
                                'bodyClass': 'one-column',
                                'url': '/sveden/paid_edu/',
                                'level': '2',
                                'navigation-slider': {
                                    'next': {
                                        'text': null,
                                        'link': null
                                    },
                                    'prev': {
                                        'text': null,
                                        'link': null
                                    }
                                }
                            },
                            'hide': false
                        },
                        {
                            'type': 'link',
                            'name': 'Финансово-хозяйственная деятельность',
                            'link': '/sveden/budget/',
                            'level': 3,
                            'parent': false,
                            'permission': 'R',
                            'params': [],
                            'blank': false,
                            'active': false,
                            'pageParams': {
                                'title': {
                                    'full': 'Финансово-хозяйственная деятельность',
                                    'short': 'Финансово-хозяйственная деятельность',
                                    'show': true
                                },
                                'redirect': null,
                                'showPage': true,
                                'meta': {
                                    'title': null,
                                    'description': null
                                },
                                'hero': {
                                    'enable': true,
                                    'type': null,
                                    'position': null,
                                    'src': null,
                                    'preview': null
                                },
                                'tools': {
                                    'share': false,
                                    'print': false,
                                    'pdf': false
                                },
                                'type': 'one-column',
                                'breadcrumbs': true,
                                'pageNav': false,
                                'footer': null,
                                'bodyClass': 'one-column',
                                'url': '/sveden/budget/',
                                'level': '2',
                                'navigation-slider': {
                                    'next': {
                                        'text': null,
                                        'link': null
                                    },
                                    'prev': {
                                        'text': null,
                                        'link': null
                                    }
                                }
                            },
                            'hide': false
                        },
                        {
                            'type': 'link',
                            'name': 'Вакантные места для приема обучающихся',
                            'link': '/sveden/vacant/',
                            'level': 3,
                            'parent': false,
                            'permission': 'R',
                            'params': [],
                            'blank': false,
                            'active': false,
                            'pageParams': {
                                'title': {
                                    'full': 'Вакантные места для приема (перевода) обучающихся',
                                    'short': 'Вакантные места для приема обучающихся',
                                    'show': true
                                },
                                'redirect': null,
                                'showPage': true,
                                'meta': {
                                    'title': null,
                                    'description': null
                                },
                                'hero': {
                                    'enable': true,
                                    'type': null,
                                    'position': null,
                                    'src': null,
                                    'preview': null
                                },
                                'tools': {
                                    'share': false,
                                    'print': false,
                                    'pdf': false
                                },
                                'type': 'one-column',
                                'breadcrumbs': true,
                                'pageNav': false,
                                'footer': null,
                                'bodyClass': 'one-column',
                                'url': '/sveden/vacant/',
                                'level': '2',
                                'navigation-slider': {
                                    'next': {
                                        'text': null,
                                        'link': null
                                    },
                                    'prev': {
                                        'text': null,
                                        'link': null
                                    }
                                }
                            },
                            'hide': false
                        },
                        {
                            'type': 'link',
                            'name': 'Доступная среда',
                            'link': '/sveden/ovz/',
                            'level': 3,
                            'parent': false,
                            'permission': 'R',
                            'params': [],
                            'blank': false,
                            'active': false,
                            'pageParams': {
                                'title': {
                                    'full': 'Доступная среда',
                                    'short': 'Доступная среда',
                                    'show': true
                                },
                                'redirect': null,
                                'showPage': true,
                                'meta': {
                                    'title': null,
                                    'description': null
                                },
                                'hero': {
                                    'enable': true,
                                    'type': null,
                                    'position': null,
                                    'src': null,
                                    'preview': null
                                },
                                'tools': {
                                    'share': false,
                                    'print': false,
                                    'pdf': false
                                },
                                'type': 'one-column',
                                'breadcrumbs': true,
                                'pageNav': false,
                                'footer': null,
                                'bodyClass': 'one-column',
                                'url': '/sveden/ovz/',
                                'level': '2',
                                'navigation-slider': {
                                    'next': {
                                        'text': null,
                                        'link': null
                                    },
                                    'prev': {
                                        'text': null,
                                        'link': null
                                    }
                                }
                            },
                            'hide': false
                        },
                        {
                            'type': 'link',
                            'name': 'Международное сотрудничество',
                            'link': '/sveden/inter/',
                            'level': 3,
                            'parent': false,
                            'permission': 'R',
                            'params': [],
                            'blank': false,
                            'active': false,
                            'pageParams': {
                                'title': {
                                    'full': 'Международное сотрудничество',
                                    'short': 'Международное сотрудничество',
                                    'show': true
                                },
                                'redirect': null,
                                'showPage': true,
                                'meta': {
                                    'title': null,
                                    'description': null
                                },
                                'hero': {
                                    'enable': true,
                                    'type': null,
                                    'position': null,
                                    'src': null,
                                    'preview': null
                                },
                                'tools': {
                                    'share': false,
                                    'print': false,
                                    'pdf': false
                                },
                                'type': 'one-column',
                                'breadcrumbs': true,
                                'pageNav': false,
                                'footer': null,
                                'bodyClass': 'one-column',
                                'url': '/sveden/inter/',
                                'level': '2',
                                'navigation-slider': {
                                    'next': {
                                        'text': null,
                                        'link': null
                                    },
                                    'prev': {
                                        'text': null,
                                        'link': null
                                    }
                                }
                            },
                            'hide': false
                        }
                    ],
                    'hide': false
                },
                {
                    'type': 'link',
                    'name': 'Сведения об образовательной организации',
                    'link': '/sveden/',
                    'level': 2,
                    'parent': true,
                    'permission': 'R',
                    'params': [],
                    'blank': false,
                    'active': false,
                    'pageParams': {
                        'title': {
                            'full': 'Сведения об образовательной организации',
                            'short': 'Сведения об образовательной организации',
                            'show': true
                        },
                        'redirect': null,
                        'showPage': true,
                        'meta': {
                            'title': null,
                            'description': null
                        },
                        'hero': {
                            'enable': true,
                            'type': null,
                            'position': null,
                            'src': null,
                            'preview': null
                        },
                        'tools': {
                            'share': false,
                            'print': false,
                            'pdf': false
                        },
                        'type': 'wide',
                        'breadcrumbs': true,
                        'pageNav': false,
                        'footer': null,
                        'bodyClass': 'wide',
                        'url': '/sveden/',
                        'level': '1',
                        'navigation-slider': {
                            'next': {
                                'text': null,
                                'link': null
                            },
                            'prev': {
                                'text': null,
                                'link': null
                            }
                        }
                    },
                    'items': [
                        {
                            'type': 'link',
                            'name': 'Основные сведения',
                            'link': '/sveden/common/',
                            'level': 3,
                            'parent': false,
                            'permission': 'R',
                            'params': [],
                            'blank': false,
                            'active': false,
                            'pageParams': {
                                'title': {
                                    'full': 'Основные сведения',
                                    'short': 'Основные сведения',
                                    'show': true
                                },
                                'redirect': null,
                                'showPage': true,
                                'meta': {
                                    'title': null,
                                    'description': null
                                },
                                'hero': {
                                    'enable': true,
                                    'type': null,
                                    'position': null,
                                    'src': null,
                                    'preview': null
                                },
                                'tools': {
                                    'share': false,
                                    'print': false,
                                    'pdf': false
                                },
                                'type': 'one-column',
                                'breadcrumbs': true,
                                'pageNav': false,
                                'footer': null,
                                'bodyClass': 'one-column',
                                'url': '/sveden/common/',
                                'level': '2',
                                'navigation-slider': {
                                    'next': {
                                        'text': null,
                                        'link': null
                                    },
                                    'prev': {
                                        'text': null,
                                        'link': null
                                    }
                                }
                            },
                            'hide': false
                        },
                        {
                            'type': 'link',
                            'name': 'Структура и органы управления',
                            'link': '/sveden/struct/',
                            'level': 3,
                            'parent': false,
                            'permission': 'R',
                            'params': [],
                            'blank': false,
                            'active': false,
                            'pageParams': {
                                'title': {
                                    'full': 'Структура и органы управления образовательной организацией',
                                    'short': 'Структура и органы управления',
                                    'show': true
                                },
                                'redirect': null,
                                'showPage': true,
                                'meta': {
                                    'title': null,
                                    'description': null
                                },
                                'hero': {
                                    'enable': true,
                                    'type': null,
                                    'position': null,
                                    'src': null,
                                    'preview': null
                                },
                                'tools': {
                                    'share': false,
                                    'print': false,
                                    'pdf': false
                                },
                                'type': 'one-column',
                                'breadcrumbs': true,
                                'pageNav': false,
                                'footer': null,
                                'bodyClass': 'one-column',
                                'url': '/sveden/struct/',
                                'level': '2',
                                'navigation-slider': {
                                    'next': {
                                        'text': null,
                                        'link': null
                                    },
                                    'prev': {
                                        'text': null,
                                        'link': null
                                    }
                                }
                            },
                            'hide': false
                        },
                        {
                            'type': 'link',
                            'name': 'Документы',
                            'link': '/sveden/document/',
                            'level': 3,
                            'parent': false,
                            'permission': 'R',
                            'params': [],
                            'blank': false,
                            'active': false,
                            'pageParams': {
                                'title': {
                                    'full': 'Документы',
                                    'short': 'Документы',
                                    'show': true
                                },
                                'redirect': null,
                                'showPage': true,
                                'meta': {
                                    'title': null,
                                    'description': null
                                },
                                'hero': {
                                    'enable': true,
                                    'type': null,
                                    'position': null,
                                    'src': null,
                                    'preview': null
                                },
                                'tools': {
                                    'share': false,
                                    'print': false,
                                    'pdf': false
                                },
                                'type': 'one-column',
                                'breadcrumbs': true,
                                'pageNav': false,
                                'footer': null,
                                'bodyClass': 'one-column',
                                'url': '/sveden/document/',
                                'level': '2',
                                'navigation-slider': {
                                    'next': {
                                        'text': null,
                                        'link': null
                                    },
                                    'prev': {
                                        'text': null,
                                        'link': null
                                    }
                                }
                            },
                            'hide': false
                        },
                        {
                            'type': 'link',
                            'name': 'Образование',
                            'link': '/sveden/education/',
                            'level': 3,
                            'parent': false,
                            'permission': 'R',
                            'params': [],
                            'blank': false,
                            'active': false,
                            'pageParams': {
                                'title': {
                                    'full': 'Образование',
                                    'short': 'Образование',
                                    'show': true
                                },
                                'redirect': null,
                                'showPage': true,
                                'meta': {
                                    'title': null,
                                    'description': null
                                },
                                'hero': {
                                    'enable': true,
                                    'type': null,
                                    'position': null,
                                    'src': null,
                                    'preview': null
                                },
                                'tools': {
                                    'share': false,
                                    'print': false,
                                    'pdf': false
                                },
                                'type': 'one-column',
                                'breadcrumbs': true,
                                'pageNav': false,
                                'footer': null,
                                'bodyClass': 'one-column',
                                'url': '/sveden/education/',
                                'level': '2',
                                'navigation-slider': {
                                    'next': {
                                        'text': null,
                                        'link': null
                                    },
                                    'prev': {
                                        'text': null,
                                        'link': null
                                    }
                                }
                            },
                            'hide': false
                        },
                        {
                            'type': 'link',
                            'name': 'Образовательные стандарты и требования',
                            'link': '/sveden/eduStandarts/',
                            'level': 3,
                            'parent': false,
                            'permission': 'R',
                            'params': [],
                            'blank': false,
                            'active': false,
                            'pageParams': {
                                'title': {
                                    'full': 'Образовательные стандарты и требования',
                                    'short': 'Образовательные стандарты и требования',
                                    'show': true
                                },
                                'redirect': null,
                                'showPage': true,
                                'meta': {
                                    'title': null,
                                    'description': null
                                },
                                'hero': {
                                    'enable': true,
                                    'type': null,
                                    'position': null,
                                    'src': null,
                                    'preview': null
                                },
                                'tools': {
                                    'share': false,
                                    'print': false,
                                    'pdf': false
                                },
                                'type': 'one-column',
                                'breadcrumbs': true,
                                'pageNav': false,
                                'footer': null,
                                'bodyClass': 'one-column',
                                'url': '/sveden/eduStandarts/',
                                'level': '2',
                                'navigation-slider': {
                                    'next': {
                                        'text': null,
                                        'link': null
                                    },
                                    'prev': {
                                        'text': null,
                                        'link': null
                                    }
                                }
                            },
                            'hide': false
                        },
                        {
                            'type': 'link',
                            'name': 'Руководство и педагогический состав',
                            'link': '/sveden/employees/',
                            'level': 3,
                            'parent': false,
                            'permission': 'R',
                            'params': [],
                            'blank': false,
                            'active': false,
                            'pageParams': {
                                'title': {
                                    'full': 'Руководство и педагогический состав',
                                    'short': 'Руководство и педагогический состав',
                                    'show': true
                                },
                                'redirect': null,
                                'showPage': true,
                                'meta': {
                                    'title': null,
                                    'description': null
                                },
                                'hero': {
                                    'enable': true,
                                    'type': null,
                                    'position': null,
                                    'src': null,
                                    'preview': null
                                },
                                'tools': {
                                    'share': false,
                                    'print': false,
                                    'pdf': false
                                },
                                'type': 'one-column',
                                'breadcrumbs': true,
                                'pageNav': false,
                                'footer': null,
                                'bodyClass': 'one-column',
                                'url': '/sveden/employees/',
                                'level': '2',
                                'navigation-slider': {
                                    'next': {
                                        'text': null,
                                        'link': null
                                    },
                                    'prev': {
                                        'text': null,
                                        'link': null
                                    }
                                }
                            },
                            'hide': false
                        },
                        {
                            'type': 'link',
                            'name': 'Материально-техническое обеспечение',
                            'link': '/sveden/objects/',
                            'level': 3,
                            'parent': false,
                            'permission': 'R',
                            'params': [],
                            'blank': false,
                            'active': false,
                            'pageParams': {
                                'title': {
                                    'full': 'Материально-техническое обеспечение и оснащенность образовательного процесса',
                                    'short': 'Материально-техническое обеспечение',
                                    'show': true
                                },
                                'redirect': null,
                                'showPage': true,
                                'meta': {
                                    'title': null,
                                    'description': null
                                },
                                'hero': {
                                    'enable': true,
                                    'type': null,
                                    'position': null,
                                    'src': null,
                                    'preview': null
                                },
                                'tools': {
                                    'share': false,
                                    'print': false,
                                    'pdf': false
                                },
                                'type': 'one-column',
                                'breadcrumbs': true,
                                'pageNav': false,
                                'footer': null,
                                'bodyClass': 'one-column',
                                'url': '/sveden/objects/',
                                'level': '2',
                                'navigation-slider': {
                                    'next': {
                                        'text': null,
                                        'link': null
                                    },
                                    'prev': {
                                        'text': null,
                                        'link': null
                                    }
                                }
                            },
                            'hide': false
                        },
                        {
                            'type': 'link',
                            'name': 'Стипендии и меры поддержки обучающихся',
                            'link': '/sveden/grants/',
                            'level': 3,
                            'parent': false,
                            'permission': 'R',
                            'params': [],
                            'blank': false,
                            'active': false,
                            'pageParams': {
                                'title': {
                                    'full': 'Стипендии и меры поддержки обучающихся',
                                    'short': 'Стипендии и меры поддержки обучающихся',
                                    'show': true
                                },
                                'redirect': null,
                                'showPage': true,
                                'meta': {
                                    'title': null,
                                    'description': null
                                },
                                'hero': {
                                    'enable': true,
                                    'type': null,
                                    'position': null,
                                    'src': null,
                                    'preview': null
                                },
                                'tools': {
                                    'share': false,
                                    'print': false,
                                    'pdf': false
                                },
                                'type': 'one-column',
                                'breadcrumbs': true,
                                'pageNav': false,
                                'footer': null,
                                'bodyClass': 'one-column',
                                'url': '/sveden/grants/',
                                'level': '2',
                                'navigation-slider': {
                                    'next': {
                                        'text': null,
                                        'link': null
                                    },
                                    'prev': {
                                        'text': null,
                                        'link': null
                                    }
                                }
                            },
                            'hide': false
                        },
                        {
                            'type': 'link',
                            'name': 'Платные образовательные услуги',
                            'link': '/sveden/paid_edu/',
                            'level': 3,
                            'parent': false,
                            'permission': 'R',
                            'params': [],
                            'blank': false,
                            'active': false,
                            'pageParams': {
                                'title': {
                                    'full': 'Платные образовательные услуги',
                                    'short': 'Платные образовательные услуги',
                                    'show': true
                                },
                                'redirect': null,
                                'showPage': true,
                                'meta': {
                                    'title': null,
                                    'description': null
                                },
                                'hero': {
                                    'enable': true,
                                    'type': null,
                                    'position': null,
                                    'src': null,
                                    'preview': null
                                },
                                'tools': {
                                    'share': false,
                                    'print': false,
                                    'pdf': false
                                },
                                'type': 'one-column',
                                'breadcrumbs': true,
                                'pageNav': false,
                                'footer': null,
                                'bodyClass': 'one-column',
                                'url': '/sveden/paid_edu/',
                                'level': '2',
                                'navigation-slider': {
                                    'next': {
                                        'text': null,
                                        'link': null
                                    },
                                    'prev': {
                                        'text': null,
                                        'link': null
                                    }
                                }
                            },
                            'hide': false
                        },
                        {
                            'type': 'link',
                            'name': 'Финансово-хозяйственная деятельность',
                            'link': '/sveden/budget/',
                            'level': 3,
                            'parent': false,
                            'permission': 'R',
                            'params': [],
                            'blank': false,
                            'active': false,
                            'pageParams': {
                                'title': {
                                    'full': 'Финансово-хозяйственная деятельность',
                                    'short': 'Финансово-хозяйственная деятельность',
                                    'show': true
                                },
                                'redirect': null,
                                'showPage': true,
                                'meta': {
                                    'title': null,
                                    'description': null
                                },
                                'hero': {
                                    'enable': true,
                                    'type': null,
                                    'position': null,
                                    'src': null,
                                    'preview': null
                                },
                                'tools': {
                                    'share': false,
                                    'print': false,
                                    'pdf': false
                                },
                                'type': 'one-column',
                                'breadcrumbs': true,
                                'pageNav': false,
                                'footer': null,
                                'bodyClass': 'one-column',
                                'url': '/sveden/budget/',
                                'level': '2',
                                'navigation-slider': {
                                    'next': {
                                        'text': null,
                                        'link': null
                                    },
                                    'prev': {
                                        'text': null,
                                        'link': null
                                    }
                                }
                            },
                            'hide': false
                        },
                        {
                            'type': 'link',
                            'name': 'Вакантные места для приема обучающихся',
                            'link': '/sveden/vacant/',
                            'level': 3,
                            'parent': false,
                            'permission': 'R',
                            'params': [],
                            'blank': false,
                            'active': false,
                            'pageParams': {
                                'title': {
                                    'full': 'Вакантные места для приема (перевода) обучающихся',
                                    'short': 'Вакантные места для приема обучающихся',
                                    'show': true
                                },
                                'redirect': null,
                                'showPage': true,
                                'meta': {
                                    'title': null,
                                    'description': null
                                },
                                'hero': {
                                    'enable': true,
                                    'type': null,
                                    'position': null,
                                    'src': null,
                                    'preview': null
                                },
                                'tools': {
                                    'share': false,
                                    'print': false,
                                    'pdf': false
                                },
                                'type': 'one-column',
                                'breadcrumbs': true,
                                'pageNav': false,
                                'footer': null,
                                'bodyClass': 'one-column',
                                'url': '/sveden/vacant/',
                                'level': '2',
                                'navigation-slider': {
                                    'next': {
                                        'text': null,
                                        'link': null
                                    },
                                    'prev': {
                                        'text': null,
                                        'link': null
                                    }
                                }
                            },
                            'hide': false
                        },
                        {
                            'type': 'link',
                            'name': 'Доступная среда',
                            'link': '/sveden/ovz/',
                            'level': 3,
                            'parent': false,
                            'permission': 'R',
                            'params': [],
                            'blank': false,
                            'active': false,
                            'pageParams': {
                                'title': {
                                    'full': 'Доступная среда',
                                    'short': 'Доступная среда',
                                    'show': true
                                },
                                'redirect': null,
                                'showPage': true,
                                'meta': {
                                    'title': null,
                                    'description': null
                                },
                                'hero': {
                                    'enable': true,
                                    'type': null,
                                    'position': null,
                                    'src': null,
                                    'preview': null
                                },
                                'tools': {
                                    'share': false,
                                    'print': false,
                                    'pdf': false
                                },
                                'type': 'one-column',
                                'breadcrumbs': true,
                                'pageNav': false,
                                'footer': null,
                                'bodyClass': 'one-column',
                                'url': '/sveden/ovz/',
                                'level': '2',
                                'navigation-slider': {
                                    'next': {
                                        'text': null,
                                        'link': null
                                    },
                                    'prev': {
                                        'text': null,
                                        'link': null
                                    }
                                }
                            },
                            'hide': false
                        },
                        {
                            'type': 'link',
                            'name': 'Международное сотрудничество',
                            'link': '/sveden/inter/',
                            'level': 3,
                            'parent': false,
                            'permission': 'R',
                            'params': [],
                            'blank': false,
                            'active': false,
                            'pageParams': {
                                'title': {
                                    'full': 'Международное сотрудничество',
                                    'short': 'Международное сотрудничество',
                                    'show': true
                                },
                                'redirect': null,
                                'showPage': true,
                                'meta': {
                                    'title': null,
                                    'description': null
                                },
                                'hero': {
                                    'enable': true,
                                    'type': null,
                                    'position': null,
                                    'src': null,
                                    'preview': null
                                },
                                'tools': {
                                    'share': false,
                                    'print': false,
                                    'pdf': false
                                },
                                'type': 'one-column',
                                'breadcrumbs': true,
                                'pageNav': false,
                                'footer': null,
                                'bodyClass': 'one-column',
                                'url': '/sveden/inter/',
                                'level': '2',
                                'navigation-slider': {
                                    'next': {
                                        'text': null,
                                        'link': null
                                    },
                                    'prev': {
                                        'text': null,
                                        'link': null
                                    }
                                }
                            },
                            'hide': false
                        }
                    ],
                    'hide': false
                },
                {
                    'type': 'link',
                    'name': 'Территории Университета',
                    'link': '/about/territory/',
                    'level': 2,
                    'parent': false,
                    'permission': 'R',
                    'params': [],
                    'blank': false,
                    'active': false,
                    'pageParams': {
                        'title': {
                            'full': 'Территории Университета',
                            'short': 'Территории Университета',
                            'show': true
                        },
                        'redirect': null,
                        'showPage': true,
                        'meta': {
                            'title': null,
                            'description': null
                        },
                        'hero': {
                            'enable': false,
                            'type': null,
                            'position': null,
                            'src': null,
                            'preview': null
                        },
                        'tools': {
                            'share': false,
                            'print': false,
                            'pdf': false
                        },
                        'type': 'one-column',
                        'breadcrumbs': true,
                        'pageNav': false,
                        'footer': null,
                        'bodyClass': 'one-column',
                        'url': '/about/territory/',
                        'level': '2',
                        'navigation-slider': {
                            'next': {
                                'text': null,
                                'link': null
                            },
                            'prev': {
                                'text': null,
                                'link': null
                            }
                        }
                    },
                    'hide': false
                },
                {
                    'type': 'link',
                    'name': 'История',
                    'link': '/about/history/',
                    'level': 2,
                    'parent': false,
                    'permission': 'R',
                    'params': [],
                    'blank': false,
                    'active': false,
                    'pageParams': {
                        'title': {
                            'full': 'История',
                            'short': 'История',
                            'show': true
                        },
                        'redirect': null,
                        'showPage': true,
                        'meta': {
                            'title': null,
                            'description': null
                        },
                        'hero': {
                            'enable': true,
                            'type': null,
                            'position': null,
                            'src': null,
                            'preview': null
                        },
                        'tools': {
                            'share': false,
                            'print': false,
                            'pdf': false
                        },
                        'type': 'one-column',
                        'breadcrumbs': true,
                        'pageNav': false,
                        'footer': null,
                        'bodyClass': 'one-column',
                        'url': '/about/history/',
                        'level': '2',
                        'navigation-slider': {
                            'next': {
                                'text': null,
                                'link': null
                            },
                            'prev': {
                                'text': null,
                                'link': null
                            }
                        }
                    },
                    'hide': false
                },
                {
                    'type': 'link',
                    'name': 'Отзывы',
                    'link': '/about/review/',
                    'level': 2,
                    'parent': false,
                    'permission': 'R',
                    'params': [],
                    'blank': false,
                    'active': false,
                    'pageParams': {
                        'title': {
                            'full': 'Отзывы',
                            'short': 'Отзывы',
                            'show': true
                        },
                        'redirect': null,
                        'showPage': true,
                        'meta': {
                            'title': null,
                            'description': null
                        },
                        'hero': {
                            'enable': true,
                            'type': null,
                            'position': null,
                            'src': null,
                            'preview': null
                        },
                        'tools': {
                            'share': false,
                            'print': false,
                            'pdf': false
                        },
                        'type': 'one-column',
                        'breadcrumbs': true,
                        'pageNav': false,
                        'footer': null,
                        'bodyClass': 'one-column',
                        'url': '/about/review/',
                        'level': '2',
                        'navigation-slider': {
                            'next': {
                                'text': null,
                                'link': null
                            },
                            'prev': {
                                'text': null,
                                'link': null
                            }
                        }
                    },
                    'hide': false
                }
            ]
        },
        {
            'type': 'link',
            'name': 'Обучение',
            'link': '/education/',
            'level': 1,
            'parent': true,
            'permission': 'R',
            'params': [],
            'blank': false,
            'active': false,
            'pageParams': {
                'title': {
                    'full': 'Обучение',
                    'short': 'Обучение',
                    'show': true
                },
                'redirect': null,
                'showPage': true,
                'meta': {
                    'title': null,
                    'description': null
                },
                'hero': {
                    'enable': true,
                    'type': null,
                    'position': null,
                    'src': null,
                    'preview': null
                },
                'tools': {
                    'share': false,
                    'print': false,
                    'pdf': false
                },
                'type': 'wide',
                'breadcrumbs': true,
                'pageNav': false,
                'footer': null,
                'bodyClass': 'wide',
                'url': '/education/',
                'level': '1',
                'navigation-slider': {
                    'next': {
                        'text': null,
                        'link': null
                    },
                    'prev': {
                        'text': null,
                        'link': null
                    }
                }
            },
            'items': [
                {
                    'type': 'link',
                    'name': 'Программы обучения',
                    'link': '/education/programs/',
                    'level': 2,
                    'parent': false,
                    'permission': 'R',
                    'params': [],
                    'blank': false,
                    'active': false,
                    'pageParams': {
                        'title': {
                            'full': 'Программы обучения',
                            'short': 'Программы обучения',
                            'show': true
                        },
                        'redirect': null,
                        'showPage': true,
                        'meta': {
                            'title': null,
                            'description': null
                        },
                        'hero': {
                            'enable': true,
                            'type': null,
                            'position': null,
                            'src': null,
                            'preview': null
                        },
                        'tools': {
                            'share': false,
                            'print': false,
                            'pdf': false
                        },
                        'type': 'one-column',
                        'breadcrumbs': true,
                        'pageNav': false,
                        'footer': null,
                        'bodyClass': 'one-column',
                        'url': '/education/programs/',
                        'level': '2',
                        'navigation-slider': {
                            'next': {
                                'text': null,
                                'link': null
                            },
                            'prev': {
                                'text': null,
                                'link': null
                            }
                        }
                    },
                    'hide': false
                },
                {
                    'type': 'link',
                    'name': 'Расписание',
                    'link': '/education/schedule/',
                    'level': 2,
                    'parent': false,
                    'permission': 'R',
                    'params': [],
                    'blank': false,
                    'active': false,
                    'pageParams': {
                        'title': {
                            'full': 'Расписание',
                            'short': 'Расписание',
                            'show': true
                        },
                        'redirect': null,
                        'showPage': true,
                        'meta': {
                            'title': null,
                            'description': null
                        },
                        'hero': {
                            'enable': true,
                            'type': null,
                            'position': null,
                            'src': null,
                            'preview': null
                        },
                        'tools': {
                            'share': false,
                            'print': false,
                            'pdf': false
                        },
                        'type': 'wide',
                        'breadcrumbs': true,
                        'pageNav': false,
                        'footer': null,
                        'bodyClass': 'wide',
                        'url': '/education/schedule/',
                        'level': '2',
                        'navigation-slider': {
                            'next': {
                                'text': null,
                                'link': null
                            },
                            'prev': {
                                'text': null,
                                'link': null
                            }
                        }
                    },
                    'hide': false
                }
            ]
        },
        {
            'type': 'link',
            'name': 'Пресс-центр',
            'link': '/press-center/',
            'level': 1,
            'parent': true,
            'permission': 'R',
            'params': [],
            'blank': false,
            'active': false,
            'pageParams': {
                'title': {
                    'full': 'Пресс-центр',
                    'short': 'Пресс-центр',
                    'show': true
                },
                'redirect': null,
                'showPage': true,
                'meta': {
                    'title': null,
                    'description': null
                },
                'hero': {
                    'enable': true,
                    'type': null,
                    'position': null,
                    'src': null,
                    'preview': null
                },
                'tools': {
                    'share': false,
                    'print': false,
                    'pdf': false
                },
                'type': 'wide',
                'breadcrumbs': true,
                'pageNav': false,
                'footer': null,
                'bodyClass': 'wide',
                'url': '/press-center/',
                'level': '1',
                'navigation-slider': {
                    'next': {
                        'text': null,
                        'link': null
                    },
                    'prev': {
                        'text': null,
                        'link': null
                    }
                }
            },
            'items': [
                {
                    'type': 'link',
                    'name': 'Новости',
                    'link': '/press-center/news/',
                    'level': 2,
                    'parent': false,
                    'permission': 'R',
                    'params': [],
                    'blank': false,
                    'active': false,
                    'pageParams': {
                        'title': {
                            'full': 'Новости',
                            'short': 'Новости',
                            'show': true
                        },
                        'redirect': null,
                        'showPage': true,
                        'meta': {
                            'title': null,
                            'description': null
                        },
                        'hero': {
                            'enable': true,
                            'type': null,
                            'position': null,
                            'src': null,
                            'preview': null
                        },
                        'tools': {
                            'share': false,
                            'print': false,
                            'pdf': false
                        },
                        'type': 'one-column',
                        'breadcrumbs': true,
                        'pageNav': false,
                        'footer': null,
                        'bodyClass': 'one-column',
                        'url': '/press-center/news/',
                        'level': '2',
                        'navigation-slider': {
                            'next': {
                                'text': null,
                                'link': null
                            },
                            'prev': {
                                'text': null,
                                'link': null
                            }
                        }
                    },
                    'hide': false
                },
                {
                    'type': 'link',
                    'name': 'Мероприятия',
                    'link': '/press-center/events/',
                    'level': 2,
                    'parent': false,
                    'permission': 'R',
                    'params': [],
                    'blank': false,
                    'active': false,
                    'pageParams': {
                        'title': {
                            'full': 'Мероприятия',
                            'short': 'Мероприятия',
                            'show': true
                        },
                        'redirect': null,
                        'showPage': true,
                        'meta': {
                            'title': null,
                            'description': null
                        },
                        'hero': {
                            'enable': true,
                            'type': null,
                            'position': null,
                            'src': null,
                            'preview': null
                        },
                        'tools': {
                            'share': false,
                            'print': false,
                            'pdf': false
                        },
                        'type': 'one-column',
                        'breadcrumbs': true,
                        'pageNav': false,
                        'footer': null,
                        'bodyClass': 'one-column',
                        'url': '/press-center/events/',
                        'level': '2',
                        'navigation-slider': {
                            'next': {
                                'text': null,
                                'link': null
                            },
                            'prev': {
                                'text': null,
                                'link': null
                            }
                        }
                    },
                    'hide': false
                },
                {
                    'type': 'link',
                    'name': 'Подписка',
                    'link': '/press-center/subscription/',
                    'level': 2,
                    'parent': false,
                    'permission': 'R',
                    'params': [],
                    'blank': false,
                    'active': false,
                    'pageParams': {
                        'title': {
                            'full': 'Подписка',
                            'short': 'Подписка',
                            'show': true
                        },
                        'redirect': null,
                        'showPage': true,
                        'meta': {
                            'title': null,
                            'description': null
                        },
                        'hero': {
                            'enable': true,
                            'type': null,
                            'position': null,
                            'src': null,
                            'preview': null
                        },
                        'tools': {
                            'share': false,
                            'print': false,
                            'pdf': false
                        },
                        'type': 'one-column',
                        'breadcrumbs': true,
                        'pageNav': false,
                        'footer': null,
                        'bodyClass': 'one-column',
                        'url': '/press-center/subscription/',
                        'level': '2',
                        'navigation-slider': {
                            'next': {
                                'text': null,
                                'link': null
                            },
                            'prev': {
                                'text': null,
                                'link': null
                            }
                        }
                    },
                    'hide': false
                }
            ]
        },
        {
            'type': 'link',
            'name': 'Карьера',
            'link': '/career/',
            'level': 1,
            'parent': true,
            'permission': 'R',
            'params': [],
            'blank': false,
            'active': false,
            'pageParams': {
                'title': {
                    'full': 'Карьера',
                    'short': 'Карьера',
                    'show': true
                },
                'redirect': null,
                'showPage': true,
                'meta': {
                    'title': null,
                    'description': null
                },
                'hero': {
                    'enable': true,
                    'type': null,
                    'position': null,
                    'src': null,
                    'preview': null
                },
                'tools': {
                    'share': false,
                    'print': false,
                    'pdf': false
                },
                'type': 'wide',
                'breadcrumbs': true,
                'pageNav': false,
                'footer': null,
                'bodyClass': 'wide',
                'url': '/career/',
                'level': '1',
                'navigation-slider': {
                    'next': {
                        'text': null,
                        'link': null
                    },
                    'prev': {
                        'text': null,
                        'link': null
                    }
                }
            },
            'items': [
                {
                    'type': 'link',
                    'name': 'Почему Норникель',
                    'link': 'https://www.nornickel.ru/careers/why-nornickel/',
                    'level': 2,
                    'parent': false,
                    'permission': 'Z',
                    'params': {
                        'BLANK': true,
                        'EXTERNAL': true
                    },
                    'blank': true,
                    'active': false,
                    'pageParams': {
                        'navigation-slider': {
                            'next': {
                                'text': null,
                                'link': null
                            },
                            'prev': {
                                'text': null,
                                'link': null
                            }
                        }
                    },
                    'hide': false
                },
                {
                    'type': 'link',
                    'name': 'Молодым специалистам',
                    'link': 'https://www.nornickel.ru/careers/beginning/',
                    'level': 2,
                    'parent': false,
                    'permission': 'Z',
                    'params': {
                        'BLANK': true,
                        'EXTERNAL': true
                    },
                    'blank': true,
                    'active': false,
                    'pageParams': {
                        'navigation-slider': {
                            'next': {
                                'text': null,
                                'link': null
                            },
                            'prev': {
                                'text': null,
                                'link': null
                            }
                        }
                    },
                    'hide': false
                },
                {
                    'type': 'link',
                    'name': 'Вакансии',
                    'link': '/career/vacancies/',
                    'level': 2,
                    'parent': false,
                    'permission': 'R',
                    'params': [],
                    'blank': false,
                    'active': false,
                    'pageParams': {
                        'title': {
                            'full': 'Вакансии',
                            'short': 'Вакансии',
                            'show': true
                        },
                        'redirect': null,
                        'showPage': true,
                        'meta': {
                            'title': null,
                            'description': null
                        },
                        'hero': {
                            'enable': true,
                            'type': null,
                            'position': null,
                            'src': null,
                            'preview': null
                        },
                        'tools': {
                            'share': false,
                            'print': false,
                            'pdf': false
                        },
                        'type': 'one-column',
                        'breadcrumbs': true,
                        'pageNav': false,
                        'footer': null,
                        'bodyClass': 'one-column',
                        'url': '/career/vacancies/',
                        'level': '2',
                        'navigation-slider': {
                            'next': {
                                'text': null,
                                'link': null
                            },
                            'prev': {
                                'text': null,
                                'link': null
                            }
                        }
                    },
                    'hide': false
                }
            ]
        },
        {
            'type': 'link',
            'name': 'Поставщикам',
            'link': '/suppliers/',
            'level': 1,
            'parent': true,
            'permission': 'R',
            'params': [],
            'blank': false,
            'active': false,
            'pageParams': {
                'title': {
                    'full': 'Поставщикам',
                    'short': 'Поставщикам',
                    'show': true
                },
                'redirect': null,
                'showPage': true,
                'meta': {
                    'title': null,
                    'description': null
                },
                'hero': {
                    'enable': true,
                    'type': null,
                    'position': null,
                    'src': null,
                    'preview': null
                },
                'tools': {
                    'share': false,
                    'print': false,
                    'pdf': false
                },
                'type': 'wide',
                'breadcrumbs': true,
                'pageNav': false,
                'footer': null,
                'bodyClass': 'wide',
                'url': '/suppliers/',
                'level': '1',
                'navigation-slider': {
                    'next': {
                        'text': null,
                        'link': null
                    },
                    'prev': {
                        'text': null,
                        'link': null
                    }
                }
            },
            'items': [
                {
                    'type': 'link',
                    'name': 'Политика закупок',
                    'link': 'https://www.nornickel.ru/suppliers/purchasing-policy/',
                    'level': 2,
                    'parent': false,
                    'permission': 'Z',
                    'params': {
                        'BLANK': true,
                        'EXTERNAL': true
                    },
                    'blank': true,
                    'active': false,
                    'pageParams': {
                        'navigation-slider': {
                            'next': {
                                'text': null,
                                'link': null
                            },
                            'prev': {
                                'text': null,
                                'link': null
                            }
                        }
                    },
                    'hide': false
                },
                {
                    'type': 'link',
                    'name': 'Как стать поставщиком',
                    'link': 'https://www.nornickel.ru/suppliers/become-supplier/',
                    'level': 2,
                    'parent': false,
                    'permission': 'Z',
                    'params': {
                        'BLANK': true,
                        'EXTERNAL': true
                    },
                    'blank': true,
                    'active': false,
                    'pageParams': {
                        'navigation-slider': {
                            'next': {
                                'text': null,
                                'link': null
                            },
                            'prev': {
                                'text': null,
                                'link': null
                            }
                        }
                    },
                    'hide': false
                },
                {
                    'type': 'link',
                    'name': 'Локальные закупки',
                    'link': '/suppliers/local/',
                    'level': 2,
                    'parent': false,
                    'permission': 'R',
                    'params': [],
                    'blank': false,
                    'active': false,
                    'pageParams': {
                        'title': {
                            'full': 'Локальные закупки',
                            'short': 'Локальные закупки',
                            'show': true
                        },
                        'redirect': null,
                        'showPage': true,
                        'meta': {
                            'title': null,
                            'description': null
                        },
                        'hero': {
                            'enable': true,
                            'type': null,
                            'position': null,
                            'src': null,
                            'preview': null
                        },
                        'tools': {
                            'share': false,
                            'print': false,
                            'pdf': false
                        },
                        'type': 'one-column',
                        'breadcrumbs': true,
                        'pageNav': false,
                        'footer': null,
                        'bodyClass': 'one-column',
                        'url': '/suppliers/local/',
                        'level': '2',
                        'navigation-slider': {
                            'next': {
                                'text': null,
                                'link': null
                            },
                            'prev': {
                                'text': null,
                                'link': null
                            }
                        }
                    },
                    'hide': false
                },
                {
                    'type': 'link',
                    'name': 'Договорная документация',
                    'link': '/suppliers/documentation/',
                    'level': 2,
                    'parent': false,
                    'permission': 'R',
                    'params': [],
                    'blank': false,
                    'active': false,
                    'pageParams': {
                        'title': {
                            'full': 'Договорная документация',
                            'short': 'Договорная документация',
                            'show': true
                        },
                        'redirect': null,
                        'showPage': true,
                        'meta': {
                            'title': null,
                            'description': null
                        },
                        'hero': {
                            'enable': true,
                            'type': null,
                            'position': null,
                            'src': null,
                            'preview': null
                        },
                        'tools': {
                            'share': false,
                            'print': false,
                            'pdf': false
                        },
                        'type': 'one-column',
                        'breadcrumbs': true,
                        'pageNav': false,
                        'footer': null,
                        'bodyClass': 'one-column',
                        'url': '/suppliers/documentation/',
                        'level': '2',
                        'navigation-slider': {
                            'next': {
                                'text': null,
                                'link': null
                            },
                            'prev': {
                                'text': null,
                                'link': null
                            }
                        }
                    },
                    'hide': false
                }
            ]
        },
        {
            'type': 'link',
            'name': 'Контакты',
            'link': '/contacts/',
            'level': 1,
            'parent': false,
            'permission': 'R',
            'params': [],
            'blank': false,
            'active': false,
            'pageParams': {
                'title': {
                    'full': 'Контакты',
                    'short': 'Контакты',
                    'show': false
                },
                'redirect': null,
                'showPage': null,
                'meta': {
                    'title': null,
                    'description': null
                },
                'hero': {
                    'enable': true,
                    'type': null,
                    'position': null,
                    'src': null,
                    'preview': null
                },
                'tools': {
                    'share': false,
                    'print': false,
                    'pdf': false
                },
                'type': 'one-column',
                'breadcrumbs': true,
                'pageNav': false,
                'footer': null,
                'bodyClass': 'one-column',
                'url': '/contacts/',
                'level': '1',
                'navigation-slider': {
                    'next': {
                        'text': null,
                        'link': null
                    },
                    'prev': {
                        'text': null,
                        'link': null
                    }
                }
            }
        }
    ],
    'bottom': [
        {
            'type': 'link',
            'name': 'Раскрытие информации',
            'link': '#',
            'level': 1,
            'parent': false,
            'permission': 'R',
            'params': [],
            'blank': false,
            'active': false
        },
        {
            'type': 'link',
            'name': 'Политика использования cookie',
            'link': '#',
            'level': 1,
            'parent': false,
            'permission': 'R',
            'params': [],
            'blank': false,
            'active': false
        },
        {
            'type': 'link',
            'name': 'Политика обработки персональных данных',
            'link': '#',
            'level': 1,
            'parent': false,
            'permission': 'R',
            'params': [],
            'blank': false,
            'active': false
        },
        {
            'type': 'link',
            'name': 'Сайты предприятий Группы &laquo;Норникель&raquo;',
            'link': '#',
            'level': 1,
            'parent': false,
            'permission': 'R',
            'params': [],
            'blank': false,
            'active': false
        },
        {
            'type': 'link',
            'name': 'Карта сайта',
            'link': '/sitemap/',
            'level': 1,
            'parent': false,
            'permission': 'R',
            'params': [],
            'blank': false,
            'active': false
        }
    ]
}
