<template>
    <figure :class="['z-blockquote', { 'z-blockquote_padding' : quote}]">
        <div
            class="z-blockquote__quote"
            v-if="quote"
            :style="`background-image: url(${quoteImg})`"
        ></div>
        <blockquote class="z-blockquote__text">
            <slot name="text"></slot>
        </blockquote>
        <div class="z-blockquote__wrapper" v-if="author">
            <div
                class="z-blockquote__photo"
                v-if="author.photo"
                :style="`background-image: url(${author.photo || photo})`"
            ></div>
            <div class="z-blockquote__info info" v-if="author.name || author.info">
                <span class="info__name" v-html="author.name"></span>
                <span class="info__text" v-if="author.info" v-html="author.info"></span>
            </div>
        </div>
        <div class="z-blockquote__source" v-if="author && author.source" v-html="author.source"></div>
    </figure>
</template>

<script>
export default {
    name: 'z-blockquote',
    props: {
        author: {
            name: String,
            info: String,
            photo: String,
            source: String
        },
        quote: Boolean
    },
    data () {
        return {
            quoteImg: require('./images/quote.svg')
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
