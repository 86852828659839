<template>
    <div class="nm-dropdown__footer nm-footer">
        <div v-if="staticLinks && staticLinks.length" class="nm-footer__static-container">
            <p class="nm-footer__static-title">Личный кабинет</p>
            <div v-for="(item, index) in staticLinks" :key="`static-links-${index}`">
                <a class="nm-footer__static-link" :href="item.link">
                    <z-icon name="tools/external-2"></z-icon>
                    <span>{{ item.title }}</span>
                </a>
            </div>
        </div>
        <div v-for="(item, index) in bottom" :key="`main-links-${index}`">
            <a class="nm-footer__main-link"
               :class="{'nm-footer__main-link--active': item.active}"
               v-html="item.name"
               :href="item.link"/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'navigation-mobile-footer',
    props: {
        staticLinks: {
            type: Array,
            default: null
        }
    },
    data () {
        return {
            bottom: this.$root.app.components.navigation.bottom
        }
    }
}
</script>

<style lang="scss" src="../index.scss"/>
