/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'general/spinner': {
    width: 26,
    height: 26,
    viewBox: '0 0 26 26',
    data: '<path pid="0" d="M13 .911c0-.52.423-.946.942-.909a13 13 0 11-13.777 10.9c.083-.515.595-.828 1.102-.708.506.12.816.627.74 1.142a11.114 11.114 0 1011.935-9.442C13.423 1.85 13 1.432 13 .911z" _fill="#4E566A"/>'
  }
})
