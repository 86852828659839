const app = {
    state: {
        device: 'desktop',
        burgerMenu: false
    },
    mutations: {
        ToggleDevice: (state, device) => {
            state.device = device
        },
        ToggleBurgerMenu: (state) => {
            state.burgerMenu = !state.burgerMenu
        }
    },
    actions: {
        ToggleDevice ({ commit }, device) {
            commit('ToggleDevice', device)
        },
        ToggleBurgerMenuAction ({ commit }) {
            commit('ToggleBurgerMenu')
        }
    }
}

export default app
