/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bullet/dot/s': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8 10.667a2.667 2.667 0 100-5.334 2.667 2.667 0 000 5.334z" _fill="#0077C8"/>'
  }
})
