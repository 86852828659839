<template>
    <div class="media-library">
        <filters :filters="filters" @change-filters="filtersHandler"/>
        <media-content :items="data"/>
        <z-preloader v-if="isLoading"/>
        <z-pagination
            class="u-top-margin--m-important"
            show-prev show-next show-nav
            :show-more="{ marginBottom: 'xs' }"
            :data="adaptedNav"
            @change-page="paginationHandler"/>
    </div>

</template>

<script>
import Filters from './components/Filters.vue'
import MediaContent from './components/MediaContent.vue'
import {getMediaLibrary} from '@/api/media-library'
import {throttle} from 'throttle-debounce'

export default {
    name: 'media-library',
    components: {
        MediaContent,
        Filters
    },
    data () {
        return {
            isLoading: false,
            data: this.$root.app.components['media-library'].items,
            filters: this.$root.app.components['media-library'].filters,
            nav: this.$root.app.components['media-library'].nav,
            params: {
                type: '',
                subject: '',
                page: 1
            }
        }
    },
    computed: {
        adaptedNav () {
            return {
                currentPage: this.nav.current,
                totalItems: this.nav.count,
                totalPages: this.nav.total,
                pageRange: 1
            }
        }
    },
    methods: {
        filtersHandler (data) {
            this.params = {
                ...data
            }
            this.params.page = 1
            this.send()
        },
        paginationHandler (data) {
            this.params.page = data.page
            this.send(data.type === 'load-more')
        },

        send: throttle(600, false, function (more = false) {
            this.isLoading = true

            getMediaLibrary(this.params)
                .then((response) => {
                    this.filters = response.filters
                    this.nav = response.nav
                    if (more) {
                        this.data = [...this.data, ...response.items]
                    } else {
                        this.data = response.items
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => {
                    this.isLoading = false
                })
        }, false)
    }
}
</script>

<style lang="scss">
</style>
