<template>
    <div class="purchase-detail">
        <z-lead class="u-bottom-margin--xl" v-html="data.previewText" v-if="data.previewText"></z-lead>

        <p class="purchase-detail__title text-size-xl">Характеристики</p>
        <z-table :columns="['', '']">
            <z-table-row>
                <z-table-col index="0">Способ проведения</z-table-col>
                <z-table-col index="1" v-html="data.methodCarrying.value"></z-table-col>
            </z-table-row>
            <z-table-row>
                <z-table-col index="0">Площадка</z-table-col>
                <z-table-col index="1" v-html="data.area.value"></z-table-col>
            </z-table-row>
            <z-table-row>
                <z-table-col index="0">Заказчик</z-table-col>
                <z-table-col index="1" v-html="data.customer.value"></z-table-col>
            </z-table-row>
            <z-table-row>
                <z-table-col index="0">Способ подачи заявки</z-table-col>
                <z-table-col index="1" v-html="data.applicationMethod.value"></z-table-col>
            </z-table-row>
            <z-table-row>
                <z-table-col index="0">Дата начала подачи заявок</z-table-col>
                <z-table-col index="1">{{ data.startDate.timestamp | momentFilter('DD.MM.YYYY') }}</z-table-col>
            </z-table-row>
            <z-table-row>
                <z-table-col index="0">Дата окончания подачи заявок</z-table-col>
                <z-table-col index="1">{{ data.endDate.timestamp | momentFilter('DD.MM.YYYY') }}</z-table-col>
            </z-table-row>
            <z-table-row>
                <z-table-col index="0">Контакты для связи</z-table-col>
                <z-table-col index="1">
                    <div class="u-bottom-margin--xs" v-html="data.fullname.value" v-if="data.fullname.value"></div>
                    <z-link
                        class="u-bottom-margin--xs"
                        :href="`mailto:${data.email.value}`"
                        v-html="data.email.value"
                        v-if="data.email.value"
                    ></z-link>
                    <z-link
                        :href="`tel:${data.phone.value}`"
                        v-html="data.phone.value"
                        v-if="data.phone.value"
                    ></z-link>
                </z-table-col>
            </z-table-row>
        </z-table>

        <template v-if="data.files.length > 0">
            <p class="purchase-detail__title text-size-xl u-top-margin--xl">Файлы</p>
            <z-filelist class="purchase-detail__file-list">
                <z-filelist-item
                    v-for="(file, index) in data.files"
                    :key="index"
                    size="l"
                    :icon="`file/big/${file.type}`"
                    :file-size="file.size"
                >
                    <z-link :href="file.link" v-html="file.name"></z-link>
                </z-filelist-item>
            </z-filelist>
        </template>

        <z-page-nav
            class="purchase-detail__nav"
            :data="{
                prev: {
                    name: 'Предыдущая',
                    url: nav.prev ? nav.prev.url : ''
                },
                next: {
                    name: 'Следующая',
                    url: nav.next ? nav.next.url : ''
                },
                'back-link': nav['back-link']
            }"
            button-text="Вернутся к списку"
        ></z-page-nav>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'purchase-detail',
    data () {
        return {
            data: this.$root.app.components['purchase-detail'].item,
            nav: this.$root.app.components['purchase-detail'].nav
        }
    },
    created () {
        this.SetHeroTitle(this.data.name)
    },
    methods: {
        ...mapActions(['SetHeroTitle'])
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
