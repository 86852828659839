<template>
    <div v-if="data" class="management-detail">
        <div class="management-detail__info">
            <div class="management-detail__avatar-wrapper">
                <div class="management-detail__avatar" :style="`background-image: url('${avatar}')`"/>
            </div>
            <div>
                <z-caption class="u-bottom-margin--2xs-important" tag="h2" size="2xl" weight="700">{{data.name}}</z-caption>
                <span class="management-detail__position" v-if="data.position.value" v-html="data.position.value"></span>
                <p class="management-detail__item" v-if="data.experience.value">
                    <span class="management-detail__key">Стаж в&nbsp;компании: </span>
                    <span class="management-detail__value" v-html="data.experience.value"></span>
                </p>
                <p class="management-detail__item" v-if="data.education.value">
                    <span class="management-detail__key">Образование: </span>
                    <span class="management-detail__value" v-html="data.education.value"></span>
                </p>
                <p class="management-detail__item" v-if="data.qualification.value">
                    <span class="management-detail__key">Квалификация: </span>
                    <span class="management-detail__value" v-html="data.qualification.value"></span>
                </p>
                <p class="management-detail__item" v-if="data.speciality.value">
                    <span class="management-detail__key">Специальность: </span>
                    <span class="management-detail__value" v-html="data.speciality.value"></span>
                </p>
            </div>
        </div>
        <div class="management-detail__rewards" v-if="data.rewards.value">
            <z-caption class="u-bottom-margin--xs-important" tag="h3" size="xl" weight="600">Награды</z-caption>
            <vue-raw class="management-detail__reward" :raw="data.rewards.value"/>
        </div>
        <z-page-nav :data="nav" button-text="Вернуться к&nbsp;списку"/>
    </div>
</template>

<script>
export default {
    name: 'management-detail',
    data () {
        return {
            data: this.$root.app.components['governance-detail']?.item || null,
            nav: this.$root.app.components['governance-detail'].nav
        }
    },
    computed: {
        avatar () {
            if (this.data.detailPicture.link) {
                return this.data.detailPicture.link
            }

            return this.data.gender.xmlId === 'female' ? '/images/management/female.jpg' : '/images/management/male.jpg'
        }
    }
}
</script>

<style lang="scss">
.management-detail {
    &__info {
        display: flex;

        @include margin-level(bottom, XL);

        @include breakpoint(mobile) {
            flex-direction: column;
        }
    }

    &__avatar-wrapper {
        max-width: 313px;
        width: 100%;
        @include margin-level(right, L);

        @media screen and (max-width: 850px) {
            max-width: 200px;
        }

        @include breakpoint(mobile) {
            max-width: 100%;
            margin-right: 0;
            margin-bottom: $token-spacers-xs;
        }
    }

    &__avatar {
        width: 100%;
        padding-top: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 4px;
    }

    &__position {
        display: block;
        font-weight: 400;
        @include typo-level(S);
        line-height: 1.4;
        color: $token-colors-black-40;

        @include margin-level(bottom, L);
    }

    &__item {
        &:not(:last-child) {
            @include margin-level(bottom, 2XS)
        }
    }

    &__key {
        font-weight: 400;
        @include typo-level(S);
        line-height: 1.4;
        color: $token-colors-black-60;
    }

    &__value {
        font-weight: 400;
        @include typo-level(S);
        line-height: 140%;
        color: $token-colors-black-90;
    }

    &__rewards {
        border-bottom: 1px solid $token-colors-black-10;
        @include padding-level(bottom, 3XL);
        @include margin-level(bottom, S);
    }

    &__reward {
        &:not(:last-child) {
            @include margin-level(bottom, 2XS);
        }
    }
}
</style>
