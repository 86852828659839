/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/small/pdf': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.666 3.333h11.072a1 1 0 01.708.293l1.207 1.207h-.008l.337.337 8.233 8.233v-.007l1.207 1.207a1 1 0 01.293.707V33.5a4 4 0 01-4 4H10.666a4 4 0 01-4-4V7.333a4 4 0 014-4zm21.55 11.625h-8.734a1 1 0 01-1-1V4.833H10.666a2.5 2.5 0 00-2.5 2.5V33.5a2.5 2.5 0 002.5 2.5h19.05a2.5 2.5 0 002.5-2.5V14.958zm-8.234-1.5V7.292l6.166 6.166h-6.166z" _fill="#1092DC"/><path pid="1" d="M11.092 27.167v-7.05h3.06c.773 0 1.37.193 1.79.58.42.386.63.926.63 1.62 0 .693-.21 1.236-.63 1.63-.42.386-1.017.58-1.79.58h-1.78v2.64h-1.28zm1.28-3.65h1.58c.92 0 1.38-.4 1.38-1.2 0-.794-.46-1.19-1.38-1.19h-1.58v2.39zm5.044 3.65v-7.05h2.62c1.18 0 2.09.303 2.73.91.646.606.97 1.476.97 2.61 0 1.126-.324 1.996-.97 2.61-.64.613-1.55.92-2.73.92h-2.62zm1.28-1.06h1.26c1.626 0 2.44-.824 2.44-2.47 0-1.64-.814-2.46-2.44-2.46h-1.26v4.93zm6.137 1.06v-7.05h4.61v1.02h-3.33v1.99h3.12v1.02h-3.12v3.02h-1.28z" _fill="#1092DC"/>'
  }
})
