/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tools/pause-circle': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.75 12a8.25 8.25 0 1116.5 0 8.25 8.25 0 01-16.5 0zM12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM10.5 9A.75.75 0 009 9v6a.75.75 0 001.5 0V9zm3.75-.75A.75.75 0 0115 9v6a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75z" _fill="#81899D"/>'
  }
})
