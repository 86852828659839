/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrows/caret-small': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M16.29 14.94a.75.75 0 101.06-1.06l-4.82-4.82a.75.75 0 00-1.06 0l-4.82 4.82a.75.75 0 001.06 1.06L12 10.65l4.29 4.29z" _fill="#81899D"/>'
  }
})
