<template>
    <div class="news-filter">
        <div class="row">
            <z-select
                class="news-filter__item col-default-4 col-v-tablet-6 col-mobile-12"
                :data="data.years"
                v-model="params.year"
                @change="changeFilter"
                is-filter
                placeholder="Год"
            />
        </div>
    </div>
</template>

<script>
import { queryString } from '@/utils/queryString'

export default {
    name: 'news-filter',
    props: {
        data: Object
    },
    data () {
        return {
            params: {
                year: ''
            }
        }
    },
    created () {
        let params = {}
        new URL(window.location).searchParams.forEach(function (val, key) {
            params[key] = val
        })

        this.params = { ...this.params, ...params }

        this.$emit('update-filter', this.params)
    },
    methods: {
        changeFilter () {
            this.$emit('change-filter', this.params)

            queryString(this.clearEmptyParams)
        }
    },
    computed: {
        clearEmptyParams () {
            let params = Object.assign({}, this.params)

            Object.keys(params).forEach(function (key, index) {
                if (Array.isArray(params[key])) {
                    if (!(params[key].filter(val => { return val !== null && val !== undefined && val !== '' && val !== false }).length)) delete params[key]
                } else if (!params[key]) {
                    delete params[key]
                }
            })

            return params
        }
    }
}
</script>
