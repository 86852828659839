/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar/calendar-2': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M16.574 2.102a.75.75 0 01.75.75v.775h2.3c.835 0 1.512.677 1.512 1.512V20.385c0 .836-.677 1.513-1.513 1.513H4.377a1.512 1.512 0 01-1.512-1.512V5.139c0-.835.677-1.512 1.512-1.512h2.3v-.775a.75.75 0 011.5 0v.775h7.647v-.775a.75.75 0 01.75-.75zm3.062 3.037v3.062H4.365V5.139a.01.01 0 010-.004l.003-.004a.014.014 0 01.004-.003l.005-.001h2.3v.775a.75.75 0 001.5 0v-.775h7.647v.775a.75.75 0 001.5 0v-.775h2.304l.001.001.003.003a.015.015 0 01.002.002l.001.002v.004zm0 4.562H4.365v10.684a.01.01 0 000 .005l.001.002.001.001.001.001a.015.015 0 00.004.003h15.255c.002 0 .003-.001.005-.003l.002-.002.001-.002V9.701z" _fill="#81899D"/>'
  }
})
